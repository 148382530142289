import React from "react";
import { Component } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Message,
  Popup,
} from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import ReactGA from "react-ga";
import "./style.css";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
const { encrypt, decrypt } = require("./crypto");

export default class signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      cardnumber: "",
      cardholder: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      username: "",
    };
  }

  componentDidMount() {
    //ReactGA.initialize('UA-160033896-1');
    //ReactGA.pageview(window.location.pathname + window.location.search);

    this.setState({
      username: window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      ),
    });
  }

  validateForm() {
    return (
      this.state.cardnumber.length > 0 &&
      this.state.exp_month.length > 0 &&
      this.state.exp_year.length > 0 &&
      this.state.cvc.length > 0
    );
  }

  handleSubmitForm = async (event) => {
    event.preventDefault();
    const vm = this;

    Swal.fire({
      title: "Processing",
      text: "Please wait...",
      confirmButtonColor: "#35a3a5",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    try {
      let card_info = {
        cardnumber: this.state.cardnumber.replace(/\s+/g, ""),
        exp_month: this.state.exp_month,
        exp_year: this.state.exp_year,
        cvc: this.state.cvc,
      };

      let ciphertext = encrypt(JSON.stringify(card_info));
      let data = {
        cardinfo: ciphertext,
      };

      console.log(data);

      await this.API_post_member(data).then(function (res) {
        console.log(res);
        if (res._status_response === "success") {
          Swal.fire({
            title: "Payment processed",
            text: "Thank you for joining",
            confirmButtonColor: "#35a3a5",
            confirmButtonText: "Ok",
          }).then(function () {
            window.location.href = "/signin";
          });
        } else {
          Swal.fire({
            text: res.message,
            confirmButtonColor: "#35a3a5",
            confirmButtonText: "Ok",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: e.response.data._status_message,
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      });
    }
  };

  API_post_member(body) {
    return API.post("tap", `/post-member-confirm/${this.state.username}`, {
      body,
    });
  }

  //  update state variables when form changes
  onChange_input = (event) => {
    if (event.target.name === "cardnumber") {
      let card = event.target.value;

      this.setState({
        cardnumber: this.transform(card),
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  transform = (value) => {
    if (value != null) {
      value = value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    }
    return value;
  };
  //  render page --------------------------------------------------------------------------------------------------------

  renderForm() {
    return (
      <div className="signup_new_card">
        <Form
          className="tapform"
          id="frm_signup"
          onSubmit={this.handleSubmitForm}>
          <Form.Group widths="equal">
            <Form.Field
              id="cardnumber"
              name="cardnumber"
              value={this.state.cardnumber || ""}
              onChange={this.onChange_input}
              control={Input}
              label={"Card Number"}
              placeholder={"xxxx xxxx xxxx xxxx"}
              required
              maxLength={19}
              minLength={19}
              style={{
                border: "1px solid #9CA3AF",
                borderRadius: 4,
              }}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              id="exp_month"
              name="exp_month"
              value={this.state.exp_month || ""}
              onChange={this.onChange_input}
              control={Input}
              label={"Expiration - Month"}
              placeholder={"00"}
              required
              maxLength={2}
              minLength={2}
              style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
            />
            <Form.Field
              id="exp_year"
              name="exp_year"
              value={this.state.exp_year || ""}
              onChange={this.onChange_input}
              control={Input}
              label={"Expiration - Year"}
              placeholder={"0000"}
              required
              maxLength={4}
              style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
              minLength={4}
            />{" "}
          </Form.Group>
          <Popup
            content=" Security Code is the 3 or 4 digit number on the back of your card, to the right of the signature box."
            position="right center"
            trigger={
              <Form.Field
                id="cvc"
                name="cvc"
                value={this.state.cvc || ""}
                onChange={this.onChange_input}
                control={Input}
                label={"Security Code"}
                placeholder={"000"}
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                required
                maxLength={4}
                minLength={3}
              />
            }
          />

          <div className="invoicetotal">
            <div className="invoicetotal_text">Monthly total</div>
            <div className="invoicetotal_text">$ 9.99 USD</div>
          </div>

          <button
            // disabled={!this.validateForm()}
            type="submit"
            className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded disabled">
            Sign Up
          </button>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div className="containerwrapper" style={{ marginTop: 5 }}>
        <Helmet>
          <title>{`Tap Advance | Sign Up`}</title>
        </Helmet>

        <div className="signup_container_new" id="page_signup_new">
          <div className="page_signup_1 md:w-2/3 w-full">
            <div className="mb-20 flex flex-col ">
              <div
                className="login_logo py-5 mb-6 justify-between"
                style={{ display: "flex", alignItems: "center" }}>
                {/* <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img> */}
                <div className="">
                  <h1 className="text-4xl text-left"> Payment details</h1>
                </div>
                <Popup
                  content="Transactions are encrypted and secure."
                  position="right center"
                  trigger={
                    <div className="cursor-pointer">
                      <img src={process.env.PUBLIC_URL + "/icons-lock.png"} />
                    </div>
                  }
                />
              </div>
              <div className="flex justify-end">
                <div className="mr-2">
                  <img src={process.env.PUBLIC_URL + "/visa.png"} />
                </div>
                <div className="mr-2">
                  <img src={process.env.PUBLIC_URL + "/mastercard.png"} />
                </div>
                <div className="">
                  <img src={process.env.PUBLIC_URL + "/amex.png"} />
                </div>
              </div>
              {this.renderForm()}
            </div>
          </div>

          <div className="page_signup_2 md:w-1/3 w-full flex flex-col">
            <div className="page_signup_header font-semibold py-8 mb-10 px-10">
              Features
            </div>
            <div className="signup-features flex flex-col justify-between px-10 h-3/5">
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />
                <div className="">Unlimited Access</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Foundational skill building</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Modern Sales Training </div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Personal Growth</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Leadership Development</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">On Demand Learning</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Printable Worksheets</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Bite-sized Training</div>
              </div>
              {/* <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="font-semibold">And more</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
