import React, { Component } from "react";
import { Form, Input, Button, Message } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import "../signin/signin.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Swal from "sweetalert2";

export default class verify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_loading: false,
      email: localStorage.getItem("emailTapAdvance"),

      authentication: "",
      verificationCode: "",
    };

    this.cognitodata = {};
  }

  handleSubmitForm = async (event) => {
    event.preventDefault();
    const vm = this;
    localStorage.setItem("verificationCode", vm.state.verificationCode);
    window.location.href = "/reset";
  };

  onChange_input = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <div className="login_wrapper">
        <Helmet>
          <title>{`Tap Advance | Reset Password`}</title>
        </Helmet>

        <div className="login green_border lg:p-12 mb-20">
          <div
            className="login_logo py-10 px-10 md:px-16"
            style={{ display: "flex", alignItems: "center" }}>
            {/* <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img> */}
            <div
              className="mr-2 cursor-pointer"
              onClick={() => (window.location.href = "/signin")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10pt"
                color="#FFF"
                height="15pt"
                viewBox="0 0 25 28">
                <path
                  fill="#35a3a5"
                  d="M14.277 24.203l-1.23 1.207a1.342 1.342 0 01-1.879 0L.387 14.848a1.29 1.29 0 010-1.848L11.168 2.43a1.35 1.35 0 011.879 0l1.23 1.207a1.29 1.29 0 01-.023 1.867l-6.68 6.242h15.934c.738 0 1.332.582 1.332 1.305v1.738c0 .727-.594 1.305-1.332 1.305H7.574l6.68 6.246c.543.504.555 1.348.023 1.863zm0 0"
                />
              </svg>
            </div>
            <h1 className="ml-4 text-4xl text-left font-bold">
              {" "}
              Forgot Password
            </h1>
          </div>

          <div className="px-16 text-sm">
            A code has been sent to your email{" "}
            <span className="font-semibold">{this.state.email} </span>. Please
            enter the verification code.
          </div>
          <div className="login_form py-10 px-4">
            <Form onSubmit={this.handleSubmitForm}>
              <Form.Field
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                id="email"
                name="verificationCode"
                value={this.state.verificationCode || ""}
                onChange={this.onChange_input}
                control={Input}
                label={"Verification Code"}
                placeholder={"000000"}
              />

              <button
                className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded"
                type="submit">
                Continue
              </button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
