import React, { Component } from "react";
import { Form, Input, Button, Message, Popup } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import "../signin/signin.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Swal from "sweetalert2";

export default class reset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: localStorage.getItem("emailTapAdvance"),
      verificationCode: localStorage.getItem("verificationCode"),
      password: "",
      passwordConfirm: "",
      validatepassword_length: false,
      validatepassword_lowercase: false,
      validatepassword_uppercase: false,
      validatepassword_number: false,
      validatepassword_specialcharacter: false,
    };

    this.cognitodata = {};
  }

  componentDidMount() {
    //ReactGA.initialize('UA-160033896-1');
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  validateForm() {
    return (
      this.state.validatepassword_length === true &&
      this.state.validatepassword_lowercase === true &&
      this.state.validatepassword_uppercase === true &&
      this.state.validatepassword_number === true &&
      this.state.validatepassword_specialcharacter === true &&
      this.state.password === this.state.passwordConfirm
    );
  }

  handleSubmitForm = async (event) => {
    event.preventDefault();
    const vm = this;
    Swal.fire({
      title: "Resetting",
      text: "Please wait...",
      confirmButtonColor: "#35a3a5",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    let body = {
      email: this.state.email.toLowerCase(),
      password: vm.state.password,
      reset_code: vm.state.verificationCode,
    };

    if (this.validateForm()) {
      try {
        await this.API_reset_password(body).then(function (res) {
          console.log(res);
          if (res._status_response === "success") {
            localStorage.clear();
            Swal.fire({
              title: "Password Updated!",
              text: "Your password has been updated successfully!",
              confirmButtonColor: "#35a3a5",
              confirmButtonText: "Ok",
            }).then(function () {
              window.location.href = "/signin";
            });
          } else {
            Swal.fire({
              text: res.message,
              confirmButtonColor: "#35a3a5",
              confirmButtonText: "Ok",
            });
          }
        });
      } catch (e) {
        //alert(e.message);
        Swal.fire({
          title: "Error",
          text: e.response.data._status_message,
          confirmButtonColor: "#35a3a5",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        text: "Passwords do not match or doesn't meet the requirement",
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      });
    }
  };

  API_reset_password(body) {
    return API.post("tap", "/post-password-reset", { body });
  }

  //  API call to get-user
  API_get_member(cognitousername) {
    return API.get("tap", "/get-member/" + cognitousername);
  }

  //  update state variables when form changes
  onChange_input = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onChange_password = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    let password = event.target.value;

    if (password.length >= 8) {
      this.setState({ validatepassword_length: true });
    } else {
      this.setState({ validatepassword_length: false });
    }

    if (/[a-z]/.test(password)) {
      this.setState({ validatepassword_lowercase: true });
    } else {
      this.setState({ validatepassword_lowercase: false });
    }

    if (/[A-Z]/.test(password)) {
      this.setState({ validatepassword_uppercase: true });
    } else {
      this.setState({ validatepassword_uppercase: false });
    }

    if (/[0-9]/.test(password)) {
      this.setState({ validatepassword_number: true });
    } else {
      this.setState({ validatepassword_number: false });
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
      this.setState({ validatepassword_specialcharacter: true });
    } else {
      this.setState({ validatepassword_specialcharacter: false });
    }
  };

  //  render login ---------------------------------------------------------------------------------------------------
  render() {
    console.log(this.state);
    return (
      <div className="login_wrapper">
        <Helmet>
          <title>{`Tap Advance | Reset Password`}</title>
        </Helmet>

        <div className="login green_border lg:p-12 mb-20">
          <div
            className="login_logo py-10 px-10 md:px-16"
            style={{ display: "flex", alignItems: "center" }}>
            <div
              className="mr-2 cursor-pointer"
              onClick={() => (window.location.href = "/verify")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10pt"
                color="#FFF"
                height="15pt"
                viewBox="0 0 25 28">
                <path
                  fill="#35a3a5"
                  d="M14.277 24.203l-1.23 1.207a1.342 1.342 0 01-1.879 0L.387 14.848a1.29 1.29 0 010-1.848L11.168 2.43a1.35 1.35 0 011.879 0l1.23 1.207a1.29 1.29 0 01-.023 1.867l-6.68 6.242h15.934c.738 0 1.332.582 1.332 1.305v1.738c0 .727-.594 1.305-1.332 1.305H7.574l6.68 6.246c.543.504.555 1.348.023 1.863zm0 0"
                />
              </svg>
            </div>
            <h1 className="ml-4 text-4xl text-left font-bold">
              {" "}
              Reset Password
            </h1>
          </div>

          <div className="px-16 text-sm">
            Password must be a minimum of{" "}
            <span className="font-semibold">8 characters long </span> and
            contain at least{" "}
            <span className="font-semibold">one uppercase </span> and{" "}
            <span className="font-semibold">
              {" "}
              lowercase character, a number and a special character{" "}
            </span>
          </div>
          <div className="login_form py-10 px-4">
            <Form onSubmit={this.handleSubmitForm}>
              <Form.Field
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                id="password"
                name="password"
                value={this.state.password || ""}
                onChange={this.onChange_password}
                control={Input}
                type="password"
                label={"Password"}
                placeholder={"Password"}
                required
              />

              <Form.Field
                style={{
                  border: "1px solid #9CA3AF",
                  borderRadius: 4,
                }}
                id="passwordConfirm"
                name="passwordConfirm"
                value={this.state.passwordConfirm || ""}
                onChange={this.onChange_password}
                control={Input}
                type="password"
                label={"Password Confirmation"}
                required
                placeholder={"Password"}></Form.Field>

              <button
                className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded"
                type="submit">
                Continue
              </button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
