import React from "react";
import { Component } from "react";
import { API } from "aws-amplify";
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'
import ReactGA from 'react-ga';
import "./module.css";
import {Button, Form, TextArea} from "semantic-ui-react";
import {Helmet} from "react-helmet";

export default class module1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            module_name: "",
            module_description: "",
            previousmoduleid:"",
            didmount: false,
            blocks: [],
            displayTapcircle: false,
            displayMyJournal: true,
            tapcircle_submitting: false,
            comment: "",
            comments: [],
            tapcircle_submittingreply: false,
            parentcomment_id: 0,
            journalcomments: [],
            journalcomment: "",
            journal_submitting: false
        };

        //  populate page on redirect
        this.props.history.listen((location, action) => {
            let location_split = location.pathname.split('/');
            if(location_split[1] === 'module' && this.state.didmount === true) {

                    this.populatemodule(location_split[2])
            }
        });

    }


    //  populate page on fist load
    async componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if(this.state.didmount === false) {
            this.populatemodule(this.props.match.params.moduleid);
            this.state.didmount = true;
        }

        try {
            const comments = await this.API_get_comments(this.props.match.params.moduleid);
            this.setState({ comments });
        } catch (e) {
            console.log(e);
        }

        try {
            const journalcomments = await this.API_get_journalcomments(this.props.match.params.moduleid);
            this.setState({ journalcomments });
        } catch (e) {
            console.log(e);
        }

    }


    //  populate page with module data
    async populatemodule(module_id){
        try {
            const result = await this.API_get_module(module_id);
            this.state.module_name = result[0]['module_name'];
            this.state.module_description = result[0]['description'];
            const blocks = await this.API_get_blocks(module_id);
            this.setState({blocks});
        } catch (e) {
            console.log(e);
        }
    }


    //  API call to get-module
    API_get_module(moduleid) {
        return API.get("tap", "/get-module/"+moduleid);
    }


    //  API call to get-blocks
    API_get_blocks(parentmodule_id) {
        if(!localStorage.getItem('cognitoid')){
            localStorage.setItem('cognitoid','null')
        }
        return API.get("tap", "/get-blocks/"+parentmodule_id+"/"+localStorage.getItem('cognitoid'));
    }

    API_get_comments(blockid) {
        return API.get("tap-tapcircle", "/get-modulecomments/"+blockid);
    }

    //  API call to post PDF completed stats
    API_put_comment(body) {
        return API.put('tap-tapcircle', '/put-comment/', { body });
    }

    API_put_commentlike(body) {
        return API.put('tap-tapcircle', '/put-commentlike/', { body });
    }

    API_get_journalcomments(blockid) {
        return API.get("tap-tapcircle", "/get-modulejournalcomments/"+blockid+"/"+localStorage.getItem('cognitoid'));
    }

    API_put_journalcomment(body) {
        return API.put('tap-tapcircle', '/put-journalcomment/', { body });
    }


    //  open block page
    block_onClick(block_id,viewstatus){
        if(localStorage.getItem('authenticated') !== 'true' && viewstatus === 'FREE') {
            this.props.history.push("/block/" + block_id);
        }else if(localStorage.getItem('authenticated') !== 'true' && viewstatus !== 'FREE') {
            this.props.history.push("/signin");
        }else if(localStorage.getItem('authenticated') === 'true') {
            this.props.history.push("/block/" + block_id);
        }
    }


    //  reload page with new module
    async module_onClick(module_id){
        this.props.history.push("/module/"+module_id);
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }

    async tab_tapcircle_onClick(){
        this.setState({ displayMyJournal: false })
        this.setState({ displayTapcircle: true })

        try {
            const comments = await this.API_get_comments(this.props.match.params.moduleid);
            this.setState({ comments });
        } catch (e) {
            console.log(e);
        }
    }

    async tab_myjournal_onClick(){
        this.setState({ displayMyJournal: true })
        this.setState({ displayTapcircle: false })

        try {
            const journalcomments = await this.API_get_journalcomments(this.props.match.params.moduleid);
            this.setState({ journalcomments });
        } catch (e) {
            console.log(e);
        }
    }

    onChange_tapcircle = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit_tapcircle = async event => {
        event.preventDefault();
        this.setState({ tapcircle_submitting: true })
        let comment = {};
        comment.learningpath_id=0;
        comment.module_id=this.props.match.params.moduleid;
        comment.block_id=0;
        comment.cognitousername=localStorage.getItem('cognitoid')
        comment.parentcomment_id=0;
        comment.comment=this.state.comment;
        await this.API_put_comment(comment);
        this.setState({ tapcircle_submitting: false })
        this.setState({ comment: "" })

        try {
            const comments = await this.API_get_comments(this.props.match.params.moduleid);
            this.setState({ comments });
        } catch (e) {
            console.log(e);
        }
    };

    onChange_tapcirclereply = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit_tapcirclereply = async event => {
        event.preventDefault();
        this.setState({ ['tapcircle_submittingreply'+event.target.parentcomment_id.value]: true })
        let comment = {};
        comment.learningpath_id=0;
        comment.module_id=this.props.match.params.moduleid;
        comment.block_id=0;
        comment.cognitousername=localStorage.getItem('cognitoid')
        comment.parentcomment_id=event.target.parentcomment_id.value;
        comment.comment=this.state['comment'+event.target.parentcomment_id.value];
        this.setState({ ['comment'+event.target.parentcomment_id.value]: "" })
        await this.API_put_comment(comment);
        this.setState({ ['tapcircle_submittingreply'+comment.parentcomment_id]: false })

        try {
            const comments = await this.API_get_comments(this.props.match.params.moduleid);
            this.setState({ comments });
        } catch (e) {
            console.log(e);
        }
    };

    async tapcircle_like_onClick(comment_id){
        document.getElementById("commentlike_icon"+comment_id).src = "/icons/heart 20px (eb6060).svg";
        let commentlike = {};
        commentlike.comment_id=comment_id;
        commentlike.cognitousername=localStorage.getItem('cognitoid')
        await this.API_put_commentlike(commentlike);

        try {
            const comments = await this.API_get_comments(this.props.match.params.moduleid);
            this.setState({ comments });
        } catch (e) {
            console.log(e);
        }
    }

    onChange_journal = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit_journal = async event => {
        event.preventDefault();
        this.setState({ journal_submitting: true })
        let journalcomment = {};
        journalcomment.learningpath_id=0;
        journalcomment.module_id=this.props.match.params.moduleid;
        journalcomment.block_id=0;
        journalcomment.cognitousername=localStorage.getItem('cognitoid')
        journalcomment.comment=this.state.journalcomment;
        await this.API_put_journalcomment(journalcomment);
        this.setState({ journal_submitting: false })
        this.setState({ journalcomment: "" })

        try {
            const journalcomments = await this.API_get_journalcomments(this.props.match.params.moduleid);
            this.setState({ journalcomments });
        } catch (e) {
            console.log(e);
        }
    };





    render() {

        const window = (new JSDOM('')).window;
        const DOMPurify = createDOMPurify(window);

        return (
            <div className="containerwrapper">

                <Helmet>
                    <title>{`Tap Advance | ${this.state.module_name}`}</title>
                </Helmet>

                <div className="maincontentblock mcb_module_header_stripe"></div>
                <div className="maincontentblock mcb_module_header">
                    <div className="module_title"> {this.state.module_name}</div>
                </div>
                <div className="maincontentblock mcb_module_header_stripe"></div>

                <div className="maincontentblock mcb_module_description">
                    { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.module_description) }} /> }
                </div>

                {localStorage.getItem('authenticated') !== 'true' &&
                <div className="maincontentblock mcb_signup">
                    <div className="signup_wrapper">
                        <div className="signup_1"><img src="/icons/person3.svg" alt="person 3"></img></div>
                        <div className="signup_2">Sign up to access this Module and explore all our content!</div>
                        <div className="signup_3">
                            <a href="/signup"><div className="signup_3_button_wrapper"><Button primary className="btn_primary_eb6060_ffffff home_signup_block_4_cta_button">SIGN UP</Button></div></a>
                            <div>Already a member? <a href="/signin">Sign In</a></div>
                        </div>
                    </div>
                </div>
                }

                <div className="maincontentblock mcb_blocks">
                    <div className="blocks_wrapper">
                        {this.state.blocks.map((item, i) =>

                            <div className="block" key={i} onClick={() => this.block_onClick(item.block_id, item.viewstatus)}>
                                <div className={"block_img" + (localStorage.getItem('authenticated') !== 'true' && item.viewstatus !== 'FREE' ? " paid" : "")}>
                                    <img    src={`https://tap-app-resources.s3.amazonaws.com/public/jpg/block_${item.block_id}.jpg`}
                                            alt={`primary header for block id ${item.block_id}`}
                                            onError={this.addDefaultSrc}></img>
                                </div>

                                <div className="block_description_wrapper">
                                    <div className="block_description_icon">
                                        {localStorage.getItem('authenticated') !== 'true' ?
                                            item.viewstatus === 'FREE' ?
                                                <img src="/icons/block 120px (2ea3a6).svg" alt="block icon"></img>
                                                :
                                                <img src="/icons/block 120px (d4d1d8).svg" alt="block icon"></img>
                                            :
                                            <img src="/icons/block 120px (2ea3a6).svg" alt="block icon"></img>
                                        }
                                    </div>
                                    <div className="block_description">
                                        <div className={"block_description_title" + (localStorage.getItem('authenticated') !== 'true' && item.viewstatus !== 'FREE' ? " paid" : "")}>{item.block_name}</div>
                                        <div className="block_description_topics">
                                            {item.blocktopics + (item.blocktopics === 1 ? ' topic' : ' topics')}
                                        </div>
                                    </div>
                                </div>

                                {localStorage.getItem('authenticated') === 'true' ?

                                    <div>
                                        <div className="block_access">
                                            <div className="block_access_wrapper">
                                                <div className="block_access_text">{item.blocktopicscompleted} of {item.blocktopics} topics completed</div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <div className={"block_access" + (item.viewstatus === 'FREE' ? " free" : "")}>
                                        {item.viewstatus === 'FREE' &&
                                            <div className="block_access_wrapper">
                                                <div className="block_access_img"><img src="/icons/lock_open 20px (ffffff).svg"
                                                                                       alt="block icon"></img></div>
                                                <div className="block_access_text free">tap for a free preview!</div>
                                            </div>
                                        }
                                        {item.viewstatus !== 'FREE' &&
                                        <div className="block_access_wrapper">
                                            <div className="block_access_img"><img src="/icons/lock_closed 20px (cecbd2).svg"
                                                                                   alt="block icon"></img></div>
                                            <div className="block_access_text">tap to sign in and view</div>
                                        </div>
                                        }
                                    </div>
                                }

                            </div>

                        )}
                    </div>
                </div>

                {localStorage.getItem('authenticated') === 'true' &&
                    <div className="maincontentblock mcb_tapcircle">

                        <div className="tab_wrapper">
                            {/*<div className="tab_item" onClick={() => this.tab_tapcircle_onClick()}>
                                    <div className={"tab_item_text " + (this.state.displayTapcircle ? "tapcircle_tabactive" : "")}>Tap Circle&trade; Forum</div>
                                </div>*/}
                            {localStorage.getItem('authenticated') === 'true' &&
                            <div className="tab_item" onClick={() => this.tab_myjournal_onClick()}>
                                <div
                                    className={"tab_item_text " + (this.state.displayMyJournal ? "tapcircle_tabactive" : "")}>My
                                    Notes
                                </div>
                            </div>
                            }
                        </div>

                        {this.state.displayTapcircle &&
                        <div>

                            <div className="tapcircle_title">
                                Join the conversation with other TAP Advance members.
                            </div>

                            {localStorage.getItem('authenticated') !== 'true' &&
                            <div className="tapcircle_signup">
                                <a href="/signup">
                                    <div className="tapcircle_signup_cta_button_wrapper"><Button primary
                                                                                                 className="btn_primary_ffffff_eb6060 tapcircle_signup_cta_button">SIGN
                                        UP TO JOIN THE CONVERSATION</Button></div>
                                </a>
                                <div>Already a member? <a href="/signin">Sign In</a></div>
                            </div>
                            }

                            <div className="tapcircle_header">
                                <div className="tapcircle_header_stats">
                                    <div className="tapcircle_header_stats_img"><img src="/icons/comment 20px (bcb6c3).svg"
                                                                                     alt="comment icon"></img></div>
                                    <div className="tapcircle_header_stats_text">{this.state.comments.length} Comments</div>
                                </div>
                                {localStorage.getItem('authenticated') === 'true' &&
                                <div className="tapcircle_header_comment">
                                    <Form onSubmit={this.onSubmit_tapcircle}>
                                        <Form.TextArea id="tapcircle_comment"
                                                       name='comment'
                                                       rows={4}
                                                       value={this.state.comment}
                                                       onChange={this.onChange_tapcircle}
                                                       control={TextArea}
                                                       placeholder="Join the conversation..."
                                        />
                                        {!this.state.tapcircle_submitting
                                            ?
                                            <Button primary
                                                    id="btn_tapcircle_submit"
                                                    type='submit'
                                            >Submit</Button>
                                            :
                                            <Button loading
                                                    id="btn_tapcircle_submit"
                                            >Submit</Button>
                                        }
                                    </Form>
                                </div>
                                }
                            </div>

                            <div className="tapcircle_comments">
                                {this.state.comments.map((item, i, elements) =>
                                    <div key={i}>
                                        <div
                                            className={"tapcircle_comments_item " + (item.parentcomment_id === 0 ? "parentcomment " : "replycomment ")}>
                                            <div className="tapcircle_comments_item_member">{item.membername}</div>
                                            <div className="tapcircle_comments_item_comment">{item.comment}</div>
                                            <div className="tapcircle_comments_item_likes">
                                                <div className="tapcircle_comments_item_likes_icon"
                                                     onClick={() => this.tapcircle_like_onClick(item.commentlike_id)}>
                                                    <img id={"commentlike_icon" + item.commentlike_id}
                                                         src={item.liked > 0 ? "/icons/heart 20px (eb6060).svg" : "/icons/heart 20px (bcb6c3).svg"}
                                                         alt="heart icon"></img>
                                                </div>
                                                <div className="tapcircle_comments_item_likes_count">| {item.likes}</div>
                                            </div>
                                        </div>

                                        {((elements[i + 1] && elements[i + 1]['parentcomment_id'] === 0) || !elements[i + 1]) &&
                                        <div className="tapcircle_comments_item_replyform">
                                            <Form onSubmit={this.onSubmit_tapcirclereply}
                                                  className="tapcircle_comments_item_replyform_form">
                                                <Form.TextArea id="tapcircle_reply"
                                                               name={'comment' + item.id}
                                                               rows={2}
                                                               value={this.state['comment' + item.id]}
                                                               onChange={this.onChange_tapcirclereply}
                                                               control={TextArea}
                                                               placeholder="Write a response..."
                                                />
                                                <input type="hidden" id="parentcomment_id" name="parentcomment_id"
                                                       value={item.id}></input>
                                                {!this.state['tapcircle_submittingreply' + item.id]
                                                    ?
                                                    <Button primary
                                                            id="btn_tapcircle_submitreply"
                                                            type='submit'
                                                    >Submit</Button>
                                                    :
                                                    <Button loading
                                                            id="btn_tapcircle_submitreply"
                                                    >Submit</Button>
                                                }
                                            </Form>
                                        </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                        }

                        {this.state.displayMyJournal &&
                        <div>
                            <div className="tapcircle_title">
                                Record a personal note in your private online notebook.
                            </div>

                            <div className="tapcircle_header">
                                <div className="tapcircle_header_stats">
                                    <div className="tapcircle_header_stats_img"><img src="/icons/comment 20px (bcb6c3).svg"
                                                                                     alt="comment icon"></img></div>
                                    <div className="tapcircle_header_stats_text">{this.state.journalcomments.length} Notes
                                    </div>
                                </div>
                                <div className="tapcircle_header_comment">
                                    <Form onSubmit={this.onSubmit_journal}>
                                        <Form.TextArea id="tapcircle_journalcomment"
                                                       name='journalcomment'
                                                       rows={4}
                                                       value={this.state.journalcomment}
                                                       onChange={this.onChange_journal}
                                                       control={TextArea}
                                                       placeholder="Record your personal thoughts..."
                                        />
                                        {!this.state.journal_submitting
                                            ?
                                            <Button primary
                                                    id="btn_journal_submit"
                                                    type='submit'
                                            >Submit</Button>
                                            :
                                            <Button loading
                                                    id="btn_journal_submit"
                                            >Submit</Button>
                                        }
                                    </Form>
                                </div>
                            </div>

                            <div className="tapcircle_comments">
                                {this.state.journalcomments.map((item, i, elements) =>
                                    <div key={i}>
                                        <div className="tapcircle_comments_item journalcomment">
                                            <div className="tapcircle_comments_item_member">{item.lastmodified}</div>
                                            <div className="tapcircle_comments_item_comment">{item.comment}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>
        );
    }
};