import React, { Component } from "react";
import { API } from "aws-amplify";
import "./blog.css";
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';



export default class blog extends Component {


    constructor(props) {
        super(props);

        this.state = {
            didmount: false,
            blog: {}
        };


    }

    async componentDidMount() {

        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if(this.state.didmount === false) {
            this.populateblog()
            this.state.didmount = true;
        }
    }


    async populateblog(){
        try {
            const blog = await this.API_get_blog();
            this.setState({ blog: blog[0] });

        } catch (e) {
            console.log(e);
        }
    }

    //  API call to get-events
    API_get_blog() {
        return API.get("tap", "/get-blog/"+this.props.match.params.blogid);
    }

    addDefaultSrc(ev){
        ev.target.src = "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg"
    }

    render() {

        const window = (new JSDOM('')).window;
        const DOMPurify = createDOMPurify(window);

        return (
            <div className="containerwrapper">
                <Helmet>
                    <title>{`Tap Advance | ${this.state.blog.blog_name}`}</title>
                </Helmet>

                <div className="maincontentblock mcb_blog_header">
                    <div className="blog_title">{this.state.blog.blog_name}</div>
                    <div className="blog_author">{this.state.blog.author}</div>
                </div>

                <div className="maincontentblock">
                    <div className="page_header_img">
                        <img src={`https://tap-app-resources.s3.amazonaws.com/public/jpg/blog_${this.props.match.params.blogid}.jpg`}
                             alt={`primary header for blog id ${this.props.match.params.blogid}`}
                             onError={this.addDefaultSrc} alt={`primary header for blog id ${this.props.match.params.blogid}`} alt={`primary header for content id ${this.props.match.params.blogid}`}/>
                    </div>
                </div>

                <div className="maincontentblock mcb_blog_content">
                    <div className="blog_content">
                        { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.blog.content) }} /> }
                    </div>
                </div>

                <div className="maincontentblock mcb_blog_author">
                    <div className="blog_author_wrapper">
                        <div className="blog_author_avatar">
                            <div className="blog_author_avatar_img">
                                <img src={`https://tap-app-resources.s3.amazonaws.com/public/jpg/author_${this.state.blog.blog_author_id}.jpg`}
                                                                         alt={`primary header for author id ${this.state.blog.blog_author_id}`}
                                                                         onError={this.addDefaultSrc} alt={`primary header for author id ${this.state.blog.blog_author_id}`} alt={`primary header for author id ${this.state.blog.blog_author_id}`}/></div>
                        </div>
                        <div className="blog_author_details">
                            <div className="blog_author_details_name">{this.state.blog.author}</div>
                            <div className="blog_author_details_title">{this.state.blog.role}</div>
                            <div className="blog_author_details_bio">{this.state.blog.bio}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}