import React, { Component } from "react";
import { Button, Form, Input, Message, Modal } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";

import Swal from "sweetalert2";
const { encrypt, decrypt } = require("./../containers/signupCardInfo/crypto");

class UpdateCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardnumber: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
      currentCard: {},
    };
  }

  componentDidMount() {
    const vm = this;
    vm.API_get_member_payment_method().then(function (res) {
      if (res._status_response === "success") {
        vm.setState({ currentCard: res.paymentmethods[0] });
      }
    });
  }

  onChange_input = (event) => {
    if (event.target.name === "cardnumber") {
      let card = event.target.value;

      this.setState({
        cardnumber: this.transform(card),
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSubmitForm = async (event) => {
    event.preventDefault();
    const vm = this;

    Swal.fire({
      title: "Processing",
      text: "Please wait...",
      confirmButtonColor: "#35a3a5",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    try {
      let card_info = {
        cardnumber: this.state.cardnumber.replace(/\s+/g, ""),
        exp_month: this.state.exp_month,
        exp_year: this.state.exp_year,
        cvc: this.state.cvc,
      };

      let ciphertext = encrypt(JSON.stringify(card_info));
      let data = {
        cardinfo: ciphertext,
      };

      console.log(data);

      await this.API_put_member(data).then(function (res) {
        console.log(res);
        if (res._status_response === "success") {
          Swal.fire({
            title: "Processed",
            text: "Payment method updated!",
            confirmButtonColor: "#35a3a5",
            confirmButtonText: "Ok",
          }).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire({
            text: res.message,
            confirmButtonColor: "#35a3a5",
            confirmButtonText: "Ok",
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: e.response.data._status_message,
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      });
    }
  };

  API_put_member(body) {
    return API.put(
      "tap",
      `/put-member-paymentmethod/${localStorage.getItem("cognitoid")}`,
      {
        body,
      }
    );
  }

  API_get_member_payment_method() {
    return API.get(
      "tap",
      `/get-member-paymentmethod/${localStorage.getItem("cognitoid")}`
    );
  }

  transform = (value) => {
    if (value != null) {
      value = value
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    }
    return value;
  };

  render() {
    return (
      <div
        // id="mdl_updatecreditcard"
        closeOnDimmerClick={false}
        dimmer={"inverted"}
        // open={this.props.modalOpen}
        // onClose={() => this.props.toggle("updateCardModal")}
      >
        <Form
          className="tapform"
          id="frm_signup"
          onSubmit={this.handleSubmitForm}>
          <div className="modal-header">Update your credit card</div>
          <div className="modal-content">
            {this.state.currentCard && (
              <div className="mb-4 bg-white rounded-sm p-4 ">
                <span className="font-medium">Current Card - </span>{" "}
                {this.state.currentCard.card_brand &&
                  this.state.currentCard.card_brand.toUpperCase()}
                <br />
                <span className="font-medium">Card number </span> -{" "}
                {this.state.currentCard.card_number_label} <br />{" "}
                <span className="font-medium"> Expires </span> -{" "}
                {this.state.currentCard.card_exp_month}/
                {this.state.currentCard.card_exp_year}
              </div>
            )}
            <Form.Group widths="equal">
              <Form.Field
                id="cardnumber"
                name="cardnumber"
                value={this.state.cardnumber || ""}
                onChange={this.onChange_input}
                control={Input}
                label={"Card Number"}
                placeholder={"Card Number"}
                required
                maxLength={19}
                minLength={19}
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="exp_month"
                name="exp_month"
                value={this.state.exp_month || ""}
                onChange={this.onChange_input}
                control={Input}
                label={"Expiration - Month"}
                placeholder={"00"}
                required
                maxLength={2}
                minLength={2}
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                id="exp_year"
                name="exp_year"
                value={this.state.exp_year || ""}
                onChange={this.onChange_input}
                control={Input}
                label={"Expiration - Year"}
                placeholder={"0000"}
                required
                maxLength={4}
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                minLength={4}
              />{" "}
            </Form.Group>
            <Form.Field
              id="cvc"
              name="cvc"
              value={this.state.cvc || ""}
              onChange={this.onChange_input}
              control={Input}
              label={"Security Code"}
              placeholder={"000"}
              style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
              required
              maxLength={3}
              minLength={3}
            />
            <button
              // disabled={!this.validateForm()}
              type="submit"
              className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-2 px-8 rounded disabled mr-2">
              Update
            </button>
            {/* <button
              onClick={() => this.props.toggle("updateCardModal")}
              className="mt-4 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-8 rounded disabled">
              Cancel
            </button> */}
          </div>
          {/* <div className="modal-footer">
              <div className="modal-footer-buttons">
              
              </div>
            </div> */}
        </Form>
      </div>
    );
  }
}

export default UpdateCardModal;
