import React, { Component } from "react";
import { Form, Input, Button, Message } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import "./verification.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

export default class verification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_loading: false,
      email: "",
      password: "",
      authentication: "",
    };

    this.cognitodata = {};
  }

  componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    let email = new URLSearchParams(window.location.search).get("email");
    this.setState({ email: email });
    console.log(email);
  }

  //  login input field change handler
  onChange_login = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  //  login form submit handler
  onSubmit_login = async (event) => {
    event.preventDefault();
    this.setState({ user_loading: true });
    this.setState({ authentication: "" });
    this.login();
  };

  async login() {
    try {
      //  check if user needs to be migrated from old cognito.  Can be deleted when all users have migrated
      let migration = await this.API_put_migration();
      console.log(migration);

      let cognitodata = await Auth.signIn(
        this.state.email.toLowerCase(),
        this.state.password
      );
      console.log(cognitodata);
      localStorage.setItem("cognitoid", cognitodata.username);
      localStorage.setItem("authenticated", true);
      this.populateuser(cognitodata.username);
    } catch (e) {
      this.setState({ user_loading: false });
      this.setState({ authentication: "failed" });
      console.log(e);
    }
  }

  async populateuser(cognitousername) {
    try {
      this.displaymemberpage();
      let member = await this.API_get_member(cognitousername).then(function (
        res
      ) {
        console.log(res);
      });
      localStorage.setItem("firstname", member[0]["firstname"]);
      localStorage.setItem("lastname", member[0]["lastname"]);
      localStorage.setItem("email", this.state.email);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      console.log(e);
    }
  }

  passwordreset() {
    this.props.history.push("/reset");
  }

  displaymemberpage() {
    this.props.history.push("/member");
  }

  //  API call to get-user
  API_put_migration() {
    let body = {};
    body.email = this.state.email.toLowerCase();
    body.password = this.state.password;
    return API.put("tap", "/put-migration", { body });
  }

  //  API call to get-user
  API_get_member(cognitousername) {
    return API.get("tap", "/get-member/" + cognitousername);
  }

  //  render login ---------------------------------------------------------------------------------------------------
  render() {
    return (
      <div className="verify_wrapper">
        <Helmet>
          <title>{`Tap Advance | Sign In`}</title>
        </Helmet>

        <div className="verify">
          <div className="">
            {/* <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img> */}
            <div
              onClick={() => (window.location.href = "/signup")}
              style={{
                width: "10%",
                height: "50%",
                float: "left",
                marginTop: 8,
                cursor: "pointer",
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10pt"
                color="#FFF"
                height="15pt"
                viewBox="0 0 25 28">
                <path
                  fill="#35a3a5"
                  d="M14.277 24.203l-1.23 1.207a1.342 1.342 0 01-1.879 0L.387 14.848a1.29 1.29 0 010-1.848L11.168 2.43a1.35 1.35 0 011.879 0l1.23 1.207a1.29 1.29 0 01-.023 1.867l-6.68 6.242h15.934c.738 0 1.332.582 1.332 1.305v1.738c0 .727-.594 1.305-1.332 1.305H7.574l6.68 6.246c.543.504.555 1.348.023 1.863zm0 0"
                />
              </svg>
            </div>
            <div style={{ width: "50%", height: "50%", float: "left" }}>
              <h1 className="text-4xl text-left"> Verification</h1>{" "}
            </div>
            <div style={{ width: "100%", height: "50%", clear: "both" }}>
              We have sent an email to{" "}
              <span className="font-semibold">{this.state.email}</span>.
              <br /> Please check the email to verify your account.{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
