import React, { Component } from "react";
import { Button, Form, Input, Message, Modal } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import CancelSuccessModal from "./CancelSuccessModal.js";

import Swal from "sweetalert2";
const { encrypt, decrypt } = require("./../containers/signupCardInfo/crypto");

class CancelMembershipModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mdl_cancel_success: false,
    };
  }

  componentDidMount() {
    const vm = this;
  }

  API_put_cancelmembership() {
    return API.put(
      "tap",
      "/put-cancelrequest/" + localStorage.getItem("cognitoid")
    );
  }

  async onClick_cancelmembership(thank_you_modal) {
    this.setState({ mdl_cancelmembership_updating: true });
    try {
      await this.API_put_cancelmembership();
      this.setState({ mdl_cancelmembership_updating: false });
      this.setState({ mdl_cancel_success: true });

      await Auth.signOut();
      localStorage.setItem("authenticated", false);
      localStorage.setItem("firstname", "");
      localStorage.setItem("lastname", "");
      localStorage.setItem("cognitoid", "");
      localStorage.setItem("email", "");
    } catch (e) {
      console.log(e);
    }
  }

  noButton = () => {
    this.setState(() => this.props.toggle("cancelMembershipModal"));
    window.location.reload();
  };

  render() {
    return (
      <>
        <CancelSuccessModal
          modalOpen={this.state.mdl_cancel_success}
          toggle={() =>
            this.setState({
              mdl_cancel_success: !this.state.mdl_cancel_success,
            })
          }
        />
        <Modal
          id="mdl_updatecreditcard"
          dimmer={"inverted"}
          open={this.props.modalOpen}
          onClose={() => this.props.toggle("cancelMembershipModal")}>
          <div className="modal-header">Cancel Membership</div>
          <div className="modal-content">
            Do you really want to cancel your TAP Advance membership?
          </div>
          <div className="modal-footer">
            <div className="modal-footer-buttons">
              <Button onClick={() => this.noButton()}>NO</Button>
              {!this.state.mdl_cancelmembership_updating ? (
                <Button
                  className="activestate"
                  onClick={() => this.onClick_cancelmembership()}>
                  YES
                </Button>
              ) : (
                <Button loading className="activestate">
                  YES
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default CancelMembershipModal;
