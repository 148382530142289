import React from "react";
import { Component } from "react";
import ReactGA from 'react-ga';
import "./what-is-a-learning-path.css";
import {Helmet} from "react-helmet";

export default class whatIsALearningPath extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

    }


//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">

                <Helmet>
                    <title>{`Tap Advance | What Is A Learning Path`}</title>
                </Helmet>

                <div className="maincontentblock" id="mcb_wialp">
                    <p>TAP Advance offers many ways to learn including full skill building modules, but our learning paths have been designed to answer specific questions and address common problems experienced by people just like you. Rather then mastering an entire skill (as you would in a module) a learning path helps you solve a specific problem.</p>
                </div>

                <div className="maincontentblock" id="mcb_wialp_wrapper">
                    <div className="wialp_1"></div>

                    <div className="wialp_2">
                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_1.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">We have talked to our learning specialists and our industry experts to put together a curated list to answer some of the most common problems and challenges for entrepreneurs.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_2.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">A learning path allows you to approach a problem from many different angles, so that you are covering all the bases when it comes to problem solving.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_3.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">If you are looking to learn everything you need to know about something specific like prospecting, by all means check out the Prospecting module to master this specific skill.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_4.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">If you don’t know how to sell, check out the learning path ‘How to Sell’ which covers topics like: What is Sales, What Motivates People to Buy, What is Social Selling, Simply Share your Products, Simply Share your Business Opportunity, Helping People Make a Decision,  What are Sales Objections, Starting the Conversation... and so much more! .</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_5.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">TAP’s custom learning paths answer tough questions as well as address hot topics and known industry challenges.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_6.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">Although learning is fun in a self-paced environment and mastering a skill is super rewarding, we want to make sure you can find the solutions you need, when you need them.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_7.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">Stay accountable to your learning as you complete each block and check it off on your checklist and receive recognition as you complete your learning path. </div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_8.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">When facing a challenge it can be hard to know where to start.  Our TAP learning paths remove the noise so you can focus on finding the right solution for you.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>

                        <div className="wialp_2_item">
                            <div className="wialp_2_item_img"><img src="/icons/wialp_9.svg" alt="wialp icon 1"></img></div>
                            <div className="wialp_2_text">We want to help take the guess work out and provide you with proven solutions so you can tap into your potential.</div>
                            <div className="wialp_2_separator">
                                <div className="wialp_2_separator_spacer"></div>
                                <div className="wialp_2_separator_border"></div>
                                <div className="wialp_2_separator_underline"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}