import React from "react";
import { Component } from "react";
import { Button, Form, Input } from 'semantic-ui-react'
import { Auth } from "aws-amplify";

import "./workshops.css";

export default class workshops extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: '',
            password: '',
            cardnumber: '',
            expiry: '',
            cvc: '',
            confirmPassword: "",
            confirmationCode: "",
            newUser: null
        };
    }



//  update state variables when form changes
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    validateForm() {
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }



    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }



    handleSubmitForm = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            const newUser = await Auth.signUp({
                username: this.state.email,
                password: this.state.password
            });
            this.setState({
                newUser
            });
        } catch (e) {
            //alert(e.message);
        }

        this.setState({ isLoading: false });
    };



    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            await Auth.signIn(this.state.email, this.state.password);

            this.props.userHasAuthenticated(true);
            this.props.history.push("/member");
        } catch (e) {
            //alert(e.message);

            this.setState({ isLoading: false });
        }
    };



    renderConfirmationForm() {
        return (
            <div className="confirmation">
                <Form className='tapform'
                      id='frm_confirmation'
                      onSubmit={this.handleConfirmationSubmit}
                >
                    <Form.Field
                        id='confirmation_code'
                        name='confirmationCode'
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                        control={Input}
                        label='Confirmation Code'
                        placeholder='000000'
                    />
                    <Button primary
                            disabled={!this.validateConfirmationForm()}
                            type='submit'
                            isLoading={this.state.isLoading}>Verify</Button>
                </Form>
            </div>
        );
    }



//  render page --------------------------------------------------------------------------------------------------------

    renderForm() {
        return (
            <div className="signup">
                <Form className='tapform'
                      id='frm_signup'
                      onSubmit={this.handleSubmitForm}
                >
                    <Form.Field
                        id='signup_email'
                        name='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                        control={Input}
                        label='Email'
                        placeholder='Email'
                    />
                    <Form.Field
                        id='signup_password'
                        name='password'
                        value={this.state.password}
                        onChange={this.handleChange}
                        control={Input}
                        type='password'
                        label='Password'
                        placeholder='Password'
                    />
                    <Form.Field
                        id='signup_cardnumber'
                        name='cardnumber'
                        value={this.state.cardnumber}
                        onChange={this.handleChange}
                        control={Input}
                        label='Card number'
                        placeholder='0000 0000 0000 0000'
                    />
                    <Form.Group>
                        <Form.Field
                            id='signup_expiry'
                            name='expiry'
                            value={this.state.expiry}
                            onChange={this.handleChange}
                            control={Input}
                            label='Expiry'
                            placeholder='MMYY'
                        />
                        <Form.Field
                            id='signup_cvc'
                            name='cvc'
                            value={this.state.cvc}
                            onChange={this.handleChange}
                            control={Input}
                            label='CVC'
                            placeholder='000'
                        />
                    </Form.Group>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <div className="containerwrapper">
                <div className="maincontentblock" id="page_description">
                    <h1>Workshops</h1>
                    <p>Sign up page...</p>
                </div>
                <div className="maincontentblock">
                    {this.state.newUser === null
                        ? this.renderForm()
                        : this.renderConfirmationForm()}
                </div>
            </div>
        );
    }
}