import React from "react";
import { Component } from "react";
import ReactGA from 'react-ga';

import "./privacy-policy.css";

export default class privacyPolicy extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }






//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">
                <div className="maincontentblock" id="page_description">
                    <h1>Privacy Policy</h1>
                    <p>Description text....</p>
                </div>

            </div>
        );
    }
}