import React, { Component } from "react";
import { API } from "aws-amplify";
import "./landing_i-dont-know-anyone-else.css";
import { Button } from "semantic-ui-react";
import createDOMPurify from "dompurify";
import { JSDOM } from "jsdom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

export default class landing_i_dont_know_anyone_else extends Component {
  constructor(props) {
    super(props);

    this.state = {
      landingpage: {},
      modules: [],
      contents: [],
      assets: [],
      learningpath: {},
    };
  }

  async componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    try {
      const landingpage = await this.API_get_landingpage();
      this.setState({ landingpage: landingpage[0] });
      const modules = await this.API_get_landingpagemodules();
      this.setState({ modules });
    } catch (e) {
      console.log(e);
    }

    try {
      const learningpath = await this.API_get_learningpath();
      this.setState({ learningpath: learningpath[0] });
    } catch (e) {
      console.log(e);
    }

    window.scrollTo(0, 0);
  }

  //  API call to get-events
  API_get_landingpage() {
    return API.get("tap", "/get-landingpage/3");
  }

  //  API call to get-events
  API_get_landingpagemodules() {
    return API.get("tap", "/get-landingpagemodules/3");
  }

  //  API call to get-learningpath
  API_get_learningpath() {
    return API.get("tap", "/get-learningpath/3");
  }

  module_onClick(module_id) {
    this.props.history.push("/module/" + module_id);
  }

  whatislearningpath_onClick() {
    this.props.history.push("/what-is-a-learning-path");
  }

  learningpath_onClick() {
    this.props.history.push("/learningpath/3");
  }

  render() {
    const window = new JSDOM("").window;
    const DOMPurify = createDOMPurify(window);

    return (
      <div className="containerwrapper">
        <Helmet>
          <title>{`Tap Advance | I Don't Know Anyone Else`}</title>
        </Helmet>

        <div className="maincontentblock mcb_landing_header">
          <div className="landing_title">I Don't Know Anyone Else</div>
          <div className="landing_image">
            <img
              src="/icons/i-dont-know-anyone-else3.png"
              alt="landing page header"></img>
          </div>
        </div>
        <div className="maincontentblock mcb_landing_subheader landing-subheader-margin">
          {
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.state.landingpage.description),
              }}
            />
          }
        </div>

        {!this.props.signedin && (
          <div className="maincontentblock mcb_landing_whatislearningpath">
            <div className="whatislearningpath_wrapper">
              <div className="whatislearningpath_icon">
                <img
                  src="/icons/LearningPath-Icon.svg"
                  alt="learning path icon"></img>
              </div>
              <div className="whatislearningpath_description">
                <div className="whatislearningpath_description_header">
                  What is a Learning Path
                </div>
                <div className="whatislearningpath_description_description">
                  TAP Advance offers many ways to learn including curated
                  Learning Paths and full skill building Modules. Rather than
                  mastering an entire subject (as you would in a module) a
                  learning path helps you solve a specific problem by looking at
                  it from many different angles. Each learning path has been
                  carefully selected from our content to answer specific
                  questions and address common problems experienced by people
                  just like you.
                </div>
                <div className="whatislearningpath_description_cta">
                  <Button
                    primary
                    onClick={() => this.whatislearningpath_onClick()}>
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.signedin && (
          <div className="maincontentblock mcb_landing_whatislearningpath">
            <div className="whatislearningpath_wrapper">
              <div className="whatislearningpath_icon">
                <img
                  src="/icons/LearningPath-Icon.svg"
                  alt="learning path icon"></img>
              </div>
              <div className="whatislearningpath_description">
                <div className="whatislearningpath_description_header">
                  {this.state.learningpath.learningpath_name}
                </div>
                <div className="whatislearningpath_description_description">
                  {this.state.learningpath.description_short}
                </div>
                <div className="whatislearningpath_description_cta">
                  <Button primary onClick={() => this.learningpath_onClick()}>
                    Take This Journey
                  </Button>
                </div>
              </div>
            </div>
            <div className="whatislearningpath_link">
              <div className="whatislearningpath_link_text">
                What is a Learning Path?
              </div>
              <div className="whatislearningpath_cta">
                <Button
                  primary
                  className="btn_primary_ffffff_5e637c"
                  onClick={() => this.whatislearningpath_onClick()}>
                  Read More
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="maincontentblock mcb_landing_video">
          <div className="landing_video_wrapper">
            <iframe
              src="https://www.youtube.com/embed/B2OMyKR_WvM"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
          <div className="landing_video_description">
            <p>
              Do you find that you put unnecessary pressure on yourself when it
              comes to meeting new people? Perhaps you have talked to all the
              people you think might be interested, and now you don’t know where
              to turn to keep your funnel full. Maybe it’s time to change your
              mindset and let go of the end result.​
            </p>
            <p>
              Ultimately, it’s all in your attitude! Where is your creativity?
              Think about meeting someone new, be creative, think outside the
              box. Don’t think about building your business, think about
              building new relationships. The number one contributing factor to
              long-term happiness is how connected you feel to other people.
              Don’t think about hunting people down, think about broadening your
              base of relationships and genuinely connecting with other humans.
              Your happiness is at stake!
            </p>
            <p>
              Making a genuine connection can happen face to face or online. It
              can happen at the yoga studio, the grocery store, volunteering at
              your kid’s school, or in a Facebook group. We get so nervous about
              what people might think about us or our business that meeting new
              people has become a chore. ​
            </p>
            <p>
              It’s time to connect with more humans because you can help them!
              It’s time to remember that when you let go of the end result, when
              you see people as they are, when you listen to their needs and
              find ways to help them, that is what it’s all about. ​
            </p>
          </div>
        </div>

        {localStorage.getItem("authenticated") !== "true" && (
          <div className="maincontentblock mcb_signup">
            <div className="signup_wrapper">
              <div className="signup_1">
                <img src="/icons/person3.svg" alt="person 3"></img>
              </div>
              <div className="signup_2">
                Sign up and explore all our content!
              </div>
              <div className="signup_3">
                <a href="/signup">
                  <div className="signup_3_button_wrapper">
                    <Button
                      primary
                      className="btn_primary_eb6060_ffffff home_signup_block_4_cta_button">
                      SIGN UP
                    </Button>
                  </div>
                </a>
                <div>
                  Already a member? <a href="/signin">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="maincontentblock mcb_modules">
          <div className="modules_header">Related Content</div>
          <div className="modules_wrapper">
            {this.state.modules.map((item, i) => {
              return (
                <div
                  className="module"
                  key={i}
                  onClick={() => this.module_onClick(item.id)}>
                  <div className="module_description_wrapper">
                    <div className="module_description_icon">
                      {" "}
                      <img
                        src="/icons/module 120px (eb6060).svg"
                        alt="block icon"></img>{" "}
                    </div>
                    <div className="module_description">
                      <div className="module_description_title">
                        {item.module_name}
                      </div>
                      <div className="module_counts_wrapper">
                        <div className="module_description_views">
                          {item.contentviews === 0
                            ? "Be the first to view this!"
                            : item.moduleviews +
                              (item.moduleviews === 1 ? " view" : " views")}
                        </div>
                        <div className="module_description_blocks">
                          {item.moduleblocks +
                            (item.moduleblocks === 1 ? " block" : " blocks")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
