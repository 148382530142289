import React from "react";
import { Component } from "react";
import { API } from "aws-amplify";
import { Button, Form, TextArea } from 'semantic-ui-react'
import ReactGA from 'react-ga';
import "./mynotebook.css";
import {Helmet} from "react-helmet";

export default class mynotebook extends Component {

    constructor(props) {
        super(props);

        this.state = {
            journalcomments: [],
            journalcomment: "",
            journal_submitting: false
        };
    }

    async componentDidMount() {
        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        window.scrollTo(0, 0)

        if(localStorage.getItem('authenticated') !== 'true'){
            this.props.history.push("/");
        }else {
            try {
                const journalcomments = await this.API_get_journalcomments();
                this.setState({journalcomments});
            } catch (e) {
                console.log(e);
            }
        }

    }

    API_get_journalcomments() {
        return API.get("tap-tapcircle", "/get-journalcomments/"+localStorage.getItem('cognitoid'));
    }

    API_put_journalcomment(body) {
        return API.put('tap-tapcircle', '/put-journalcomment/', { body });
    }

    onChange_journal = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit_journal = async event => {
        event.preventDefault();
        this.setState({ journal_submitting: true })
        let journalcomment = {};
        journalcomment.learningpath_id=0;
        journalcomment.module_id=0;
        journalcomment.block_id=0;
        journalcomment.cognitousername=localStorage.getItem('cognitoid')
        journalcomment.comment=this.state.journalcomment;
        await this.API_put_journalcomment(journalcomment);
        this.setState({ journal_submitting: false })
        this.setState({ journalcomment: "" })

        try {
            const journalcomments = await this.API_get_journalcomments();
            this.setState({ journalcomments });
        } catch (e) {
            console.log(e);
        }
    };

    mynotebook_block_onClick(block_id){
        this.props.history.push("/block/"+block_id);
    }

    mynotebook_module_onClick(module_id){
        this.props.history.push("/module/"+module_id);
    }

    mynotebook_learningpath_onClick(learningpath_id){
        this.props.history.push("/learningpath/"+learningpath_id);
    }


//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">

                <Helmet>
                    <title>{`Tap Advance | My Notebook`}</title>
                </Helmet>


                <div className="maincontentblock mcb_tool_header">
                    {/*<div className="tool_header_icon">
                        <img src="/icons/Chart Your Journey Icon Journal-01.jpg" alt="block icon"></img>
                    </div>*/}
{/*
                    <div className="tool_header_wrapper">
*/}
                        <div className="tool_header_title">My Notebook</div>
{/*
                    </div>
*/}
                </div>

                <div className="tool_header_text">
                    <p>Finally, a notebook you can keep online! Use your Notebook to journal daily to spark creativity, increase emotional intelligence and evoke mindfulness! These are just some of the powerful benefits of journaling.</p>
                    <p>What is super cool about your TAP Notebook is that it is conveniently placed on every page as you review content! As you embark on your learning journey you can immediately take notes and record your private thoughts and ‘ah ha!’ moments.</p>
                </div>

                <div className="maincontentblock mcb_journal">
                    <div className="tapcircle_header">
                        <div className="tapcircle_header_stats">
                            <div className="tapcircle_header_stats_img"><img src="/icons/comment 20px (bcb6c3).svg" alt="comment icon"></img></div>
                            <div className="tapcircle_header_stats_text">{this.state.journalcomments.length} Notes</div>
                        </div>
                        <div className="tapcircle_header_comment">
                            <Form onSubmit={this.onSubmit_journal}>
                                <Form.TextArea id="tapcircle_journalcomment"
                                               name='journalcomment'
                                               rows={4}
                                               value={this.state.journalcomment}
                                               onChange={this.onChange_journal}
                                               control={TextArea}
                                               placeholder="Record your personal thoughts..."
                                />
                                {!this.state.journal_submitting
                                    ?
                                    <Button primary
                                            id="btn_journal_submit"
                                            type='submit'
                                    >Submit</Button>
                                    :
                                    <Button loading
                                            id="btn_journal_submit"
                                    >Submit</Button>
                                }
                            </Form>
                        </div>
                    </div>

                    <div className="tapcircle_comments">
                        {this.state.journalcomments.map((item, i, elements) =>
                            <div key={i}>
                                <div className="tapcircle_comments_item journalcomment">
                                    {item.block_id>0 && <div className="tapcircle_comments_item_member myjournal_clickable" onClick={() => this.mynotebook_block_onClick(item.block_id)}>{item.blockname} - {item.lastmodified}</div>}
                                    {item.module_id>0 && <div className="tapcircle_comments_item_member myjournal_clickable" onClick={() => this.mynotebook_module_onClick(item.module_id)}>{item.modulename} - {item.lastmodified}</div>}
                                    {item.learningpath_id>0 && <div className="tapcircle_comments_item_member myjournal_clickable" onClick={() => this.mynotebook_learningpath_onClick(item.learningpath_id)}>{item.learningpathname} - {item.lastmodified}</div>}
                                    {(item.block_id + item.module_id + item.learningpath_id) === 0 && <div className="tapcircle_comments_item_member">{item.lastmodified}</div>}
                                    <div className="tapcircle_comments_item_comment">{item.comment}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}