import React from "react";
import { Component } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Message,
  Popup,
} from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import ReactGA from "react-ga";
import "./signup.css";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { Redirect } from "react-router";

export default class signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      firstname: "",
      lastname: "",
      email: "",
      emailpermission: true,
      password: "",
      passwordConfirm: "",
      validatepassword_length: false,
      validatepassword_lowercase: false,
      validatepassword_uppercase: false,
      validatepassword_number: false,
      validatepassword_specialcharacter: false,
      errorMessage: "",
      // cardnumber: '',
      // cardholder: '',
      // exp_month: '',
      // exp_year: '',
      // cvc: ''
    };
  }

  validatePassword() {
    return (
      this.state.validatepassword_length === true &&
      this.state.validatepassword_lowercase === true &&
      this.state.validatepassword_uppercase === true &&
      this.state.validatepassword_number === true &&
      this.state.validatepassword_specialcharacter === true
    );
  }

  validatePasswordMatch() {
    return this.state.password === this.state.passwordConfirm;
  }

  handleSubmitForm = async (event) => {
    event.preventDefault();
    const vm = this;

    Swal.fire({
      title: "Loading",
      text: "Please wait...",
      confirmButtonColor: "#35a3a5",
      confirmButtonText: "Ok",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    if (this.validatePasswordMatch()) {
      if (this.validatePassword()) {
        try {
          let userdetails = {};
          userdetails["firstname"] = this.state.firstname;
          userdetails["lastname"] = this.state.lastname;
          userdetails["email"] = this.state.email;
          userdetails["emailpermission"] = this.state.emailpermission;
          userdetails["password"] = this.state.password;

          await this.API_put_member(userdetails).then(function (res) {
            console.log(res);
            if (res._status_response === "success") {
              window.location.href = `/verification?email=${res.email}`;
            } else {
              Swal.fire({
                title: "Error",
                text: res._status_message,
                confirmButtonColor: "#35a3a5",
                confirmButtonText: "Ok",
              });
            }
          });
        } catch (e) {
          console.log(e.response.data);
          Swal.fire({
            title: "Error",
            text: e.response.data._status_message,
            confirmButtonColor: "#35a3a5",
            confirmButtonText: "Ok",
          });
        }
      } else {
        Swal.fire({
          text: "Password does not meet the requirements",
          confirmButtonColor: "#35a3a5",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        text: "Passwords do not match",
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      });
    }
  };

  API_put_member(body) {
    return API.post("tap", "/post-member", { body });
  }

  //  update state variables when form changes
  onChange_input = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onChange_checkbox = (event, { name, type, checked }) => {
    let value = type === "checkbox" ? checked : this.state[name];
    this.setState({
      [name]: value,
    });
  };

  onChange_password = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    let password = event.target.value;

    if (password.length >= 8) {
      this.setState({ validatepassword_length: true });
    } else {
      this.setState({ validatepassword_length: false });
    }

    if (/[a-z]/.test(password)) {
      this.setState({ validatepassword_lowercase: true });
    } else {
      this.setState({ validatepassword_lowercase: false });
    }

    if (/[A-Z]/.test(password)) {
      this.setState({ validatepassword_uppercase: true });
    } else {
      this.setState({ validatepassword_uppercase: false });
    }

    if (/[0-9]/.test(password)) {
      this.setState({ validatepassword_number: true });
    } else {
      this.setState({ validatepassword_number: false });
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
      this.setState({ validatepassword_specialcharacter: true });
    } else {
      this.setState({ validatepassword_specialcharacter: false });
    }
  };

  //  render page --------------------------------------------------------------------------------------------------------

  renderForm() {
    if (localStorage.getItem("authenticated") === "true") {
      return <Redirect to="/member" />;
    }
    return (
      <div className="signup_new ">
        <Form
          error
          className="tapform"
          id="frm_signup"
          onSubmit={this.handleSubmitForm}>
          <Form.Field
            style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
            id="firstname"
            name="firstname"
            value={this.state.firstname || ""}
            onChange={this.onChange_input}
            control={Input}
            label={"First Name"}
            placeholder={"First Name"}
            required
          />

          <Form.Field
            style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
            id="lastname"
            name="lastname"
            value={this.state.lastname || ""}
            onChange={this.onChange_input}
            control={Input}
            label={"Last Name"}
            placeholder={"Last Name"}
            required
          />
          <Form.Field
            style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
            id="email"
            name="email"
            value={this.state.email || ""}
            onChange={this.onChange_input}
            control={Input}
            label={"Email"}
            placeholder={"Email"}
            required
          />

          <Popup
            content="Minimum of 8 characters, one uppercase and lowercase character, a number & a special character"
            trigger={
              <Form.Field
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                id="password"
                name="password"
                value={this.state.password || ""}
                onChange={this.onChange_password}
                control={Input}
                type="password"
                label={"Password"}
                placeholder={"Password"}
                required
              />
            }
          />
          <Popup
            content="Minimum of 8 characters, one uppercase and lowercase character, a number & a special character"
            trigger={
              <Form.Field
                style={{
                  border: "1px solid #9CA3AF",
                  borderRadius: 4,
                }}
                id="passwordConfirm"
                name="passwordConfirm"
                value={this.state.passwordConfirm || ""}
                onChange={this.onChange_password}
                control={Input}
                type="password"
                label={"Password Confirmation"}
                required
                placeholder={"Password"}></Form.Field>
            }
          />
          {/* {this.validateForm() && (
            <div className="ml-72 py-1.5">
              <svg
                className="mr-4"
                fill="#EB6160"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M8.152 20.598l-7.8-7.801a1.198 1.198 0 010-1.695l1.695-1.7a1.202 1.202 0 011.7 0L9 14.656 20.254 3.402a1.202 1.202 0 011.7 0l1.694 1.7a1.198 1.198 0 010 1.695l-13.8 13.8a1.198 1.198 0 01-1.696 0zm0 0" />
              </svg>
            </div>
          )} */}
          <Checkbox
            style={{ color: "#2EA3A6" }}
            id="checkbox"
            type="checkbox"
            label="Keep me up to date with news and updates"
            name="emailpermission"
            checked={this.state.emailpermission}
            onChange={this.onChange_checkbox}
          />
          {/* {this.state.errorMessage !== "" && (
            <Message error content={this.state.errorMessage} />
          )} */}

          <button className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded">
            Sign Up
          </button>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div className="containerwrapper bg-white" style={{ marginTop: 5 }}>
        <Helmet>
          <title>{`Tap Advance | Sign Up`}</title>
        </Helmet>

        <div className="signup_container_new" id="page_signup_new">
          <div className="page_signup_1 md:w-2/3 w-full">
            <div className="signup_box mb-20">
              <div
                className="login_logo py-5 mb-6"
                style={{ display: "flex", alignItems: "center" }}>
                {/* <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img> */}
                <div
                  className="mr-2 cursor-pointer"
                  onClick={() => (window.location.href = "/")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10pt"
                    color="#FFF"
                    height="15pt"
                    viewBox="0 0 25 28">
                    <path
                      fill="#35a3a5"
                      d="M14.277 24.203l-1.23 1.207a1.342 1.342 0 01-1.879 0L.387 14.848a1.29 1.29 0 010-1.848L11.168 2.43a1.35 1.35 0 011.879 0l1.23 1.207a1.29 1.29 0 01-.023 1.867l-6.68 6.242h15.934c.738 0 1.332.582 1.332 1.305v1.738c0 .727-.594 1.305-1.332 1.305H7.574l6.68 6.246c.543.504.555 1.348.023 1.863zm0 0"
                    />
                  </svg>
                </div>
                <h1 className="ml-4 text-4xl text-left font-bold"> Sign Up</h1>
              </div>
              {this.renderForm()}
            </div>
          </div>

          <div className="page_signup_2 md:w-1/3 w-full flex flex-col">
            <div className="page_signup_header font-semibold py-8 mb-10 px-10">
              Features
            </div>
            <div className="signup-features flex flex-col justify-between px-10 h-3/5">
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />
                <div className="">Unlimited Access</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Foundational skill building</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Modern Sales Training </div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Personal Growth</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Leadership Development</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">On Demand Learning</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Printable Worksheets</div>
              </div>
              <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="">Bite-sized Training</div>
              </div>
              {/* <div className="flex items-center my-2">
                <img
                  className="feature-check-mark"
                  src={process.env.PUBLIC_URL + "/check.png"}
                />{" "}
                <div className="font-semibold">And more</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
