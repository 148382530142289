import React from "react";
import { Component } from "react";
import { API } from "aws-amplify";
import "./library.css";
import {Document, Page} from "react-pdf";
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';

export default class library extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules: [],
            blocks: [],
            topics: [],
            lp: [],
            displayModule: true,
            displayBlock: false,
            displayTopic: false,
            displayLP: false,
            displayInfo: false,
            pdf_isLoading: false,
            pdf_isLoaded: false,
            pdf_file: "",
            pdf_pages: null,
            pdf_page: 1,
            resource_downloads: 0
        };
    }


    async componentDidMount() {
        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.state.filetype = 'pdf';
        this.state.pdf_isLoaded = false;
        this.state.pdf_isLoading = true;
        this.state.resource_downloads = '0';
        this.state.pdf_file = 'Content-Slideshow.pdf';
        this.state.pdf_page = 1;
        window.scrollTo(0, 0)
        this.populatelibrarymodules();
        this.populatelibraryblocks();
        this.populatelibrarytopics();
        this.populatelibrarylp();
    }

    async populatelibrarymodules(){
        try {
            const modules = await this.API_get_librarymodules();
            this.setState({modules});
        } catch (e) {
            console.log(e);
        }
    }

    async populatelibraryblocks(){
        try {
            const blocks = await this.API_get_libraryblocks();
            this.setState({blocks});
        } catch (e) {
            console.log(e);
        }
    }

    async populatelibrarytopics(){
        try {
            const topics = await this.API_get_librarytopics();
            this.setState({topics});
        } catch (e) {
            console.log(e);
        }
    }

    async populatelibrarylp(){
        try {
            const lp = await this.API_get_librarylp();
            this.setState({lp});
        } catch (e) {
            console.log(e);
        }
    }

    //  API call to get-module
    API_get_librarymodules() {
        return API.get("tap", "/get-librarymodules");
    }

    API_get_libraryblocks() {
        return API.get("tap", "/get-libraryblocks");
    }

    API_get_librarytopics() {
        return API.get("tap", "/get-librarytopics");
    }

    API_get_librarylp() {
        return API.get("tap", "/get-librarylearningpaths");
    }

    tab_module_onClick(){
        this.setState({   displayModule: true,
                                displayBlock: false,
                                displayTopic: false,
                                displayInfo: false,
                                displayLP: false});
    }

    tab_block_onClick(){
        this.setState({   displayModule: false,
                                displayBlock: true,
                                displayTopic: false,
                                displayInfo: false,
                                displayLP: false});
    }

    tab_topic_onClick(){
        this.setState({   displayModule: false,
                                displayBlock: false,
                                displayTopic: true,
                                displayInfo: false,
                                displayLP: false});
    }

    tab_info_onClick(){
        this.setState({   displayModule: false,
                                displayBlock: false,
                                displayTopic: false,
                                displayInfo: true,
                                displayLP: false});
    }

    tab_lp_onClick(){
        this.setState({   displayModule: false,
                                displayBlock: false,
                                displayTopic: false,
                                displayInfo: false,
                                displayLP: true});
    }

    tab_page_module_onClick(module_id){
        this.props.history.push("/module/"+module_id);
    }

    tab_page_block_onClick(block_id,viewstatus){
        if(localStorage.getItem('authenticated') !== 'true' && viewstatus === 'FREE') {
            this.props.history.push("/block/" + block_id);
        }else if(localStorage.getItem('authenticated') !== 'true' && viewstatus !== 'FREE') {
            this.props.history.push("/signin");
        }else if(localStorage.getItem('authenticated') === 'true') {
            this.props.history.push("/block/" + block_id);
        }
    }

    tab_page_topic_onClick(block_id,free){
        if(localStorage.getItem('authenticated') !== 'true' && free === 'YES') {
            this.props.history.push("/block/" + block_id);
        }else if(localStorage.getItem('authenticated') !== 'true' && free !== 'YES') {
            this.props.history.push("/signin");
        }else if(localStorage.getItem('authenticated') === 'true') {
            this.props.history.push("/block/" + block_id);
        }
    }

    tab_page_lp_onClick(learningpath_id){
        this.props.history.push("/learningpath/"+learningpath_id);
    }


    //  PDF successfully loaded handler
    PDF_onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({   pdf_isLoaded: true,
            pdf_isLoading: false,
            pdf_pages: numPages,
            resource_width: document.getElementById('PDF_viewer').offsetWidth})
        if(numPages === 1){
            this.resourcecomplete();
        }
    };

    //  display previous PDF page
    onCLick_previousPDFpage() {
        if(this.state.pdf_page - 1 > 0) {
            this.setState(state => ({pdf_page: state.pdf_page - 1}));
        }
    }


    //  display next PDF page
    async onCLick_nextPDFpage(){
        if(this.state.pdf_page < this.state.pdf_pages) {
            document.getElementById('PDF_viewer').style.height = (((document.getElementById('PDF_viewer').offsetWidth) / 16) * 9) + "px";
            this.setState(state => ({pdf_page: state.pdf_page + 1}));
        }
        if(this.state.pdf_page + 1 === this.state.pdf_pages){
            this.resourcecomplete();
        }
    }


    onCLick_refreshPDF(){
        this.setState(state => ({pdf_page: 1}));
    }

//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">

                <Helmet>
                    <title>{`Tap Advance | Library`}</title>
                </Helmet>

                <div className="maincontentblock" id="page_library">

                    <div className="library_tab_wrapper">
                        <div className="tab_item" onClick={() => this.tab_module_onClick()}>
                            <div className="tab_item_img"><img src={this.state.displayModule ? "/icons/module 120px (eb6060).svg" : "/icons/module 120px (d4d1d8).svg"} alt="module icon"></img></div>
                            <div className={"tab_item_text " + (this.state.displayModule ? "tabactive_module" : "")}>Modules</div>
                        </div>
                        <div className="tab_item" onClick={() => this.tab_block_onClick()}>
                            <div className="tab_item_img"><img src={this.state.displayBlock ? "/icons/block 120px (2ea3a6).svg" : "/icons/block 120px (d4d1d8).svg"} alt="block icon"></img></div>
                            <div className={"tab_item_text " + (this.state.displayBlock ? "tabactive_block" : "")}>Blocks</div>
                        </div>
                        <div className="tab_item" onClick={() => this.tab_topic_onClick()}>
                            <div className="tab_item_img"><img src={this.state.displayTopic ? "/icons/topic 120px (cfaea9).svg" : "/icons/topic 120px (d4d1d8).svg"} alt="block icon"></img></div>
                            <div className={"tab_item_text " + (this.state.displayTopic ? "tabactive_topic" : "")}>Topics</div>
                        </div>
                        <div className="tab_item" onClick={() => this.tab_lp_onClick()}>
                            <div className="tab_item_img"><img src={this.state.displayLP ? "/icons/learningpath 120px (434963).svg" : "/icons/learningpath 120px (d4d1d8).svg"} alt="block icon"></img></div>
                            <div className={"tab_item_text " + (this.state.displayLP ? "tabactive_lp" : "")}>Learning Paths</div>
                        </div>
                        <div className="tab_item" onClick={() => this.tab_info_onClick()}>
                            {/*<div className="tab_item_img"><img src="/icons/topic 120px (d4d1d8).svg" alt="block icon"></img></div>*/}
                            <div className={"tab_item_textonly " + (this.state.displayInfo ? "tabactive_info" : "")}>Information</div>
                        </div>
                    </div>

                    {this.state.displayModule &&
                        <div className="tab_page" id="tab_page_modules">
                            <div className="tab_page_header">
                                <div className="tab_page_header_img"><img src="/icons/module 120px (eb6060).svg" alt="module icon"></img></div>
                                <div className="tab_page_header_description">A module has been carefully curated with training & field experts. It’s a collection of like blocks that explore an entire subject.</div>
                            </div>
                            {this.state.modules.map((item, i) =>
                                <div className="tab_page_module" key={i} onClick={() => this.tab_page_module_onClick(item.id)}>
                                    <div className="tab_page_module_title">{item.module_name}</div>
                                    <div className="tab_page_module_description">{item.description_short}</div>
                                </div>
                            )}
                        </div>
                    }

                    {this.state.displayBlock &&
                        <div className="tab_page" id="tab_page_blocks">
                            <div className="tab_page_header">
                                <div className="tab_page_header_img"><img src="/icons/block 120px (2ea3a6).svg" alt="block icon"></img></div>
                                <div className="tab_page_header_description">A Block is a group of related topics that are thread together to provide a powerful piece of content.</div>
                            </div>
                            {this.state.blocks.map((item, i) =>
                                <div className="tab_page_block" key={i}
                                     onClick={() => this.tab_page_block_onClick(item.id,item.viewstatus)}>
                                    {localStorage.getItem('authenticated') !== 'true' && item.viewstatus !== 'FREE' &&
                                        <div className="block_access_wrapper">
                                            <div className="block_access_img"><img src="/icons/lock_closed 20px (cecbd2).svg" alt="block icon"></img></div>
                                            <div className="block_access_text">tap to sign in and view</div>
                                        </div>
                                    }
                                    <div className={"tab_page_block_title"  + (localStorage.getItem('authenticated') !== 'true' && item.viewstatus !== 'FREE' ? " paid" : "")}>{item.block_name}</div>
                                    <div className={"tab_page_block_description" + (localStorage.getItem('authenticated') !== 'true' && item.viewstatus !== 'FREE' ? " paid" : "")}>{item.description_short}</div>
                                </div>
                            )}
                        </div>
                    }

                    {this.state.displayTopic &&
                        <div className="tab_page" id="tab_page_topics">
                            <div className="tab_page_header">
                                <div className="tab_page_header_img"><img src="/icons/topic 120px (cfaea9).svg" alt="block icon"></img></div>
                                <div className="tab_page_header_description">A Topic is a bite-sized piece of training made up of resources that are valuable when consumed all on its own. </div>
                            </div>
                            {this.state.topics.map((item, i) =>
                                <div className="tab_page_topic" key={i} >
                                    {localStorage.getItem('authenticated') !== 'true' && item.free !== 'YES' &&
                                    <div className="block_access_wrapper">
                                        <div className="block_access_img"><img src="/icons/lock_closed 20px (cecbd2).svg" alt="block icon"></img></div>
                                        <div className="block_access_text">tap to sign in and view</div>
                                    </div>
                                    }
                                    <div className={"tab_page_topic_title" + (localStorage.getItem('authenticated') !== 'true' && item.free !== 'YES' ? " paid" : "")}onClick={() => this.tab_page_topic_onClick(item.block_id,item.free)}>{item.topic}</div>
                                    <div className="tab_page_topic_breadcrumb">
                                        <div className="tab_page_topic_module_img"><img src="/icons/module 120px (d4d1d8).svg" alt="module icon"></img></div>
                                        <div className="tab_page_topic_module" onClick={() => this.tab_page_module_onClick(item.module_id)}>{item.module_name}</div>
                                        <div className="tab_page_topic_block_img"><img src="/icons/block 120px (d4d1d8).svg" alt="block icon"></img></div>
                                        <div className="tab_page_topic_block" onClick={() => this.tab_page_block_onClick(item.block_id,item.free)}>{item.block_name}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                    {this.state.displayLP &&
                    <div className="tab_page" id="tab_page_lps">
                        <div className="tab_page_header">
                            <div className="tab_page_header_img"><img src="/icons/learningpath 120px (434963).svg" alt="module icon"></img></div>
                            <div className="tab_page_header_description">You can learn traditionally by using our library of modules  or solve an immediate problem by visiting the problem pages and clicking through to a learning path.</div>
                        </div>
                        {this.state.lp.map((item, i) =>
                            <div className="tab_page_lp" key={i} onClick={() => this.tab_page_lp_onClick(item.id)}>
                                <div className="tab_page_lp_title">{item.learningpath_name}</div>
                                <div className="tab_page_lp_description">{item.description_short}</div>
                            </div>
                        )}
                    </div>
                    }

                    {this.state.displayInfo &&
                        <div className="tab_page" id="tab_page_info">
                            <div className="pdf_viewer" id="PDF_viewer">
                                {this.state.pdf_file
                                    ?   <div>
                                        <Document
                                            file={"https://tap-app-resources.s3.amazonaws.com/public/pdf/Content-Slideshow.pdf"}
                                            onLoadSuccess={this.PDF_onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={this.state.pdf_page} min-width="0" width={this.state.resource_width} />
                                        </Document>
                                    </div>
                                    :   <div></div>
                                }
                            </div>
                            <div id="homepage_viewer_footer" className="resource_viewer_footer">

                                <div className="resource_viewer_footer_navigation">
                                    {this.state.pdf_isLoaded &&
                                    <div className="pdf_viewer_controls_btns">
                                        <div className={"pdf_viewer_controls_btn " +(this.state.pdf_page > 1 ? 'page_active' : 'page_inactive')} onClick={() => this.onCLick_previousPDFpage()}>PREVIOUS</div>
                                        <div className="pdf_viewer_controls_btn" onClick={() => this.onCLick_nextPDFpage()}>NEXT</div>
                                    </div>
                                    }
                                </div>
                                <div className="resource_viewer_footer_pages">
                                    <div className="resource_viewer_footer_pages_icon" onClick={() => this.onCLick_refreshPDF()}> <img src="/icons/refresh 20px (bcb6c3).svg" alt="block icon"></img> </div>
                                    Page {this.state.pdf_page} of {this.state.pdf_pages}
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    }
}