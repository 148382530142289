import React from "react";
import { Component } from "react";
import "./notfound.css";
import {Button} from "semantic-ui-react";

export default class notfound extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }






//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">
                <div className="maincontentblock mcb_404_header">
                    <img src="/icons/404-left.svg" alt="404 header left" id="left404"></img>
                    <img src="/icons/404-center.svg" alt="404 header center" id="center404"></img>
                    <img src="/icons/404-right.svg" alt="404 header right" id="right404"></img>
                </div>

                <div className="maincontentblock mcb_404_colorbar"></div>

                <div className="maincontentblock mcb_404_headertext">
                    <div className="headertext404_header">404!</div>
                    <div className="headertext404_text">Uh-oh, the page you have requested isn't here...</div>
                </div>

                <div className="maincontentblock mcb_404_text">
                    <p>Perhaps you're here because:</p>
                    <p>- the page has moved</p>
                    <p>- the page no longer exists</p>
                    <p>- you were looking for your lost turtle</p>
                    <p>- you enjoy 404 pages</p>
                    <div className="mcb_404_cta_button"><a href="https://tapadvance.net/"><Button primary className="btn_primary_2ea3a6_ffffff">Back to the home page!</Button></a></div>
                </div>
            </div>
        );
    }
}