import React, { Component } from "react";
import { Button, Form, Input, Message, Modal } from "semantic-ui-react";

class CancelSuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const vm = this;
  }

  render() {
    return (
      <Modal
        id="mdl_updatecreditcard"
        dimmer={"inverted"}
        open={this.props.modalOpen}
        onClose={() => (window.location.href = "/")}>
        <div className="modal-header">Thank you</div>
        <div className="modal-content">
          Thank you for submitting your request. The changes will be applied
          within 1 - 2 business days.
        </div>
        <div className="modal-footer">
          <div className="modal-footer-buttons">
            <Button onClick={() => (window.location.href = "/")}>CLOSE</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CancelSuccessModal;
