import React, { Component } from "react";
import { Form, Input, Button, Message, Loader } from "semantic-ui-react";
import { Auth, API } from "aws-amplify";
import "./signin.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Swal from "sweetalert2";
import { Redirect } from "react-router";

export default class signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_loading: false,
      email: "",
      password: "",
      authentication: "",
    };

    this.cognitodata = {};
  }

  componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //  login input field change handler
  onChange_login = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  //  login form submit handler
  onSubmit_login = async (event) => {
    event.preventDefault();
    this.setState({ user_loading: true });
    this.setState({ authentication: "" });
    this.login();
  };

  async login() {
    try {
      //  check if user needs to be migrated from old cognito.  Can be deleted when all users have migrated
      let migration = await this.API_put_migration();

      let cognitodata = await Auth.signIn(
        this.state.email.toLowerCase(),
        this.state.password
      );
      console.log(cognitodata);
      localStorage.setItem("cognitoid", cognitodata.username);
      localStorage.setItem("authenticated", true);
      this.populateuser(cognitodata.username);
    } catch (e) {
      this.setState({ user_loading: false });
      this.setState({ authentication: "failed" });
      console.log(e);
      Swal.fire({
        text: "Email or Password incorrect",
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      });
      console.log(e);
    }
  }

  async populateuser(cognitousername) {
    try {
      let member = await this.API_get_member(cognitousername);
      localStorage.setItem("firstname", member.firstname);
      localStorage.setItem("lastname", member.lastname);
      localStorage.setItem("email", this.state.email);
      this.props.userHasAuthenticated(true);
      await this.API_get_member_payment_method(cognitousername)
        .then(function (res) {
          console.log(res);
          localStorage.setItem("hasCard", true);

          window.location.href = "/member";
        })
        .catch(function (err) {
          console.log(err.response);
          if (err.response.status === 400) {
            localStorage.clear();
            Swal.fire({
              title: "Registration process incomplete!",
              text: "Please enter payment info using the confirmation email sent!",
              confirmButtonColor: "#35a3a5",
              confirmButtonText: "Ok",
            }).then(function () {
              window.location.reload();
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  passwordreset() {
    this.props.history.push("/forgot");
  }

  //  API call to get-user
  API_put_migration() {
    let body = {};
    body.email = this.state.email.toLowerCase();
    body.password = this.state.password;
    return API.put("tap", "/put-migration", { body });
  }

  //  API call to get-user
  API_get_member(cognitousername) {
    return API.get("tap", "/get-member/" + cognitousername);
  }

  API_get_member_payment_method(cognitousername) {
    return API.get("tap", `/get-member-paymentmethod/${cognitousername}`);
  }

  //  render login ---------------------------------------------------------------------------------------------------
  render() {
    if (localStorage.getItem("hasCard") === "true") {
      return <Redirect to="/member" />;
    }

    return (
      <div className="login_wrapper">
        <Helmet>
          <title>{`Tap Advance | Sign In`}</title>
        </Helmet>

        <div className="login green_border lg:p-12 mb-20">
          <div
            className="login_logo px-16 py-5"
            style={{ display: "flex", alignItems: "center" }}>
            {/* <img src="/icons/logo-tapadvance-full.svg" alt="Tap Advance logo"></img> */}
            <div
              className="mr-2 cursor-pointer"
              onClick={() => (window.location.href = "/")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10pt"
                color="#FFF"
                height="15pt"
                viewBox="0 0 25 28">
                <path
                  fill="#35a3a5"
                  d="M14.277 24.203l-1.23 1.207a1.342 1.342 0 01-1.879 0L.387 14.848a1.29 1.29 0 010-1.848L11.168 2.43a1.35 1.35 0 011.879 0l1.23 1.207a1.29 1.29 0 01-.023 1.867l-6.68 6.242h15.934c.738 0 1.332.582 1.332 1.305v1.738c0 .727-.594 1.305-1.332 1.305H7.574l6.68 6.246c.543.504.555 1.348.023 1.863zm0 0"
                />
              </svg>
            </div>
            <h1 className="ml-4 text-4xl text-left font-bold"> Sign In</h1>
          </div>
          {this.state.user_loading && <Loader active inline="centered" />}
          <div className="login_form px-4 py-10">
            <Form onSubmit={this.onSubmit_login}>
              <Form.Field
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                id="email"
                value={this.state.email}
                onChange={this.onChange_login}
                control={Input}
                label="Email"
                placeholder="Email"
              />
              <Form.Field
                style={{ border: "1px solid #9CA3AF", borderRadius: 4 }}
                id="password"
                value={this.state.password}
                onChange={this.onChange_login}
                control={Input}
                type="password"
                label="Password"
                placeholder="Password"
              />

              <div
                className="passwordreset"
                onClick={() => this.passwordreset()}>
                I forgot my password
              </div>

              {/* {this.state.authentication === "failed" && (
                <div className="authentication_message">
                  <Message color="red">
                    Your email or password was incorrect. Please try again
                  </Message>
                </div>
              )} */}

              {!this.state.user_loading ? (
                <button
                  type="submit"
                  className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded">
                  Sign In
                </button>
              ) : (
                <button className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded">
                  Sign In
                </button>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
