import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { API } from "aws-amplify";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import createDOMPurify from "dompurify";
import { JSDOM } from "jsdom";
import ReactGA from "react-ga";
import "./block.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

export default class block extends Component {
  constructor(props) {
    super(props);

    this.state = {
      block_name: "",
      content: "",
      block: {},
      topics: [],
      resource: {},
      pdf_isLoading: false,
      pdf_isLoaded: false,
      pdf_file: "",
      pdf_pages: null,
      pdf_page: 1,
      resource_downloads: 0,
      resource_width: null,
      mdl_CS_open: false,
      mdl_AS_open: false,
      displayTapcircle: false,
      displayMyJournal: true,
      tapcircle_submitting: false,
      comment: "",
      comments: [],
      tapcircle_submittingreply: false,
      parentcomment_id: 0,
      journalcomments: [],
      journalcomment: "",
      journal_submitting: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.resizeresource();
    window.addEventListener("resize", this.resizeresource);

    try {
      const result = await this.API_get_block(this.props.match.params.blockid);
      this.state.block = result[0];
      this.onClick_btn_resource_pdf(result[0]["defaultresource_id"], "pdf");
    } catch (e) {
      console.log(e);
    }

    try {
      const topics = await this.API_get_topics(this.props.match.params.blockid);
      this.setState({ topics });
    } catch (e) {
      console.log(e);
    }

    try {
      const comments = await this.API_get_comments(
        this.props.match.params.blockid
      );
      this.setState({ comments });
    } catch (e) {
      console.log(e);
    }

    try {
      const journalcomments = await this.API_get_journalcomments(
        this.props.match.params.blockid
      );
      this.setState({ journalcomments });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeresource);
  }

  //  API call to get block page details
  API_get_block(blockid) {
    return API.get("tap", "/get-block/" + blockid);
  }

  //  API call to get topics for block page
  API_get_topics(blockid) {
    if (!localStorage.getItem("cognitoid")) {
      localStorage.setItem("cognitoid", "null");
    }
    return API.get(
      "tap",
      "/get-topics/" + blockid + "/" + localStorage.getItem("cognitoid")
    );
  }

  //  API call to get resource details
  API_get_resource(topic_id, filetype) {
    return API.get("tap", "/get-resource/" + topic_id + "/" + filetype);
  }

  //  API call to get resource download stats
  API_get_resource_downloads(resource_id) {
    return API.get("tap", "/get-resource-downloads/" + resource_id);
  }

  //  API call to post PDF completed stats
  API_post_resource_complete(body) {
    return API.post("tap", "/post-resource-complete/", { body });
  }

  API_get_comments(blockid) {
    return API.get("tap-tapcircle", "/get-blockcomments/" + blockid);
  }

  //  API call to post PDF completed stats
  API_put_comment(body) {
    return API.put("tap-tapcircle", "/put-comment/", { body });
  }

  API_put_commentlike(body) {
    return API.put("tap-tapcircle", "/put-commentlike/", { body });
  }

  API_get_journalcomments(blockid) {
    return API.get(
      "tap-tapcircle",
      "/get-blockjournalcomments/" +
        blockid +
        "/" +
        localStorage.getItem("cognitoid")
    );
  }

  API_put_journalcomment(body) {
    return API.put("tap-tapcircle", "/put-journalcomment/", { body });
  }

  //  returns updated size of PDF viewer when window size changes
  /* resizeresource = () => this.setState({
        resource_width: document.getElementById('PDF_viewer').offsetWidth
    });*/

  resizeresource() {
    //console.log(document.getElementById('PDF_viewer').offsetWidth)
  }

  //  get resource details when PDF button is clicked
  async onClick_btn_resource_pdf(topic_id, filetype, resourcetype) {
    if (this.refs.pdf_display) {
      this.refs.pdf_display.scrollIntoView();
    }

    this.setState({
      pdf_isLoaded: false,
      pdf_isLoading: true,
    });

    try {
      const result = await this.API_get_resource(topic_id, filetype);
      this.setState({
        resource: result[0],
        resource_downloads: result[0]["downloads"],
        pdf_file: result[0]["filename"],
        pdf_page: 1,
        pdf_isLoaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onClick_btn_resource_mp4(topic_id, filetype) {
    if (this.refs.pdf_display) {
      this.refs.pdf_display.scrollIntoView();
    }

    this.setState({
      mp4_isLoaded: false,
      mp4_isLoading: true,
    });

    try {
      const result = await this.API_get_resource(topic_id, filetype);
      this.setState({
        resource: result[0],
        resource_downloads: result[0]["downloads"],
        mp4_file: result[0]["filename"],
      });
    } catch (e) {
      console.log(e);
    }
  }

  //  PDF successfully loaded handler
  PDF_onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      pdf_isLoaded: true,
      pdf_isLoading: false,
      pdf_pages: numPages,
      resource_width: document.getElementById("PDF_viewer").offsetWidth,
    });
    if (numPages === 1) {
      this.resourcecomplete();
    }
  };

  async resourcecomplete() {
    if (
      this.state.resource.resourcetype !== "CS" &&
      this.state.resource.resourcetype !== "AS"
    ) {
      let resourcecomplete = {};
      resourcecomplete.resource_id = this.state.resource.id;
      resourcecomplete.cognitousername = localStorage.getItem("cognitoid");
      await this.API_post_resource_complete(resourcecomplete);
      const topics = await this.API_get_topics(this.props.match.params.blockid);
      this.setState({ topics });
    }
  }

  //  display previous PDF page
  onCLick_previousPDFpage() {
    if (this.state.pdf_page - 1 > 0) {
      this.setState((state) => ({ pdf_page: state.pdf_page - 1 }));
    }
  }

  //  display next PDF page
  async onCLick_nextPDFpage() {
    if (this.state.pdf_page < this.state.pdf_pages) {
      document.getElementById("PDF_viewer").style.height =
        (document.getElementById("PDF_viewer").offsetWidth / 16) * 9 + "px";
      this.setState((state) => ({ pdf_page: state.pdf_page + 1 }));
    }
    if (this.state.pdf_page + 1 === this.state.pdf_pages) {
      this.resourcecomplete();
    }
  }

  onCLick_refreshPDF() {
    this.setState((state) => ({ pdf_page: 1 }));
  }

  //  update resource download stats when new download requested
  async onClick_btn_download(resource_id, download_id) {
    let resourcecomplete = {};
    resourcecomplete.resource_id = resource_id;
    resourcecomplete.cognitousername = localStorage.getItem("cognitoid");
    try {
      await this.API_post_resource_complete(resourcecomplete);
      await this.API_get_resource_downloads(download_id);
      const topics = await this.API_get_topics(this.props.match.params.blockid);
      this.setState({ topics });
    } catch (e) {
      console.log(e);
    }
  }

  onClick_page_header_breadcrumb_module(module_id) {
    this.props.history.push("/module/" + module_id);
  }

  addDefaultSrc(ev) {
    ev.target.src =
      "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg";
  }

  onClick_CS() {
    this.setState({ mdl_CS_open: true });
  }

  onClick_AS() {
    this.setState({ mdl_AS_open: true });
  }

  async tab_tapcircle_onClick() {
    this.setState({ displayMyJournal: false });
    this.setState({ displayTapcircle: true });

    try {
      const comments = await this.API_get_comments(
        this.props.match.params.blockid
      );
      this.setState({ comments });
    } catch (e) {
      console.log(e);
    }
  }

  async tab_myjournal_onClick() {
    this.setState({ displayMyJournal: true });
    this.setState({ displayTapcircle: false });

    try {
      const journalcomments = await this.API_get_journalcomments(
        this.props.match.params.blockid
      );
      this.setState({ journalcomments });
    } catch (e) {
      console.log(e);
    }
  }

  onChange_tapcircle = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit_tapcircle = async (event) => {
    event.preventDefault();
    this.setState({ tapcircle_submitting: true });
    let comment = {};
    comment.learningpath_id = 0;
    comment.module_id = 0;
    comment.block_id = this.props.match.params.blockid;
    comment.cognitousername = localStorage.getItem("cognitoid");
    comment.parentcomment_id = 0;
    comment.comment = this.state.comment;
    await this.API_put_comment(comment);
    this.setState({ tapcircle_submitting: false });
    this.setState({ comment: "" });

    try {
      const comments = await this.API_get_comments(
        this.props.match.params.blockid
      );
      this.setState({ comments });
    } catch (e) {
      console.log(e);
    }
  };

  onChange_tapcirclereply = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit_tapcirclereply = async (event) => {
    event.preventDefault();
    this.setState({
      ["tapcircle_submittingreply" + event.target.parentcomment_id.value]: true,
    });
    let comment = {};
    comment.learningpath_id = 0;
    comment.module_id = 0;
    comment.block_id = this.props.match.params.blockid;
    comment.cognitousername = localStorage.getItem("cognitoid");
    comment.parentcomment_id = event.target.parentcomment_id.value;
    comment.comment = this.state[
      "comment" + event.target.parentcomment_id.value
    ];
    this.setState({ ["comment" + event.target.parentcomment_id.value]: "" });
    await this.API_put_comment(comment);
    this.setState({
      ["tapcircle_submittingreply" + comment.parentcomment_id]: false,
    });

    try {
      const comments = await this.API_get_comments(
        this.props.match.params.blockid
      );
      this.setState({ comments });
    } catch (e) {
      console.log(e);
    }
  };

  async tapcircle_like_onClick(comment_id) {
    document.getElementById("commentlike_icon" + comment_id).src =
      "/icons/heart 20px (eb6060).svg";
    let commentlike = {};
    commentlike.comment_id = comment_id;
    commentlike.cognitousername = localStorage.getItem("cognitoid");
    await this.API_put_commentlike(commentlike);

    try {
      const comments = await this.API_get_comments(
        this.props.match.params.blockid
      );
      this.setState({ comments });
    } catch (e) {
      console.log(e);
    }
  }

  onChange_journal = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit_journal = async (event) => {
    event.preventDefault();
    this.setState({ journal_submitting: true });
    let journalcomment = {};
    journalcomment.learningpath_id = 0;
    journalcomment.module_id = 0;
    journalcomment.block_id = this.props.match.params.blockid;
    journalcomment.cognitousername = localStorage.getItem("cognitoid");
    journalcomment.comment = this.state.journalcomment;
    await this.API_put_journalcomment(journalcomment);
    this.setState({ journal_submitting: false });
    this.setState({ journalcomment: "" });

    try {
      const journalcomments = await this.API_get_journalcomments(
        this.props.match.params.blockid
      );
      this.setState({ journalcomments });
    } catch (e) {
      console.log(e);
    }
  };

  //  render page --------------------------------------------------------------------------------------------------------

  render() {
    const window = new JSDOM("").window;
    const DOMPurify = createDOMPurify(window);

    return (
      <div className="containerwrapper">
        <Helmet>
          <title>{`Tap Advance | ${this.state.block.block_name}`}</title>
        </Helmet>

        <div className="maincontentblock mcb_page_breadcrumb">
          <div className="page_header_breadcrumb">
            <div
              className="page_header_breadcrumb_module"
              onClick={() =>
                this.onClick_page_header_breadcrumb_module(
                  this.state.block.module_id
                )
              }>
              <div className="page_header_breadcrumb_block_img">
                <img
                  src="/icons/module 120px (d4d1d8).svg"
                  alt="block icon"></img>
              </div>
              {this.state.block.module_name} /
            </div>
            <div className="page_header_breadcrumb_block">
              <div className="page_header_breadcrumb_block_img">
                <img
                  src="/icons/block 120px (d4d1d8).svg"
                  alt="block icon"></img>
              </div>
              {this.state.block.block_name}
            </div>
          </div>
        </div>
        <div className="maincontentblock mcb_page_header">
          <div className="page_header_icon">
            <img src="/icons/block 120px (2ea3a6).svg" alt="block icon"></img>
          </div>
          <h1 className="page_header_title">{this.state.block.block_name}</h1>
        </div>

        <div className="maincontentblock mcb_content">
          <div className="page_header_img">
            <img
              src={`https://tap-app-resources.s3.amazonaws.com/public/jpg/block_${this.props.match.params.blockid}.jpg`}
              alt={`primary header image for ${this.state.block.block_name}`}
              onError={this.addDefaultSrc}></img>
          </div>
          {
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.state.block.content),
              }}
            />
          }
        </div>

        {localStorage.getItem("authenticated") !== "true" && (
          <div className="maincontentblock mcb_signup">
            <div className="signup_wrapper">
              <div className="signup_1">
                <img src="/icons/person3.svg" alt="person 3"></img>
              </div>
              <div className="signup_2">
                Sign up and explore all our content!
              </div>
              <div className="signup_3">
                <a href="/signup">
                  <div className="signup_3_button_wrapper">
                    <Button
                      primary
                      className="btn_primary_eb6060_ffffff home_signup_block_4_cta_button">
                      SIGN UP
                    </Button>
                  </div>
                </a>
                <div>
                  Already a member? <a href="/signin">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        )}

        {localStorage.getItem("authenticated") === "true" ||
        (localStorage.getItem("authenticated") !== "true" &&
          this.state.block.viewstatus === "FREE") ? (
          <div className="maincontentblock mcb_topics" ref="pdf_display">
            <div
              className={
                "resource_viewer_wrapper " +
                (this.state.resource.filetype === "mp4"
                  ? "resource_viewer_wrapper_mp4"
                  : "")
              }>
              <div className="resource_viewer_header">
                <div className="resource_viewer_title">
                  {this.state.resource.topic}
                </div>
                <div className="resource_viewer_stats_wrapper">
                  <div className="resource_viewer_stats">
                    {this.state.resource.views} views{" "}
                  </div>
                  {(this.state.resource.resourcetype === "CS" ||
                    this.state.resource.resourcetype === "AS") && (
                    <div className="resource_viewer_stats2">
                      {" "}
                      {this.state.resource.downloads} downloads
                    </div>
                  )}
                </div>
              </div>

              {this.state.resource.filetype === "pdf" && (
                <div className="pdf_viewer" id="PDF_viewer">
                  {this.state.pdf_file ? (
                    <div>
                      <Document
                        file={
                          "https://tap-app-resources.s3.amazonaws.com/public/pdf/" +
                          this.state.pdf_file
                        }
                        onLoadSuccess={this.PDF_onDocumentLoadSuccess}>
                        <Page
                          pageNumber={this.state.pdf_page}
                          min-width="0"
                          width={this.state.resource_width}
                        />
                      </Document>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}

              {this.state.resource.filetype === "mp4" && (
                <div className="mp4_viewer" id="mp4_viewer">
                  <video
                    width={this.state.resource_width}
                    controls
                    controlsList="nodownload"
                    onEnded={() => this.resourcecomplete()}>
                    <source
                      src={
                        "https://tap-app-resources.s3.amazonaws.com/public/mp4/" +
                        this.state.resource.filename
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}

              {this.state.resource.filetype === "pdf" &&
                this.state.pdf_isLoaded && (
                  <div className="resource_viewer_footer">
                    <div className="resource_viewer_footer_download"></div>
                    <div className="resource_viewer_footer_navigation">
                      <div className="pdf_viewer_controls_btns">
                        <div
                          className={
                            "pdf_viewer_controls_btn " +
                            (this.state.pdf_page > 1
                              ? "page_active"
                              : "page_inactive")
                          }
                          onClick={() => this.onCLick_previousPDFpage()}>
                          PREVIOUS
                        </div>
                        <div
                          className={
                            "pdf_viewer_controls_btn " +
                            (this.state.pdf_page === this.state.pdf_pages
                              ? "page_inactive"
                              : "page_active")
                          }
                          onClick={() => this.onCLick_nextPDFpage()}>
                          NEXT
                        </div>
                      </div>
                    </div>
                    <div className="resource_viewer_footer_pages">
                      <div
                        className="resource_viewer_footer_pages_icon"
                        onClick={() => this.onCLick_refreshPDF()}>
                        <img
                          src="/icons/refresh 20px (bcb6c3).svg"
                          alt="block icon"></img>
                      </div>
                      Page {this.state.pdf_page} of {this.state.pdf_pages}
                    </div>
                  </div>
                )}
            </div>

            <div className="topics_wrapper">
              {this.state.topics.map((item, i) => (
                <div
                  className={
                    "topic" +
                    (item.resourcetype === "CS" || item.resourcetype === "AS"
                      ? " topic_CSAS"
                      : "")
                  }
                  key={i}>
                  {item.resourcetype !== "CS" && item.resourcetype !== "AS" ? (
                    <div className="topic_description_wrapper">
                      <div className="topic_description_icon">
                        <img
                          src="/icons/topic 120px (cfaea9).svg"
                          alt="block icon"></img>
                      </div>
                      <div className="topic_description">
                        <h2 className="topic_description_title">
                          {item.topic}
                        </h2>
                        <div className="topic_description_views">
                          {item.views === 0
                            ? "Be the first to view this!"
                            : item.views +
                              (item.views === 1 ? " view" : " views")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="topic_description_wrapper">
                      {item.resourcetype === "CS" && (
                        <div className="topic_description_icon">
                          <img
                            src="/icons/cheatsheet.svg"
                            alt="block icon"></img>
                        </div>
                      )}
                      {item.resourcetype === "AS" && (
                        <div className="topic_description_icon">
                          <img
                            src="/icons/actionsheet.svg"
                            alt="block icon"></img>
                        </div>
                      )}
                      <div className="topic_description">
                        <h2 className="topic_description_title">
                          {item.topic}
                        </h2>
                        <div className="topic_description_views">
                          <div className="topic_description_views_CSAS">
                            {item.resourcetype === "CS" && "Cheat Sheet"}
                            {item.resourcetype === "AS" && "Action Sheet"}
                          </div>
                          {item.views === 0
                            ? " | Be the first to view this!"
                            : " | " +
                              item.views +
                              (item.views === 1 ? " view" : " views")}
                        </div>
                      </div>
                    </div>
                  )}
                  {item.resourcetype !== "CS" && item.resourcetype !== "AS" ? (
                    <div className="topic_resources">
                      <div className="topic_resources_text">
                        Select a format
                      </div>
                      <div
                        className={
                          "topic_resource_buttons " +
                          ((item.resourcetype === "CS" ||
                            item.resourcetype === "AS") &&
                            "topic_resource_buttons_cs")
                        }>
                        <div
                          className={
                            "block_topic_right_resources_btn " +
                            (item.PDF === 1
                              ? "resource_active "
                              : "resource_inactive ") +
                            (item.PDFcomplete > 0
                              ? "resource_complete"
                              : item.newresource === "YES" && "resource_new ")
                          }
                          onClick={() =>
                            this.onClick_btn_resource_pdf(
                              item.topic_id,
                              "pdf",
                              item.resourcetype
                            )
                          }>
                          <div className="block_topic_right_resources_btn_icon">
                            <img
                              src={
                                item.PDFcomplete > 0
                                  ? "/icons/checkmark 20px (ffffff).svg"
                                  : item.newresource === "YES"
                                  ? "/icons/new 20px (ffffff).svg"
                                  : "/icons/topic 120px (ffffff).svg"
                              }
                              alt="block icon"></img>
                          </div>
                          PDF
                        </div>

                        {item.resourcetype !== "CS" &&
                          item.resourcetype !== "AS" && (
                            <div
                              className={
                                "block_topic_right_resources_btn " +
                                (item.VIDEO === 1
                                  ? " resource_active"
                                  : " resource_inactive") +
                                (item.VIDEOcomplete > 0
                                  ? " resource_complete"
                                  : "")
                              }
                              onClick={() =>
                                this.onClick_btn_resource_mp4(
                                  item.topic_id,
                                  "mp4"
                                )
                              }>
                              <div className="block_topic_right_resources_btn_icon">
                                <img
                                  src={
                                    item.VIDEOcomplete > 0
                                      ? "/icons/checkmark 20px (ffffff).svg"
                                      : item.newresource === "YES"
                                      ? "/icons/new 20px (ffffff).svg"
                                      : "/icons/topic 120px (ffffff).svg"
                                  }
                                  alt="block icon"></img>
                              </div>
                              VIDEO
                            </div>
                          )}
                        {item.resourcetype !== "CS" &&
                          item.resourcetype !== "AS" && (
                            <div
                              className={
                                "block_topic_right_resources_btn " +
                                (item.AUDIO === 1
                                  ? "resource_active"
                                  : "resource_inactive")
                              }
                              onClick={() =>
                                this.onClick_resource(item.topic_id, "mp3")
                              }>
                              <div className="block_topic_right_resources_btn_icon">
                                <img
                                  src="/icons/topic 120px (ffffff).svg"
                                  alt="block icon"></img>
                              </div>
                              AUDIO
                            </div>
                          )}
                        {item.resourcetype === "CS" ? (
                          <div
                            className="topic_resources_cs"
                            onClick={() => this.onClick_CS()}>
                            CHEAT SHEET
                          </div>
                        ) : (
                          item.resourcetype === "AS" && (
                            <div
                              className="topic_resources_cs"
                              onClick={() => this.onClick_AS()}>
                              ACTION SHEET
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="topic_resources_CSAS">
                      <div
                        className={
                          "block_topic_right_resources_btn " +
                          (item.PDF === 1
                            ? "resource_active "
                            : "resource_inactive ") +
                          (item.PDFcomplete > 0
                            ? "resource_complete"
                            : (item.newresource === "YES" ||
                                item.newdownload === "YES") &&
                              "resource_new ")
                        }
                        onClick={() =>
                          this.onClick_btn_resource_pdf(
                            item.topic_id,
                            "pdf",
                            item.resourcetype
                          )
                        }>
                        <div className="block_topic_right_resources_btn_icon">
                          <img
                            src={
                              item.PDFcomplete > 0
                                ? "/icons/checkmark 20px (ffffff).svg"
                                : item.newresource === "YES"
                                ? "/icons/new 20px (ffffff).svg"
                                : "/icons/topic 120px (ffffff).svg"
                            }
                            alt="block icon"></img>
                        </div>
                        Preview
                      </div>
                      <a
                        href={
                          "https://tap-app-resources.s3.amazonaws.com/public/pdf/" +
                          item.PDFdownload
                        }
                        rel="noopener noreferrer"
                        download>
                        <div
                          className={
                            "block_topic_right_resources_btn " +
                            (item.PDF === 1
                              ? "resource_active "
                              : "resource_inactive ") +
                            (item.PDFcomplete > 0
                              ? "resource_complete"
                              : (item.newresource === "YES" ||
                                  item.newdownload === "YES") &&
                                "resource_new ")
                          }
                          onClick={() =>
                            this.onClick_btn_download(
                              item.PDFid,
                              item.PDFdownloadid
                            )
                          }>
                          <div className="block_topic_right_resources_btn_icon">
                            <img
                              src={
                                item.PDFcomplete > 0
                                  ? "/icons/checkmark 20px (ffffff).svg"
                                  : item.newresource === "YES"
                                  ? "/icons/new 20px (ffffff).svg"
                                  : "/icons/download 20px (ffffff).svg"
                              }
                              alt="block icon"></img>
                          </div>
                          Download
                        </div>
                      </a>
                      {item.resourcetype === "CS" ? (
                        <div
                          className="topic_resources_cs"
                          onClick={() => this.onClick_CS()}>
                          ?
                        </div>
                      ) : (
                        item.resourcetype === "AS" && (
                          <div
                            className="topic_resources_cs"
                            onClick={() => this.onClick_AS()}>
                            ?
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="topic_legend_wrapper">
              <div className="topic_legend">
                <div className="topic_legend_icon">
                  <img
                    src="/icons/checkmark 20px (c4c1c8).svg"
                    alt="block icon"></img>
                </div>
                previously viewed
              </div>
              <div className="topic_legend">
                <div className="topic_legend_icon">
                  <img
                    src="/icons/new 20px (c4c1c8).svg"
                    alt="block icon"></img>
                </div>
                new content
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {localStorage.getItem("authenticated") === "true" && (
          <div className="maincontentblock mcb_tapcircle">
            <div className="tab_wrapper">
              {/*<div className="tab_item" onClick={() => this.tab_tapcircle_onClick()}>
                                <div className={"tab_item_text " + (this.state.displayTapcircle ? "tapcircle_tabactive" : "")}>Tap Circle&trade; Forum</div>
                            </div>*/}
              {localStorage.getItem("authenticated") === "true" && (
                <div
                  className="tab_item"
                  onClick={() => this.tab_myjournal_onClick()}>
                  <div
                    className={
                      "tab_item_text " +
                      (this.state.displayMyJournal ? "tapcircle_tabactive" : "")
                    }>
                    My Notes
                  </div>
                </div>
              )}
            </div>

            {this.state.displayTapcircle && (
              <div>
                <div className="tapcircle_title">
                  Join the conversation with other TAP Advance members.
                </div>

                {localStorage.getItem("authenticated") !== "true" && (
                  <div className="tapcircle_signup">
                    <a href="/signup">
                      <div className="tapcircle_signup_cta_button_wrapper">
                        <Button
                          primary
                          className="btn_primary_ffffff_eb6060 tapcircle_signup_cta_button">
                          SIGN UP TO JOIN THE CONVERSATION
                        </Button>
                      </div>
                    </a>
                    <div>
                      Already a member? <a href="/signin">Sign In</a>
                    </div>
                  </div>
                )}

                <div className="tapcircle_header">
                  <div className="tapcircle_header_stats">
                    <div className="tapcircle_header_stats_img">
                      <img
                        src="/icons/comment 20px (bcb6c3).svg"
                        alt="comment icon"></img>
                    </div>
                    <div className="tapcircle_header_stats_text">
                      {this.state.comments.length} Comments
                    </div>
                  </div>
                  {localStorage.getItem("authenticated") === "true" && (
                    <div className="tapcircle_header_comment">
                      <Form onSubmit={this.onSubmit_tapcircle}>
                        <Form.TextArea
                          id="tapcircle_comment"
                          name="comment"
                          rows={4}
                          value={this.state.comment}
                          onChange={this.onChange_tapcircle}
                          control={TextArea}
                          placeholder="Join the conversation..."
                        />
                        {!this.state.tapcircle_submitting ? (
                          <Button
                            primary
                            id="btn_tapcircle_submit"
                            type="submit">
                            Submit
                          </Button>
                        ) : (
                          <Button loading id="btn_tapcircle_submit">
                            Submit
                          </Button>
                        )}
                      </Form>
                    </div>
                  )}
                </div>

                <div className="tapcircle_comments">
                  {this.state.comments.map((item, i, elements) => (
                    <div key={i}>
                      <div
                        className={
                          "tapcircle_comments_item " +
                          (item.parentcomment_id === 0
                            ? "parentcomment "
                            : "replycomment ")
                        }>
                        <div className="tapcircle_comments_item_member">
                          {item.membername}
                        </div>
                        <div className="tapcircle_comments_item_comment">
                          {item.comment}
                        </div>
                        <div className="tapcircle_comments_item_likes">
                          <div
                            className="tapcircle_comments_item_likes_icon"
                            onClick={() =>
                              this.tapcircle_like_onClick(item.commentlike_id)
                            }>
                            <img
                              id={"commentlike_icon" + item.commentlike_id}
                              src={
                                item.liked > 0
                                  ? "/icons/heart 20px (eb6060).svg"
                                  : "/icons/heart 20px (bcb6c3).svg"
                              }
                              alt="heart icon"></img>
                          </div>
                          <div className="tapcircle_comments_item_likes_count">
                            | {item.likes}
                          </div>
                        </div>
                      </div>

                      {((elements[i + 1] &&
                        elements[i + 1]["parentcomment_id"] === 0) ||
                        !elements[i + 1]) && (
                        <div className="tapcircle_comments_item_replyform">
                          <Form
                            onSubmit={this.onSubmit_tapcirclereply}
                            className="tapcircle_comments_item_replyform_form">
                            <Form.TextArea
                              id="tapcircle_reply"
                              name={"comment" + item.id}
                              rows={2}
                              value={this.state["comment" + item.id]}
                              onChange={this.onChange_tapcirclereply}
                              control={TextArea}
                              placeholder="Write a response..."
                            />
                            <input
                              type="hidden"
                              id="parentcomment_id"
                              name="parentcomment_id"
                              value={item.id}></input>
                            {!this.state[
                              "tapcircle_submittingreply" + item.id
                            ] ? (
                              <Button
                                primary
                                id="btn_tapcircle_submitreply"
                                type="submit">
                                Submit
                              </Button>
                            ) : (
                              <Button loading id="btn_tapcircle_submitreply">
                                Submit
                              </Button>
                            )}
                          </Form>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {this.state.displayMyJournal && (
              <div>
                <div className="tapcircle_title">
                  Record a personal note in your private online notebook.
                </div>

                <div className="tapcircle_header">
                  <div className="tapcircle_header_stats">
                    <div className="tapcircle_header_stats_img">
                      <img
                        src="/icons/comment 20px (bcb6c3).svg"
                        alt="comment icon"></img>
                    </div>
                    <div className="tapcircle_header_stats_text">
                      {this.state.journalcomments.length} Notes
                    </div>
                  </div>
                  <div className="tapcircle_header_comment">
                    <Form onSubmit={this.onSubmit_journal}>
                      <Form.TextArea
                        id="tapcircle_journalcomment"
                        name="journalcomment"
                        rows={4}
                        value={this.state.journalcomment}
                        onChange={this.onChange_journal}
                        control={TextArea}
                        placeholder="Record your personal thoughts..."
                      />
                      {!this.state.journal_submitting ? (
                        <Button primary id="btn_journal_submit" type="submit">
                          Submit
                        </Button>
                      ) : (
                        <Button loading id="btn_journal_submit">
                          Submit
                        </Button>
                      )}
                    </Form>
                  </div>
                </div>

                <div className="tapcircle_comments">
                  {this.state.journalcomments.map((item, i, elements) => (
                    <div key={i}>
                      <div className="tapcircle_comments_item journalcomment">
                        <div className="tapcircle_comments_item_member">
                          {item.lastmodified}
                        </div>
                        <div className="tapcircle_comments_item_comment">
                          {item.comment}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/**    MODAL: CS DESCRIPTION ************************************************************************/}

        <Modal
          id="mdl_CS"
          dimmer={"inverted"}
          open={this.state.mdl_CS_open}
          onClose={() => {
            this.setState({ mdl_CS_open: false });
          }}>
          <div className="mdl_title">What is a cheat sheet?</div>
          <div className="mdl_icon">
            <img src="/icons/cheatsheet.svg" alt="block icon"></img>
          </div>
          <div className="mdl_description">
            A Cheat Sheet is the key points of a specific topic “at a glance”.
            It helps us focus on the important things we need to remember.
          </div>
        </Modal>

        {/**    END MODAL: CS DESCRIPTION ********************************************************************/}

        {/**    MODAL: AS DESCRIPTION ************************************************************************/}

        <Modal
          id="mdl_AS"
          dimmer={"inverted"}
          open={this.state.mdl_AS_open}
          onClose={() => {
            this.setState({ mdl_AS_open: false });
          }}>
          <div className="mdl_title">What is an Action Sheet?</div>
          <div className="mdl_icon">
            <img src="/icons/actionsheet.svg" alt="block icon"></img>
          </div>
          <div className="mdl_description">
            Action Sheets are designed to get you to work! These worksheets help
            you discover things about yourself & your journey, find personalized
            solutions and help you hone specific skills.
          </div>
        </Modal>

        {/**    END MODAL: CS DESCRIPTION ********************************************************************/}
      </div>
    );
  }
}
