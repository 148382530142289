import React from "react";
import { Component } from "react";
import ReactGA from 'react-ga';
import "./tools.css";
import {Helmet} from "react-helmet";

export default class tools extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        ReactGA.initialize('UA-160033896-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

    }


//  render page --------------------------------------------------------------------------------------------------------



    render() {
        return (
            <div className="containerwrapper">

                <Helmet>
                    <title>{`Tap Advance | Tools`}</title>
                </Helmet>

                <div className="maincontentblock mcb_home_features_intro">
                    <p>At Tap, we aren't just 'dropping' random knowledge and hoping it sticks. We ‘ve created a community of learning, tools and accountability to give you the best chance at success. Customize your own journey and immediately experience the life changing benefits. </p>
                </div>
                <div className="maincontentblock mcb_home_features">
                    <div className="home_features_wrapper">
                        <a href={localStorage.getItem('authenticated') === 'true' ? "block/56" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Chart Your Journey </div>
                                <div className="home_features_item_icon"><img src="/icons/Chart Your Journey Icon Journal-01.jpg" alt="chart your journey icon"></img></div>
                                <div className="home_features_item_text">
                                    Your <span>My Journal</span> tool is a powerful place where you can keep those brilliant ideas and record your ‘ah ha’ moments. Be intentional about your professional growth and chart your journey.
                                </div>
                            </div>
                        </a>
                        <a href={localStorage.getItem('authenticated') === 'true' ? "/block/57" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Discover Your Priorities </div>
                                <div className="home_features_item_icon"><img src="/icons/Discover Your Priorities Icon To-Do-List-01.jpg" alt="daily activity plan icon"></img></div>
                                <div className="home_features_item_text">
                                    Your <span>My Priorities</span> tool will help you discover who you are and what is truly important. Effectively block your time and track your daily activities, creating moments that matter to you.
                                </div>
                            </div>
                        </a>
                        <a href={localStorage.getItem('authenticated') === 'true' ? "/block/58" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Reach Your Goals </div>
                                <div className="home_features_item_icon"><img src="/icons/Reach Your Goals Icon Arrow-Target.jpg" alt="track success icon"></img></div>
                                <div className="home_features_item_text">
                                    The <span>My Values & Goals</span> tool will help you set goals that are right for you.  Finally know where you are going and how you are going to get there.
                                </div>
                            </div>
                        </a>
                        <a href={localStorage.getItem('authenticated') === 'true' ? "/block/59" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Explore Your Network </div>
                                <div className="home_features_item_icon"><img src="/icons/Explore Your Network Icon Network-01.jpg" alt="follow up list icon"></img></div>
                                <div className="home_features_item_text">
                                    With your <span>My Connections</span> tool you will be able to provide the best possible customer experience.  Maintain your networking lists, keep track of your connections and conversations, and set reminders for follow-up.
                                </div>
                            </div>
                        </a>
                        <a href={localStorage.getItem('authenticated') === 'true' ? "/block/60" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Get A Head Start </div>
                                <div className="home_features_item_icon"><img src="/icons/Get a Head Start Icon Starting-Line-01.jpg" alt="on track icon"></img></div>
                                <div className="home_features_item_text">
                                    Know exactly how to start your business with your <span>My Starting Line</span> tool. Our onboarding and getting started checklists will keep you and your team on track by doing the things that lay a strong foundation.
                                </div>
                            </div>
                        </a>
                        <a href={localStorage.getItem('authenticated') === 'true' ? "/block/61" : "/module/22"}>
                            <div className="home_features_item">
                                <div className="home_features_item_title">Celebrate Your Achievements </div>
                                <div className="home_features_item_icon"><img src="/icons/Celebrate Your Achievements  Icon Road-01.jpg" alt="accountability icon"></img></div>
                                <div className="home_features_item_text">
                                    Measure your progress and give yourself a high five as you get closer to your goals with your <span>My Milestones</span> tool.  Build in personal accountability and discover fresh motivation by recognizing your growth and celebrating your hard work.
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        );
    }
}