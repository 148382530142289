import React from "react";
import { Component } from "react";
import { API } from "aws-amplify";
import ReactGA from "react-ga";
import "./blogs.css";
import { Helmet } from "react-helmet";

export default class blogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didmount: false,
      blogs: [],
    };
  }

  //  populate page on fist load
  async componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (this.state.didmount === false) {
      this.populateblogs();
      this.state.didmount = true;
    }
  }

  //  populate page with blogs data
  async populateblogs() {
    try {
      const blogs = await this.API_get_blogs();
      this.setState({ blogs });
    } catch (e) {
      console.log(e);
    }
  }

  //  API call to get-blogs
  API_get_blogs() {
    return API.get("tap", "/get-blogs");
  }

  //  open blog page
  onClick_blog(id) {
    this.props.history.push("/blog/" + id);
  }

  addDefaultSrc(ev) {
    ev.target.src =
      "https://tap-app-resources.s3.amazonaws.com/public/jpg/default.jpg";
  }

  //  render page --------------------------------------------------------------------------------------------------------

  render() {
    return (
      <div className="containerwrapper">
        <Helmet>
          <title>Tap Advance | Blogs</title>
        </Helmet>

        <div className="maincontentblock mcb_blogs_header">
          <div className="blogs_title">Tap Talk Blogs</div>
        </div>

        <div className="maincontentblock mcb_blogs">
          <div className="blogs_wrapper-new">
            {this.state.blogs.map((item, i) => {
              return (
                <div
                  className="blog-new transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-md"
                  key={i}
                  onClick={() => this.onClick_blog(item.id)}>
                  {/* <span class="tooltiptext"> {item.blog_name}</span> */}

                  <div className="blog_img">
                    <img
                      src={`https://tap-app-resources.s3.amazonaws.com/public/jpg/blog_${item.id}.jpg`}
                      alt={`primary header for blog id ${item.id}`}
                      onError={this.addDefaultSrc}
                      alt={`primary header for blog id ${item.id}`}
                    />
                  </div>
                  <div className="blog-description-new">
                    <div
                      className="blog_description_title-new text-cutoff"
                      title={item.blog_name}
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        height: 25,
                      }}>
                      {item.blog_name}
                    </div>
                    <div className="blog_description_text">
                      {item.description_short}
                    </div>
                  </div>
                  <div className="blog_details">
                    <div className="blog_details_date">{item.lastmodified}</div>
                    <div className="blog_details_author">{item.author}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
