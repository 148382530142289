import React, { Component } from "react";
import { API } from "aws-amplify";
import "./landing_i-feel-stuck.css";
import { Button } from "semantic-ui-react";
import createDOMPurify from "dompurify";
import { JSDOM } from "jsdom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

export default class landing_i_feel_stuck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      landingpage: {},
      modules: [],
      contents: [],
      assets: [],
      learningpath: {},
    };
  }

  async componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    try {
      const landingpage = await this.API_get_landingpage();
      this.setState({ landingpage: landingpage[0] });
      const modules = await this.API_get_landingpagemodules();
      this.setState({ modules });
    } catch (e) {
      console.log(e);
    }

    try {
      const learningpath = await this.API_get_learningpath();
      this.setState({ learningpath: learningpath[0] });
    } catch (e) {
      console.log(e);
    }

    window.scrollTo(0, 0);
  }

  //  API call to get-events
  API_get_landingpage() {
    return API.get("tap", "/get-landingpage/1");
  }

  //  API call to get-events
  API_get_landingpagemodules() {
    return API.get("tap", "/get-landingpagemodules/1");
  }

  //  API call to get-learningpath
  API_get_learningpath() {
    return API.get("tap", "/get-learningpath/1");
  }

  module_onClick(module_id) {
    this.props.history.push("/module/" + module_id);
  }

  whatislearningpath_onClick() {
    this.props.history.push("/what-is-a-learning-path");
  }

  learningpath_onClick() {
    this.props.history.push("/learningpath/1");
  }

  render() {
    const window = new JSDOM("").window;
    const DOMPurify = createDOMPurify(window);

    return (
      <div className="containerwrapper">
        <Helmet>
          <title>{`Tap Advance | I Feel Stuck`}</title>
        </Helmet>

        <div className="maincontentblock mcb_landing_header">
          <div className="landing_title">I Feel Stuck</div>
          <div className="landing_image">
            <img src="/icons/i-feel-stuck3.png" alt="landing page header"></img>
          </div>
        </div>
        <div className="maincontentblock mcb_landing_subheader landing-subheader-margin">
          {
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.state.landingpage.description),
              }}
            />
          }
        </div>

        {!this.props.signedin && (
          <div className="maincontentblock mcb_landing_whatislearningpath">
            <div className="whatislearningpath_wrapper">
              <div className="whatislearningpath_icon">
                <img
                  src="/icons/LearningPath-Icon.svg"
                  alt="learning path icon"></img>
              </div>
              <div className="whatislearningpath_description">
                <div className="whatislearningpath_description_header">
                  What is a Learning Path
                </div>
                <div className="whatislearningpath_description_description">
                  TAP Advance offers many ways to learn including curated
                  Learning Paths and full skill building Modules. Rather than
                  mastering an entire subject (as you would in a module) a
                  learning path helps you solve a specific problem by looking at
                  it from many different angles. Each learning path has been
                  carefully selected from our content to answer specific
                  questions and address common problems experienced by people
                  just like you.
                </div>
                <div className="whatislearningpath_description_cta">
                  <Button
                    primary
                    onClick={() => this.whatislearningpath_onClick()}>
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.signedin && (
          <div className="maincontentblock mcb_landing_whatislearningpath">
            <div className="whatislearningpath_wrapper">
              <div className="whatislearningpath_icon">
                <img
                  src="/icons/LearningPath-Icon.svg"
                  alt="learning path icon"></img>
              </div>
              <div className="whatislearningpath_description">
                <div className="whatislearningpath_description_header">
                  {this.state.learningpath.learningpath_name}
                </div>
                <div className="whatislearningpath_description_description">
                  {this.state.learningpath.description_short}
                </div>
                <div className="whatislearningpath_description_cta">
                  <Button primary onClick={() => this.learningpath_onClick()}>
                    Take This Journey
                  </Button>
                </div>
              </div>
            </div>
            <div className="whatislearningpath_link">
              <div className="whatislearningpath_link_text">
                What is a Learning Path?
              </div>
              <div className="whatislearningpath_cta">
                <Button
                  primary
                  className="btn_primary_ffffff_5e637c"
                  onClick={() => this.whatislearningpath_onClick()}>
                  Read More
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="maincontentblock mcb_landing_video">
          <div className="landing_video_wrapper">
            <iframe
              src="https://www.youtube.com/embed/Bu1xdZe2v-g"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
          <div className="landing_video_description">
            <p>
              Feeling stuck is a common dilemma! It is a sign that you are
              trying to get somewhere but you haven’t been successful, yet. The
              big question for people dealing with this dilemma, is how do I get
              unstuck? Well, the good news is, you have aspirations! Although
              that’s important to establish, the answer isn’t always clear,
              because the problem isn’t simple.
            </p>
            <p>
              People feel stuck for many reasons, so the first thing you will
              need to understand is why you are feeling that way. Some of the
              common problems that cause people to feel stuck are: You don’t
              actually know what you are doing, You have no idea why you are
              struggling, You feel like you are running ragged but not getting
              anywhere, You are aware you are wasting time, but can’t help it
              because you feel unmotivated or even lazy. You have a lack of
              positivity and absence of direction that makes your situation feel
              hopeless. Does any of this sound familiar?​
            </p>
            <p>
              Living mindfully & intentionally are trendy terms these days but
              most people aren’t interested in doing the work that it requires.
              Why? Well because it’s work you don’t have to do UNLESS you are
              living with purpose. If you really want to live deliberately while
              growing and moving forward, let us take you on a journey to help
              you tap into your full potential.​
            </p>
          </div>
        </div>

        {localStorage.getItem("authenticated") !== "true" && (
          <div className="maincontentblock mcb_signup">
            <div className="signup_wrapper">
              <div className="signup_1">
                <img src="/icons/person3.svg" alt="person 3"></img>
              </div>
              <div className="signup_2">
                Sign up and explore all our content!
              </div>
              <div className="signup_3">
                <a href="/signup">
                  <div className="signup_3_button_wrapper">
                    <Button
                      primary
                      className="btn_primary_eb6060_ffffff home_signup_block_4_cta_button">
                      SIGN UP
                    </Button>
                  </div>
                </a>
                <div>
                  Already a member? <a href="/signin">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="maincontentblock mcb_modules">
          <div className="modules_header">Related Content</div>
          <div className="modules_wrapper">
            {this.state.modules.map((item, i) => {
              return (
                <div
                  className="module"
                  key={i}
                  onClick={() => this.module_onClick(item.id)}>
                  <div className="module_description_wrapper">
                    <div className="module_description_icon">
                      {" "}
                      <img
                        src="/icons/module 120px (eb6060).svg"
                        alt="block icon"></img>{" "}
                    </div>
                    <div className="module_description">
                      <div className="module_description_title">
                        {item.module_name}
                      </div>
                      <div className="module_counts_wrapper">
                        <div className="module_description_views">
                          {item.contentviews === 0
                            ? "Be the first to view this!"
                            : item.moduleviews +
                              (item.moduleviews === 1 ? " view" : " views")}
                        </div>
                        <div className="module_description_blocks">
                          {item.moduleblocks +
                            (item.moduleblocks === 1 ? " block" : " blocks")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
