import Routes from "./routes";
import React, { useEffect, useState } from "react";
import { Component } from "react";
import { Button, Form, Input, Transition } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import TAPfooter from "./containers/footer/footer";

import "semantic-ui-css/semantic.min.css";
import "./App.css";

class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      signingin: false,
      signedin: false,
      showsignin: false,
      hamburgermenu: false,
    };
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ signedin: authenticated });
  };

  zoomOutMobile = () => {
    const viewport = document.querySelector('meta[name="viewport"]');

    if (viewport) {
      viewport.content = "initial-scale=1";
      viewport.content = "width=device-width";
    }
  };

  //  initialize page
  async componentDidMount() {
    this.setState({
      email: "",
      password: "",
    });

    try {
      await Auth.currentSession();
      this.setState({
        signedin: true,
      });
    } catch (e) {
      if (e !== "No current user") {
        //alert(e);
      }
    }

    this.setState({
      signingin: false,
    });
  }

  //  display sign in form
  showSignin = () => {
    this.setState({
      showsignin: !this.state.showsignin,
    });
  };

  //  sign out user and return to home page
  async handleSignout() {
    this.setState({
      hamburgermenu: false,
    });
    await Auth.signOut();
    this.setState({
      signingin: false,
      signedin: false,
    });
    localStorage.setItem("authenticated", false);
    localStorage.setItem("firstname", "");
    localStorage.setItem("lastname", "");
    localStorage.setItem("cognitoid", "");
    localStorage.setItem("email", "");
    localStorage.setItem("hasCard", "");

    window.location.href = "/";
  }

  handleSignin() {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/signin/");
  }

  handleSignup() {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/signup/");
  }

  handleMember() {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/member/");
  }

  //  update state variables when form changes
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //  submit form
  handleSubmit = async (event) => {
    event.preventDefault();

    //  set submit button loader
    this.setState({
      signingin: true,
    });

    //  authenticate credentials with cognito and cancel submit button loader
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.setState({
        signingin: false,
        signedin: true,
      });
      this.showSignin();
      this.props.history.push("/member");
    } catch (e) {
      this.setState({
        signingin: false,
        signedin: false,
      });
      //alert(e.message);
    }
  };

  onClick_btn_whatistap = (event) => {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/what-is-tap-advance");
  };

  onClick_btn_library = (event) => {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/library");
  };

  onClick_btn_tools = (event) => {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/tools");
  };

  onClick_btn_mynotebook = (event) => {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/tools/mynotebook");
  };

  onClick_btn_blogs = (event) => {
    this.setState({
      hamburgermenu: false,
    });
    this.props.history.push("/blogs");
  };

  onClick_hamburgermenu(varState) {
    this.setState({
      hamburgermenu: varState,
    });
  }

  //  render page --------------------------------------------------------------------------------------------------------

  render() {
    // console.log(process.env);
    const childProps = {
      signedin: this.state.signedin,
      userHasAuthenticated: this.userHasAuthenticated,
    };
    let btn_signin;

    // this.zoomOutMobile();

    //  set submit button status
    if (this.state.signingin) {
      btn_signin = (
        <Button primary loading>
          Sign in
        </Button>
      );
    } else {
      btn_signin = (
        <Button primary type="submit">
          Sign in
        </Button>
      );
    }

    return (
      <div className="pagewrapper">
        <div
          className={
            "shadow-md " +
            (this.state.signedin ? "header" : "header-signed-out")
          }>
          <div className="logo">
            <Link to="/">
              <img
                src="/icons/logo-tapadvance-full-header.svg"
                alt="TAP logo"></img>
            </Link>
          </div>
          <div className="menu-wrapper">
            <div className="menu py-4 xl:px-8">
              <div
                className="menuitem"
                onClick={() => this.onClick_btn_whatistap()}>
                What Is TAP Advance?
              </div>
              <div
                className="menuitem"
                onClick={() => this.onClick_btn_library()}>
                Library
              </div>
              {/* <div
                className="menuitem"
                onClick={() => this.onClick_btn_tools()}>
                Tools
              </div> */}
              {localStorage.getItem("authenticated") === "true" && (
                <div
                  className="menuitem"
                  onClick={() => this.onClick_btn_mynotebook()}>
                  My Notebook
                </div>
              )}
              <div
                className="menuitem"
                onClick={() => this.onClick_btn_blogs()}>
                Blogs
              </div>
            </div>
          </div>

          {localStorage.getItem("authenticated") !== "true" && (
            <div className="member">
              <div id="member_signedout">
                <button
                  className="member_item text-black hover:bg-gray-200 bg-gray-100 py-4 px-8 rounded font-semibold mr-2"
                  onClick={() => this.handleSignin()}>
                  {" "}
                  Sign In
                </button>
                <button
                  className="member_item text-black hover:bg-breaker-bay-500 bg-breaker-bay-400 py-4 px-8 rounded font-semibold"
                  id="member_signedout_signup"
                  onClick={() => this.handleSignup()}>
                  Sign Up
                </button>
              </div>{" "}
            </div>
          )}
          {localStorage.getItem("authenticated") === "true" && (
            <div className="member">
              <div id="member_signedin" className="member_signedin_new">
                <div
                  className="member_item"
                  id="member_item_name"
                  onClick={() => this.handleMember()}>
                  {localStorage.getItem("firstname") +
                    " " +
                    localStorage.getItem("lastname")}
                </div>
                <div
                  className="member_item member_item_new"
                  id="member_item_signout"
                  onClick={() => this.handleSignout()}>
                  Sign Out
                </div>
              </div>
            </div>
          )}

          <div className="hamburgermenu">
            {!this.state.hamburgermenu ? (
              <div
                className="hamburgermenu_open"
                onClick={() => this.onClick_hamburgermenu(true)}>
                <img src="/icons/hamburgeropen.svg"></img>
              </div>
            ) : (
              <div
                className="hamburgermenu_open"
                onClick={() => this.onClick_hamburgermenu(false)}>
                <img src="/icons/hamburgerclose.svg"></img>
              </div>
            )}
          </div>
        </div>

        {this.state.hamburgermenu && (
          <div
            className="hamburgermenu-panel"
            // style={{
            //   width:
            //     window.location.pathname === "/what-is-tap-advance"
            //       ? "104vw"
            //       : "102vw",
            // }}
          >
            <div
              className="menuitem"
              onClick={() => this.onClick_btn_whatistap()}>
              What Is TAP Advance?
            </div>
            <div
              className="menuitem"
              onClick={() => this.onClick_btn_library()}>
              Library
            </div>
            <div className="menuitem" onClick={() => this.onClick_btn_tools()}>
              Tools
            </div>
            {localStorage.getItem("authenticated") === "true" && (
              <div
                className="menuitem"
                onClick={() => this.onClick_btn_mynotebook()}>
                My Notebook
              </div>
            )}
            <div className="menuitem" onClick={() => this.onClick_btn_blogs()}>
              Blogs
            </div>
            <div className="menuitem">
              {localStorage.getItem("authenticated") !== "true" && (
                <div id="member_signedout">
                  <div
                    className="member_item"
                    id="member_signedout_signup"
                    onClick={() => this.handleSignup()}>
                    Sign Up |{" "}
                  </div>
                  <div
                    className="member_item"
                    onClick={() => this.handleSignin()}>
                    {" "}
                    Sign In
                  </div>
                </div>
              )}
              {localStorage.getItem("authenticated") === "true" && (
                <div id="member_signedin">
                  <div
                    className="member_item"
                    id="member_item_name"
                    onClick={() => this.handleMember()}>
                    {localStorage.getItem("firstname") +
                      " " +
                      localStorage.getItem("lastname")}
                  </div>
                  <div
                    className="member_item"
                    id="member_item_signout"
                    onClick={() => this.handleSignout()}>
                    Sign Out
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Routes childProps={childProps} />

        <TAPfooter />
      </div>
    );
  }
}

export default withRouter(App);
