import React from "react";
import { Button, Input } from "semantic-ui-react";
import "./footer.css";
import { Link } from "react-router-dom";

const TAPfooter = (props) => {
  let yearNow = new Date().getFullYear();

  return (
    <div style={{ width: "100%" }}>
      <div className="updated_footer flex flex-col footer-blue-bg container-padding pt-5 md:px-20 ">
        <div className="footer_row_1 w-full">
          <img
            className="footer_logo_updated p-10"
            src={process.env.PUBLIC_URL + "/footer-logo-new.png"}
          />
        </div>
        <div className="footer_row_2 w-full flex flex-wrap">
          <div className="new-footer-link-1 w-full lg:w-2/6 flex flex-col justify-between    p-10">
            <div className="font-semibold text-xl text-white tracking-wide py-2">
              Navigate
            </div>
            <a className=" text-white py-4 hover:text-gray-500" href="/">
              {" "}
              Home
            </a>
            <a className="text-white  py-4 hover:text-gray-500" href="/library">
              {" "}
              Library
            </a>
            <a className="text-white  py-4 hover:text-gray-500" href="/tools">
              {" "}
              Tools
            </a>
          </div>
          <div className="new-footer-link-2  w-full lg:w-2/6 flex flex-col justify-between   p-10">
            <div className="font-semibold text-xl text-white tracking-wide py-2">
              Contact
            </div>
            <div className="font-semibold text-white py-2 ">
              {" "}
              Reach out:{" "}
              <a
                className=" text-white font-normal hover:text-gray-500"
                style={{ display: "block" }}
                href="mailto:admin@theaccountabilitypartners.net">
                admin@theaccountabilitypartners.net
              </a>
            </div>
            <div className="font-semibold text-white py-2">
              {" "}
              Technical Support:{" "}
              <a
                className=" text-white font-normal hover:text-gray-500"
                style={{ display: "block" }}
                href="mailto:support@theaccountabilitypartners.net">
                support@theaccountabilitypartners.net
              </a>
            </div>
          </div>
          <div className="new-footer-link-1 w-full lg:w-2/6 flex flex-col  p-10">
            <div className="font-semibold text-xl text-white tracking-wide py-2">
              Social Media
            </div>
            <div className="flex justify-between" style={{ maxWidth: 300 }}>
              <a
                href="https://www.facebook.com/TheAccountabilityPartners/"
                target="blank">
                <div className="tapfooter_upper_links_socials_items">
                  <div className="tapfooter_upper_links_socials_items_icon">
                    <img
                      src="/icons/social-facebook.svg"
                      alt="facebook icon"></img>
                  </div>
                </div>
              </a>
              <a href="https://twitter.com/TAP_Inc_" target="_blank">
                <div className="tapfooter_upper_links_socials_items">
                  <div className="tapfooter_upper_links_socials_items_icon">
                    <img
                      src="/icons/social-twitter.svg"
                      alt="twitter icon"></img>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/TheAccountabilityPartners/"
                target="blank">
                <div className="tapfooter_upper_links_socials_items">
                  <div className="tapfooter_upper_links_socials_items_icon">
                    <img
                      src="/icons/social-instagram.svg"
                      alt="instagram icon"></img>
                  </div>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/t-a-p-the-accountability-partners-inc/"
                target="blank">
                <div className="tapfooter_upper_links_socials_items">
                  <div className="tapfooter_upper_links_socials_items_icon">
                    <img
                      src="/icons/social-linkedin.svg"
                      alt="linkedin icon"></img>
                  </div>
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCJwbURDMYzo0f_WtBQd3plQ"
                target="blank">
                <div className="tapfooter_upper_links_socials_items">
                  <div className="tapfooter_upper_links_socials_items_icon">
                    <img
                      src="/icons/social-youtube.svg"
                      alt="youtube icon"></img>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_row_3 flex-wrap footer-dblue-bg flex container-padding px-5 py-10">
        <div className="footer-bottom-row1 flex flex-wrap w-full md:w-2/3 py-2 md:py-0">
          <div className="text-white items-center font-light tracking-wide w-full md:w-1/2 flex justify-center md:justify-start text-xs md:text-base">
            © 2021 T.A.P - The Accountability Partners Inc.
          </div>

          {localStorage.getItem("authenticated") !== "true" && (
            <div className="footerbtns w-full md:w-1/2 flex justify-center py-2 md:py-0 ">
              <a href="/signin">
                <button className="bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-medium py-4 px-8 rounded mr-2">
                  Sign In
                </button>
              </a>
              <a href="/signup">
                <button className="bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-medium py-4 px-8 rounded">
                  Sign Up
                </button>
              </a>
            </div>
          )}
        </div>

        <div className="flex items-center justify-center footer-bottom-row2 w-full md:w-1/3 flex-col md:flex-row md:divide-x divide-gray-400">
          <Link
            className="text-gray-400 hover:text-gray-500 px-2"
            to={{
              pathname:
                "https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+terms+and+conditions.pdf",
            }}
            target="_blank">
            Terms & Conditions
          </Link>
          <Link
            className="text-gray-400 hover:text-gray-500 px-2"
            to={{
              pathname:
                "https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+community+guidelines.pdf",
            }}
            target="_blank">
            Community Guidelines
          </Link>
          <Link
            className="text-gray-400 hover:text-gray-500 px-2"
            to={{
              pathname:
                "https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+privacy+policy.pdf",
            }}
            target="_blank">
            Privacy Policy
          </Link>
        </div>
      </div>
      {/* <div className="tapfooter">
        <div className="maincontentblock tapfooter_upper md:h-5/6 h-full">
          <div className="tapfooter_upper_logo">
            <img src="/icons/logo-wide-whiteteal.svg" alt="tap logo"></img>
          </div>

          <div className="tapfooter_upper_links">
            <div className="tapfooter_upper_links_navigate">
              <div className="tapfooter_upper_links_header ">Navigate</div>
              <a href="/">
                <div className="tapfooter_upper_links_item">Home</div>
              </a>
              <a href="/library">
                <div className="tapfooter_upper_links_item">Library</div>
              </a>
              <a href="/tools">
                <div className="tapfooter_upper_links_item">Tools</div>
              </a>
            </div>
            <div className="tapfooter_upper_links_contact">
              <div className="tapfooter_upper_links_header">Contact</div>
              <div className="tapfooter_upper_links_item">
                Reach out:
                <a href="mailto:admin@theaccountabilitypartners.net">
                  <div className="tapfooter_upper_links_details">
                    admin@theaccountabilitypartners.net
                  </div>
                </a>
              </div>

              <div className="tapfooter_upper_links_item technicalsupport">
                Technical Support
                <a href="mailto:support@theaccountabilitypartners.net">
                  <div className="tapfooter_upper_links_details">
                    support@theaccountabilitypartners.net
                  </div>
                </a>
              </div>
            </div>
            <div className="tapfooter_upper_links_socials">
              <div className="tapfooter_upper_links_header">Socials</div>
              <div className="tapfooter_upper_links_socials_items_wrapper">
                <a
                  href="https://www.facebook.com/TheAccountabilityPartners/"
                  target="blank">
                  <div className="tapfooter_upper_links_socials_items">
                    <div className="tapfooter_upper_links_socials_items_icon">
                      <img
                        src="/icons/social-facebook.svg"
                        alt="facebook icon"></img>
                    </div>
                  </div>
                </a>
                <a href="https://twitter.com/TAP_Inc_" target="_blank">
                  <div className="tapfooter_upper_links_socials_items">
                    <div className="tapfooter_upper_links_socials_items_icon">
                      <img
                        src="/icons/social-twitter.svg"
                        alt="twitter icon"></img>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/TheAccountabilityPartners/"
                  target="blank">
                  <div className="tapfooter_upper_links_socials_items">
                    <div className="tapfooter_upper_links_socials_items_icon">
                      <img
                        src="/icons/social-instagram.svg"
                        alt="instagram icon"></img>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/t-a-p-the-accountability-partners-inc/"
                  target="blank">
                  <div className="tapfooter_upper_links_socials_items">
                    <div className="tapfooter_upper_links_socials_items_icon">
                      <img
                        src="/icons/social-linkedin.svg"
                        alt="linkedin icon"></img>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCJwbURDMYzo0f_WtBQd3plQ"
                  target="blank">
                  <div className="tapfooter_upper_links_socials_items">
                    <div className="tapfooter_upper_links_socials_items_icon">
                      <img
                        src="/icons/social-youtube.svg"
                        alt="youtube icon"></img>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="maincontentblock tapfooter_lower md:h-1/6 h-full">
          <div className="tapfooter_lower_copyright">
            © {yearNow} T.A.P - The Accountability Partners Inc.
          </div>
          {!JSON.parse(localStorage.getItem("authenticated")) && (
            <div className="tapfooter_lower_signup">
              <a href="/signin">
                <button className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-medium py-4 px-8 rounded mr-2">
                  Sign In
                </button>
              </a>
              <a href="/signup">
                <button className="mt-4 bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-medium py-4 px-8 rounded">
                  Sign Up
                </button>
              </a>
            </div>
          )}
          <div className="tapfooter_lower_links">
            <a
              href="https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+terms+and+conditions.pdf"
              target="_blank">
              Terms & Conditions
            </a>
            <div className="tapfooter_lower_links_separator">|</div>
            <a
              href="https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+community+guidelines.pdf"
              target="_blank">
              Community Guidelines
            </a>
            <div className="tapfooter_lower_links_separator">|</div>
            <a
              href="https://tap-app-resources.s3.amazonaws.com/public/pdf/TAP+-+privacy+policy.pdf"
              target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default TAPfooter;
