import React, { Component } from "react";
import { Button, Form, Input, Message, Modal } from "semantic-ui-react";
import "./member.css";
import "./custom.css";

import { Auth, API } from "aws-amplify";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import UpdateCardModal from "../../modals/UpdateCardModal.js";
import CancelMembershipModal from "../../modals/CancelMembershipModal.js";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Swal from "sweetalert2";

export default class member extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDashboard: true,
      displayAccount: false,
      modulestats1: [],
      modulestats2: [],
      modulestats3: [],
      modulestats4: [],
      modulestats5: [],
      modulestats6: [],
      modulestats7: [],
      modulestats8: [],
      modulestats9: [],
      learningpaths: [],
      card: {},
      member: {},
      member_updating: false,
      member_updateready: false,
      password: "",
      password_updating: false,
      password_updateready: false,
      validatepassword_length: false,
      validatepassword_lowercase: false,
      validatepassword_uppercase: false,
      validatepassword_number: false,
      validatepassword_specialcharacter: false,
      password_updated: false,
      mdl_cancelmembership_open: false,
      mdl_updatecreditcard_open: false,
      mdl_updatecreditcard_updating: false,
      accountInfoTab: true,
      passwordTab: false,
      creditCardInfoTab: false,
      cancelMembershipTab: false,
    };
  }

  async componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (localStorage.getItem("authenticated") === "false") {
      window.location.href = "/signin";
    }
    this.populatemember();
  }

  tab_dashboard_onClick() {
    this.setState({ displayDashboard: true, displayAccount: false });
  }

  async populatemember() {
    try {
      const member = await this.API_get_member();
      // const paid = await this.API_get_member_payment_method();

      if (member.status !== "ACTIVE") {
        window.location.href = "/signin";
      } else {
        this.setState({ member: member }, () => {
          this.populatemodulestats1();
          this.populatemodulestats2();
          this.populatemodulestats3();
          this.populatemodulestats4();
          this.populatemodulestats5();
          this.populatemodulestats6();
          this.populatemodulestats7();
          this.populatemodulestats8();
          this.populatemodulestats9();
          this.populatelearningpathstats();
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: e.response.message,
        confirmButtonColor: "#35a3a5",
        confirmButtonText: "Ok",
      }).then(function () {
        window.location.href = "/signin";
      });
    }
  }

  async populatemodulestats1() {
    try {
      const modulestats1 = await this.API_get_modulestats1();
      this.setState({ modulestats1: modulestats1[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats2() {
    try {
      const modulestats2 = await this.API_get_modulestats2();
      this.setState({ modulestats2: modulestats2[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats3() {
    try {
      const modulestats3 = await this.API_get_modulestats3();
      this.setState({ modulestats3: modulestats3[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats4() {
    try {
      const modulestats4 = await this.API_get_modulestats4();
      this.setState({ modulestats4: modulestats4[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats5() {
    try {
      const modulestats5 = await this.API_get_modulestats5();
      this.setState({ modulestats5: modulestats5[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats6() {
    try {
      const modulestats6 = await this.API_get_modulestats6();
      this.setState({ modulestats6: modulestats6[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats7() {
    try {
      const modulestats7 = await this.API_get_modulestats7();
      this.setState({ modulestats7: modulestats7[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats8() {
    try {
      const modulestats8 = await this.API_get_modulestats8();
      this.setState({ modulestats8: modulestats8[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatemodulestats9() {
    try {
      const modulestats9 = await this.API_get_modulestats9();
      this.setState({ modulestats9: modulestats9[0] });
    } catch (e) {
      console.log(e);
    }
  }

  async populatelearningpathstats() {
    try {
      const learningpathstats = await this.API_get_learningpathstats();
      console.log(learningpathstats);
      this.setState({ learningpaths: learningpathstats });
    } catch (e) {
      console.log(e);
    }
  }

  API_get_member() {
    return API.get("tap", "/get-member/" + localStorage.getItem("cognitoid"));
  }

  API_put_member(body) {
    return API.put("tap", "/put-member/" + localStorage.getItem("cognitoid"), {
      body,
    });
  }

  API_put_password(body) {
    return API.put("tap", "/put-password", { body });
  }

  API_get_modulestats1() {
    return API.get(
      "tap",
      "/get-modulestats/3/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats2() {
    return API.get(
      "tap",
      "/get-modulestats/8/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats3() {
    return API.get(
      "tap",
      "/get-modulestats/4/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats4() {
    return API.get(
      "tap",
      "/get-modulestats/1/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats5() {
    return API.get(
      "tap",
      "/get-modulestats/23/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats6() {
    return API.get(
      "tap",
      "/get-modulestats/9/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats7() {
    return API.get(
      "tap",
      "/get-modulestats/5/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats8() {
    return API.get(
      "tap",
      "/get-modulestats/2/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_modulestats9() {
    return API.get(
      "tap",
      "/get-modulestats/7/" + localStorage.getItem("cognitoid")
    );
  }

  API_get_learningpathstats() {
    return API.get(
      "tap",
      "/get-learningpathstats/0/" + localStorage.getItem("cognitoid")
    );
  }

  tab_account_onClick() {
    this.setState({ displayDashboard: false, displayAccount: true });
  }

  onChange_input = (event) => {
    const { member } = { ...this.state };
    const currentState = member;
    currentState[event.target.name] = event.target.value;
    this.setState({ member: currentState });
  };

  onChange_password = (event) => {
    let password = event.target.value;
    this.setState({ password: event.target.value });

    if (password.length >= 8) {
      this.setState({ validatepassword_length: true });
    } else {
      this.setState({ validatepassword_length: false });
    }

    if (/[a-z]/.test(password)) {
      this.setState({ validatepassword_lowercase: true });
    } else {
      this.setState({ validatepassword_lowercase: false });
    }

    if (/[A-Z]/.test(password)) {
      this.setState({ validatepassword_uppercase: true });
    } else {
      this.setState({ validatepassword_uppercase: false });
    }

    if (/[0-9]/.test(password)) {
      this.setState({ validatepassword_number: true });
    } else {
      this.setState({ validatepassword_number: false });
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
      this.setState({ validatepassword_specialcharacter: true });
    } else {
      this.setState({ validatepassword_specialcharacter: false });
    }

    if (
      this.state.validatepassword_length &&
      this.state.validatepassword_lowercase &&
      this.state.validatepassword_uppercase &&
      this.state.validatepassword_number &&
      this.state.validatepassword_specialcharacter
    ) {
      this.setState({ password_updateready: true });
    } else {
      this.setState({ password_updateready: false });
    }
  };

  validatePassword() {
    return (
      this.state.validatepassword_length === true &&
      this.state.validatepassword_lowercase === true &&
      this.state.validatepassword_uppercase === true &&
      this.state.validatepassword_number === true &&
      this.state.validatepassword_specialcharacter === true
    );
  }

  async handleSubmitForm_account() {
    this.setState({ member_updating: true });
    try {
      await this.API_put_member(this.state.member);
      this.setState({ member_updating: false });
      localStorage.setItem("firstname", this.state.member.firstname);
      localStorage.setItem("lastname", this.state.member.lastname);
      this.populatemember();
    } catch (e) {
      console.log(e);
    }
  }

  handleSubmitForm_password = async (event) => {
    event.preventDefault();
    let user = {};
    user.Username = localStorage.getItem("email");
    user.NewPassword = this.state.password;
    let body = {};
    body.User = user;

    console.log(body);
    this.setState({ password_updating: true });
    try {
      await this.API_put_password(body);
      this.setState({ password_updateready: false });
      this.setState({ password_updating: false });
      this.setState({ password: "" });
      this.setState({ password_updated: true });
    } catch (e) {
      console.log(e);
    }
  };

  onClick_cancel() {
    this.populatemember();
  }

  onOpen_mdl_updatecreditcard() {
    this.setState({ card: {} });
    this.setState({ mdl_updatecreditcard_open: true });
  }

  onOpen_mdl_cancelmembership() {
    this.setState({ mdl_cancelmembership_open: true });
  }

  toggle = (type) => {
    if (type === "updateCardModal") {
      this.setState((prevState) => ({
        mdl_updatecreditcard_open: !prevState.mdl_updatecreditcard_open,
      }));
    }
    if (type === "cancelMembershipModal") {
      this.setState((prevState) => ({
        mdl_cancelmembership_open: !prevState.mdl_cancelmembership_open,
      }));
    }
    if (type == "settingsppmodal") {
      this.setState((prevState) => ({
        SettingsPpModalOpen: !prevState.SettingsPpModalOpen,
      }));
    }
  };

  cancelMembershipButton = () => {
    this.setState(
      {
        cancelMembershipTab: true,
        accountInfoTab: false,
        passwordTab: false,
        creditCardInfoTab: false,
      },
      () => {
        this.onOpen_mdl_cancelmembership();
      }
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tap Advance | Member`}</title>
        </Helmet>
        <CancelMembershipModal
          modalOpen={this.state.mdl_cancelmembership_open}
          toggle={this.toggle}
        />
        <div className="main-container-home-updated w-full">
          <div className="floating_sub_nav w-100 ">
            <div className="container flex flex-wrap  m-auto text-lg">
              <div
                onClick={() => this.tab_dashboard_onClick()}
                className={
                  "dash_floating1 floating_1 w-full md:w-1/2 flex justify-center py-5 md:border-r-2 md:border-l-2 cursor-pointer tracking-wider whatisit " +
                  (this.state.displayDashboard
                    ? "tabactive-dashboard-header"
                    : "")
                }>
                Dashboard
              </div>
              <div
                onClick={() => this.tab_account_onClick()}
                className={
                  "dash_floating2 floating_2 w-full md:w-1/2 flex justify-center py-5 md:border-r-2 cursor-pointer tracking-wider whatisinit " +
                  (this.state.displayAccount
                    ? "tabactive-dashboard-header"
                    : "")
                }>
                Account Details
              </div>
            </div>
          </div>
          {this.state.displayDashboard && (
            <div>
              <div
                className="wit_sec2  px-10 2xl:px-0"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Union9@2x.png')`,
                  backgroundPosition: "bottom",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="container mx-auto flex-wrap flex pt-10 pb-10 lg:pb-20 ">
                  <div className="wit_text_title xl:text-3xl sm:text-3xl md:text-3xl tracking-wide lineheight13 mb-4 text-brand-blue font-semibold ">
                    Welcome,{" "}
                    {this.state.member.firstname && this.state.member.firstname}
                    !
                  </div>
                </div>
              </div>

              {/* 
section 3 read writeboxes */}
              <div className="wit_sec3 lg:py-20  mytoolssec">
                <div className="container mx-auto flex py-5 flex-col">
                  <div className="title_section flex-col flex flex-wrap justify-center items-center pb-10">
                    <div className="wit_text_title xl:text-4xl sm:text-3xl md:text-3xl text-xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                      My Tools
                    </div>

                    <p
                      className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                      Put your pen to paper and see real growth happen.
                    </p>
                  </div>

                  <div className="boxes_section flex justify-around flex-wrap 2xl:px-52 lg:px-20 ">
                    {/* box1 */}
                    <a href="/block/56">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className="dash-tool-pic dash-tools-img "
                            src={process.env.PUBLIC_URL + "/dash/Journey.png"}
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className="text-lg text-brand-blue font-semibold tracking-wide">
                            Chart Your Journey
                          </div>
                          <p className="text-black tracking-wider text-xs md:text-base px-10 text-center  ">
                            My Journal >
                          </p>
                        </div>
                      </div>
                    </a>

                    {/* box1 end */}

                    {/* box2 */}
                    <a href="/block/57">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className="dash-tool-pic dash-tools-img "
                            src={
                              process.env.PUBLIC_URL + "/dash/Priorities.png"
                            }
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className=" text-base text-brand-blue font-semibold tracking-wide">
                            Discover Your Priorities
                          </div>
                          <p
                            className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                            My Priorities >
                          </p>
                        </div>
                      </div>
                    </a>

                    {/* box2 end */}

                    {/* box3 */}
                    <a href="/block/58">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className="dash-tool-pic dash-tools-img "
                            src={process.env.PUBLIC_URL + "/dash/Goals.png"}
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className="text-lg text-brand-blue font-semibold tracking-wide">
                            Reach Your Goals
                          </div>
                          <p
                            className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                            My Values & Goals >
                          </p>
                        </div>
                      </div>
                    </a>

                    {/* box3 end */}

                    {/* box4 */}
                    <a href="/block/59">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className=" dash-tool-pic dash-tools-img3 "
                            src={process.env.PUBLIC_URL + "/dash/Network.png"}
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className="text-lg text-brand-blue font-semibold tracking-wide">
                            Explore Your Network
                          </div>
                          <p
                            className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                            My Connections >
                          </p>
                        </div>
                      </div>
                    </a>

                    {/* box4 end */}

                    {/* box5 */}
                    <a href="/block/60">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className="dash-tool-pic dash-tools-img "
                            src={process.env.PUBLIC_URL + "/dash/Flag.png"}
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className="text-lg text-brand-blue font-semibold tracking-wide">
                            Get a Head Start
                          </div>
                          <p
                            className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                            Me & My Team >
                          </p>
                        </div>
                      </div>
                    </a>
                    {/* box5 end */}

                    {/* box6 */}
                    <a href="/block/61">
                      <div className="dashbox bg-white box1 flex flex-col content-start justify-around shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105">
                        <div className="dash-box-image flex justify-center h-auto">
                          {" "}
                          <img
                            className=" dash-tool-pic dash-tools-img6 "
                            src={
                              process.env.PUBLIC_URL + "/dash/Milestones.png"
                            }
                          />
                        </div>
                        <div className="dashbox-text text-center ">
                          <div className="text-lg text-brand-blue font-semibold tracking-wide">
                            Celebrate Your Achievements
                          </div>
                          <p
                            className="
             text-black tracking-wider text-xs md:text-base px-10 text-center ">
                            My Milestones >
                          </p>
                        </div>
                      </div>
                    </a>
                    {/* box6 end */}
                  </div>

                  {/* banner for notebook */}
                  <div className=" w-full mt-20 cta-container">
                    <div
                      className=" mx-auto flex cta-nb rounded-md "
                      style={{
                        backgroundImage: `url('${process.env.PUBLIC_URL}/dash/cta-bg.jpg')`,
                        backgroundPosition: "right",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}>
                      <div className=" md:w-4/5 flex flex-col p-6 ctabannercontent">
                        <div className="bannertitle text-4xl font-extrabold text-brand-blue p-2 ">
                          Access to <div className="italic">My Notebook</div>
                        </div>
                        <div className="bannertext p-2">
                          Use your notebook to journal daily to spark
                          creativity, increase emotional intelligence and evoke
                          mindfulness!
                        </div>
                        <div
                          onClick={() =>
                            (window.location.href = "/tools/mynotebook")
                          }
                          className=" bannerbtn-container">
                          <div className="m-2 bannerbtn text-white font-bold py-2 px-8 cursor-pointer rounded inline-block">
                            My Notebook >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* banner for notebook ends */}
                </div>
              </div>

              {/* learning paths */}

              <div
                className="wit_sec3 lg:py-20 learingpathsec-1"
                style={{
                  backgroundPosition: "right",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="container mx-auto flex py-16 lg:py-10 flex-col">
                  <div className="title_section flex-col flex flex-wrap justify-center items-center pb-10">
                    <div className="wit_text_title xl:text-4xl sm:text-3xl md:text-3xl text-xl tracking-wide mb-4 text-white font-semibold  ">
                      My Learning Paths
                    </div>

                    <p
                      className="
             text-white tracking-wider text-xs md:text-base px-10 text-center ">
                      Problem solving isn’t always straight-forward; Jump on a
                      path and discover solutions.
                    </p>
                  </div>

                  <div className="boxes_section learning-path-boxes flex justify-around flex-wrap 2xl:px-20 lg:px-10 ">
                    {/* box1 */}
                    {this.state.learningpaths.map((item, i) => (
                      <div
                        key={i}
                        onClick={() =>
                          (window.location.href =
                            "/learningpath/" + item.learningpath_id)
                        }
                        className="learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                        <div className="progress-box flex justify-center h-auto p-6">
                          <CircularProgressbarWithChildren
                            value={(item.blockscomplete / item.blocks) * 100}
                            styles={buildStyles({
                              textColor: "#363B5B",
                              pathColor: "#2EA3A6",
                            })}>
                            {item.blocks > 0 &&
                            item.blocks === item.blockscomplete ? (
                              <img
                                style={{ width: "80%" }}
                                src="/dash/star@100.png"
                                alt="completed"
                              />
                            ) : (
                              <>
                                <div
                                  style={{
                                    fontSize: 15,
                                    marginTop: -5,
                                    fontWeight: "bold",
                                  }}>
                                  <strong>
                                    {(
                                      item.blockscomplete / item.blocks
                                    ).toFixed(2) * 100}
                                    %
                                  </strong>
                                </div>
                                <div style={{ fontSize: 14, marginTop: -5 }}>
                                  <strong>
                                    {item.blockscomplete}/ {item.blocks} Blocks
                                  </strong>
                                </div>
                              </>
                            )}
                          </CircularProgressbarWithChildren>
                        </div>

                        <div
                          className="flex flex-col justify-center p-5 back-fade"
                          // style={{
                          //   backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Image One.png')`,
                          //   backgroundPosition: "right",
                          //   backgroundSize: "contain",
                          //   backgroundRepeat: "no-repeat",
                          // }}
                        >
                          <div className="text-brand-blue font-semibold  md:text-base xl:pr-5 ">
                            {item.learningpath_name}
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* box1 end */}
                  </div>
                </div>
              </div>

              {/* learing path ends */}

              {/* modules */}

              <div className="wit_sec3 lg:py-20  learingpathsec">
                <div className="container mx-auto flex py-16 lg:py-10 flex-col">
                  <div className="title_section flex-col flex flex-wrap justify-center items-center pb-10">
                    <div className="wit_text_title xl:text-4xl sm:text-3xl md:text-3xl text-xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                      My Modules
                    </div>

                    <p
                      className="
              text-brand-blue tracking-wider text-xs md:text-base px-10 text-center ">
                      Be a learner. Look for ways to increase your knowledge and
                      hone your skills.
                    </p>
                  </div>

                  <div className="boxes_section  flex justify-around flex-wrap 2xl:px-20 lg:px-10 ">
                    {/* box1 */}

                    <div
                      onClick={() => (window.location.href = "/module/3")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats1.blockscomplete /
                              this.state.modulestats1.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats1.blocks > 0 &&
                          this.state.modulestats1.blocks ===
                            this.state.modulestats1.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats1.blockscomplete /
                                    this.state.modulestats1.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats1.blockscomplete}/{" "}
                                  {this.state.modulestats1.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div className="text-brand-blue font-semibold  md:text-base pr-10 xl:pr-16 ">
                          Follow-Up
                        </div>
                      </div>
                    </div>

                    {/* box1 end */}

                    {/* box2 */}
                    <div
                      onClick={() => (window.location.href = "/module/8")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats2.blockscomplete /
                              this.state.modulestats2.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats2.blocks > 0 &&
                          this.state.modulestats2.blocks ===
                            this.state.modulestats2.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats2.blockscomplete /
                                    this.state.modulestats2.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats2.blockscomplete}/{" "}
                                  {this.state.modulestats2.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base  pr-10 xl:pr-16">
                          Getting a Strong Start
                        </div>
                      </div>
                    </div>

                    {/* box2 end */}

                    {/* box3 */}
                    <div
                      onClick={() => (window.location.href = "/module/4")}
                      className=" module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats3.blockscomplete /
                              this.state.modulestats3.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats3.blocks > 0 &&
                          this.state.modulestats3.blocks ===
                            this.state.modulestats3.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats3.blockscomplete /
                                    this.state.modulestats3.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats3.blockscomplete}/{" "}
                                  {this.state.modulestats3.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10 ">
                          Modern Sales
                        </div>
                      </div>
                    </div>

                    {/* box3 end */}

                    {/* box4 */}
                    <div
                      onClick={() => (window.location.href = "/module/1")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats4.blockscomplete /
                              this.state.modulestats4.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats4.blocks > 0 &&
                          this.state.modulestats4.blocks ===
                            this.state.modulestats4.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats4.blockscomplete /
                                    this.state.modulestats4.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats4.blockscomplete}/{" "}
                                  {this.state.modulestats4.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10 ">
                          Direct Sales
                        </div>
                      </div>
                    </div>

                    {/* box4 end */}

                    {/* box5 */}
                    <div
                      onClick={() => (window.location.href = "/module/23")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats5.blockscomplete /
                              this.state.modulestats5.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats5.blocks > 0 &&
                          this.state.modulestats5.blocks ===
                            this.state.modulestats5.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats5.blockscomplete /
                                    this.state.modulestats5.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats5.blockscomplete}/{" "}
                                  {this.state.modulestats5.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10 ">
                          Onboarding
                        </div>
                      </div>
                    </div>

                    {/* box5 end */}

                    {/* box6 */}
                    <div
                      onClick={() => (window.location.href = "/module/9")}
                      className=" module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats6.blockscomplete /
                              this.state.modulestats6.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats6.blocks > 0 &&
                          this.state.modulestats6.blocks ===
                            this.state.modulestats6.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats6.blockscomplete /
                                    this.state.modulestats6.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats6.blockscomplete}/{" "}
                                  {this.state.modulestats6.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10">
                          Overcoming Obstacles
                        </div>
                      </div>
                    </div>

                    {/* box6 end */}

                    {/* box7 */}
                    <div
                      onClick={() => (window.location.href = "/module/5")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats7.blockscomplete /
                              this.state.modulestats7.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats7.blocks > 0 &&
                          this.state.modulestats7.blocks ===
                            this.state.modulestats7.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats7.blockscomplete /
                                    this.state.modulestats7.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats7.blockscomplete}/{" "}
                                  {this.state.modulestats7.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>

                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10">
                          Planning Your Day
                        </div>
                      </div>
                    </div>

                    {/* box7 end */}

                    {/* box8 */}
                    <div
                      onClick={() => (window.location.href = "/module/2")}
                      className="module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats8.blockscomplete /
                              this.state.modulestats8.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats8.blocks > 0 &&
                          this.state.modulestats8.blocks ===
                            this.state.modulestats8.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats8.blockscomplete /
                                    this.state.modulestats8.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats8.blockscomplete}/{" "}
                                  {this.state.modulestats8.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>
                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10">
                          Prospecting
                        </div>
                      </div>
                    </div>

                    {/* box8 end */}

                    {/* box9 */}
                    <div
                      onClick={() => (window.location.href = "/module/7")}
                      className=" module-border learning-path-box rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                      <div className="progress-box flex justify-center h-auto p-6">
                        <CircularProgressbarWithChildren
                          value={
                            (this.state.modulestats9.blockscomplete /
                              this.state.modulestats9.blocks) *
                            100
                          }
                          styles={buildStyles({
                            textColor: "#363B5B",
                            pathColor: "#E18786",
                          })}>
                          {this.state.modulestats9.blocks > 0 &&
                          this.state.modulestats9.blocks ===
                            this.state.modulestats9.blockscomplete ? (
                            <img
                              style={{ width: "80%" }}
                              src="/dash/star@100.png"
                              alt="completed"
                            />
                          ) : (
                            <>
                              <div
                                style={{
                                  fontSize: 15,
                                  marginTop: -5,
                                  fontWeight: "bold",
                                }}>
                                <strong>
                                  {(
                                    this.state.modulestats9.blockscomplete /
                                    this.state.modulestats9.blocks
                                  ).toFixed(2) * 100}
                                  %
                                </strong>
                              </div>
                              <div style={{ fontSize: 14, marginTop: -5 }}>
                                <strong>
                                  {this.state.modulestats9.blockscomplete}/{" "}
                                  {this.state.modulestats9.blocks} Blocks
                                </strong>
                              </div>
                            </>
                          )}
                        </CircularProgressbarWithChildren>
                      </div>
                      <div
                        className="flex flex-col justify-center p-5 back-fade"
                        style={{
                          backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Red Icon@2x.png')`,
                          backgroundPosition: "right",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}>
                        <div
                          className="
              text-brand-blue font-semibold  md:text-base xl:pr-16 pr-10">
                          Setting Goals
                        </div>
                      </div>
                    </div>

                    {/* box9 end */}
                  </div>

                  {/* bottom cartoons */}
                  <div className=" w-full mt-20 cta-container">
                    <div className=" mx-auto flex max-w-screen-xl rounded-md ">
                      <div className="leftcartoon flex justify-center w-1/3">
                        <img
                          className=" max-w-sm "
                          src={process.env.PUBLIC_URL + "/dash/left@2x.png"}
                        />
                      </div>

                      <div className="w-2/3 flex justify-center items-center p-6 ">
                        <div className="bannertitle text-md md:text-4xl font-extrabold text-brand-blue p-2 text-center">
                          Tap into your potential.
                        </div>
                      </div>

                      <div className="rightcartoon flex justify-center w-1/3">
                        <img
                          className="max-w-sm "
                          src={process.env.PUBLIC_URL + "/dash/right@2x.png"}
                        />
                      </div>
                    </div>
                  </div>
                  {/* bottomcartoos ends */}
                </div>
              </div>
            </div>
          )}
          {/* modules ends */}

          {this.state.displayAccount && (
            <>
              <div>
                <div
                  className="wit_sec2  px-10 2xl:px-0"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/dash/Union9@2x.png')`,
                    backgroundPosition: "bottom",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="container mx-auto flex-wrap flex pt-10 pb-10 lg:pb-20 ">
                    <div className="wit_text_title xl:text-3xl sm:text-3xl md:text-3xl tracking-wide lineheight13 mb-4 text-brand-blue font-semibold ">
                      Update Your Details,
                      {this.state.member.firstname &&
                        this.state.member.firstname}
                    </div>
                  </div>
                </div>
              </div>

              <div className="wit_sec3 lg:py-20  mytoolssec">
                <div className="container mx-auto flex py-5 flex-col account-details-container">
                  <div className="flex flex-wrap">
                    <div className="left-account-details flex w-full md:w-1/2 md:px-16 items-center justify-center">
                      {" "}
                      <div className="account-details-tabs">
                        <div
                          onClick={() =>
                            this.setState({
                              accountInfoTab: true,
                              passwordTab: false,
                              creditCardInfoTab: false,
                              cancelMembershipTab: false,
                            })
                          }
                          className={
                            "cursor-pointer account-tab-titles " +
                            (this.state.accountInfoTab
                              ? "tabactive-account-details font-semibold"
                              : "")
                          }>
                          Account Details
                        </div>
                        <div
                          onClick={() =>
                            this.setState({
                              passwordTab: true,
                              accountInfoTab: false,
                              creditCardInfoTab: false,
                              cancelMembershipTab: false,
                            })
                          }
                          className={
                            "cursor-pointer account-tab-titles " +
                            (this.state.passwordTab
                              ? "tabactive-account-details font-semibold"
                              : "")
                          }>
                          Password
                        </div>
                        <div
                          onClick={() =>
                            this.setState({
                              creditCardInfoTab: true,
                              accountInfoTab: false,
                              passwordTab: false,
                              cancelMembershipTab: false,
                            })
                          }
                          className={
                            "cursor-pointer account-tab-titles " +
                            (this.state.creditCardInfoTab
                              ? "tabactive-account-details font-semibold"
                              : "")
                          }>
                          Credit Card Details
                        </div>
                        <div
                          onClick={() => this.cancelMembershipButton()}
                          className={
                            "cursor-pointer cancel-button account-tab-titles " +
                            (this.state.cancelMembershipTab
                              ? "tabactive-account-details font-semibold"
                              : "")
                          }>
                          Cancel Membership
                        </div>
                      </div>
                    </div>
                    <div className="right-account-details flex w-full md:w-1/2 md:px-16  items-center justify-start">
                      {this.state.accountInfoTab && (
                        <div className="account-details-inputs px-12 py-8 bg-text-breaker-bay-200">
                          {this.state.member && (
                            <Form className="tapform" id="frm_signup">
                              <Form.Field
                                style={{
                                  border: "1px solid #9CA3AF",
                                  borderRadius: 4,
                                }}
                                id="signup_firstname"
                                name="firstname"
                                value={
                                  this.state.member.firstname &&
                                  this.state.member.firstname
                                }
                                onChange={this.onChange_input}
                                control={Input}
                                label="First Name"
                                placeholder="First Name"
                              />
                              <Form.Field
                                style={{
                                  border: "1px solid #9CA3AF",
                                  borderRadius: 4,
                                }}
                                id="signup_lastname"
                                name="lastname"
                                value={
                                  this.state.member.lastname &&
                                  this.state.member.lastname
                                }
                                onChange={this.onChange_input}
                                control={Input}
                                label="Last Name"
                                placeholder="Last Name"
                              />
                              <Button
                                secondary
                                onClick={() => this.onClick_cancel()}>
                                Cancel
                              </Button>
                              {!this.state.member_updating ? (
                                <Button
                                  primary
                                  onClick={() =>
                                    this.handleSubmitForm_account()
                                  }>
                                  Save
                                </Button>
                              ) : (
                                <Button loading primary>
                                  Save
                                </Button>
                              )}
                            </Form>
                          )}
                        </div>
                      )}
                      {this.state.passwordTab && (
                        <div className="account-details-inputs px-12 py-8 bg-text-breaker-bay-200">
                          <Form
                            className="tapform"
                            id="frm_signup_password"
                            onSubmit={this.handleSubmitForm_password}>
                            <div className="pb-4 text-sm">
                              Password must be a minimum of{" "}
                              <span className="font-semibold">
                                8 characters long{" "}
                              </span>{" "}
                              and contain at least{" "}
                              <span className="font-semibold">
                                one uppercase{" "}
                              </span>{" "}
                              and{" "}
                              <span className="font-semibold">
                                {" "}
                                lowercase character, a number and a special
                                character{" "}
                              </span>
                            </div>
                            <Form.Field
                              id="signup_password"
                              name="password"
                              value={this.state.password}
                              onChange={this.onChange_password}
                              control={Input}
                              type="password"
                              label="New Password"
                              placeholder="Enter a new password"
                            />
                            <div className="passwordvalidation">
                              <div className="passwordvalidation_item">
                                {this.state.validatepassword_length ? (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/checkmark (3dbf1c).svg"
                                      alt="password status"></img>
                                  </div>
                                ) : (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/x (e22d30).svg"
                                      alt="password status"></img>
                                  </div>
                                )}
                                <div className="passwordvalidation_item_text">
                                  8 characters long
                                </div>
                              </div>
                              <div className="passwordvalidation_item">
                                {this.state.validatepassword_uppercase ? (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/checkmark (3dbf1c).svg"
                                      alt="password status"></img>
                                  </div>
                                ) : (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/x (e22d30).svg"
                                      alt="password status"></img>
                                  </div>
                                )}
                                <div className="passwordvalidation_item_text">
                                  upper case
                                </div>
                              </div>
                              <div className="passwordvalidation_item">
                                {this.state.validatepassword_lowercase ? (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/checkmark (3dbf1c).svg"
                                      alt="password status"></img>
                                  </div>
                                ) : (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/x (e22d30).svg"
                                      alt="password status"></img>
                                  </div>
                                )}
                                <div className="passwordvalidation_item_text">
                                  lower case
                                </div>
                              </div>
                              <div className="passwordvalidation_item">
                                {this.state.validatepassword_number ? (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/checkmark (3dbf1c).svg"
                                      alt="password status"></img>
                                  </div>
                                ) : (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/x (e22d30).svg"
                                      alt="password status"></img>
                                  </div>
                                )}
                                <div className="passwordvalidation_item_text">
                                  number
                                </div>
                              </div>
                              <div className="passwordvalidation_item">
                                {this.state
                                  .validatepassword_specialcharacter ? (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/checkmark (3dbf1c).svg"
                                      alt="password status"></img>
                                  </div>
                                ) : (
                                  <div className="passwordvalidation_item_icon">
                                    <img
                                      src="/icons/x (e22d30).svg"
                                      alt="password status"></img>
                                  </div>
                                )}
                                <div className="passwordvalidation_item_text">
                                  special character
                                </div>
                              </div>
                            </div>
                            {this.validatePassword() && (
                              <div>
                                {!this.state.password_updating ? (
                                  <Button
                                    className={
                                      !this.validatePassword()
                                        ? "inactivestate"
                                        : ""
                                    }
                                    primary
                                    type="submit">
                                    Reset Password
                                  </Button>
                                ) : (
                                  <Button loading primary type="submit">
                                    Reset Password
                                  </Button>
                                )}
                              </div>
                            )}
                            {this.state.password_updated && (
                              <div className="passwordvalidation_message">
                                <Message color="green">
                                  Password Successfully Reset!
                                </Message>
                              </div>
                            )}
                          </Form>
                        </div>
                      )}
                      {this.state.creditCardInfoTab && (
                        <div className="account-details-inputs px-12 py-8 bg-text-breaker-bay-200">
                          <UpdateCardModal
                          // modalOpen={this.state.mdl_updatecreditcard_open}
                          // toggle={this.toggle}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="boxes_section flex justify-around flex-wrap 2xl:px-52 lg:px-20 "></div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
