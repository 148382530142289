import React from "react";
import { Component } from "react";
import { API } from "aws-amplify";
import ReactGA from "react-ga";
// import "./what-is-tap-advance.css";
import "./what-is-tap-style.css";

import { Helmet } from "react-helmet";

export default class whatIsTapAdvance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didmount: false,
      stats: {},
      selectedTab: "whatisit",
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (this.state.didmount === false) {
      this.populatestats();
      this.state.didmount = true;
    }
  }

  async populatestats() {
    try {
      const stats = await this.API_get_stats();
      this.setState({ stats: stats[0] });
    } catch (e) {
      console.log(e);
    }
  }

  //  API call to get-module
  API_get_stats() {
    return API.get("tap", "/get-stats");
  }

  onClickWhatIsIt = () => {
    window.location.href = "#whatisit";
    this.setState({ selectedTab: "whatisit" });
  };

  onClickWhatsInIt = () => {
    window.location.href = "#whatsinit";
    this.setState({ selectedTab: "whatsinit" });
  };

  onClickWhatsInItForYou = () => {
    window.location.href = "#whatsinitforyou";
    this.setState({ selectedTab: "whatsinitforyou" });
  };
  zoomOutMobile = () => {
    const viewport = document.querySelector('meta[name="viewport"]');

    if (viewport) {
      viewport.content = "initial-scale=1";
      viewport.content = "width=device-width";
    }
  };

  //  render page --------------------------------------------------------------------------------------------------------

  render() {
    // this.zoomOutMobile();
    return (
      <div className="containerwrapper" id="whatisitwrapper">
        <Helmet>
          <title>{`Tap Advance | What Is TAP Advance?`}</title>
        </Helmet>
        <div className="floating_sub_nav w-100 ">
          <div className="container flex flex-wrap  m-auto lg:text-lg">
            <div
              onClick={() => this.onClickWhatIsIt()}
              className={
                "floating_1 w-full md:w-1/3 flex justify-center py-5 md:border-r-2 md:border-l-2 cursor-pointer tracking-wider whatisit " +
                (this.state.selectedTab === "whatisit"
                  ? "tabactive-what-header"
                  : "")
              }>
              <span className=" opacity-80 mr-2">1. </span>What is it?{" "}
            </div>
            <div
              onClick={() => this.onClickWhatsInIt()}
              className={
                "floating_2 w-full md:w-1/3 flex justify-center py-5 md:border-r-2 cursor-pointer tracking-wider whatisinit " +
                (this.state.selectedTab === "whatsinit"
                  ? "tabactive-what-header"
                  : "")
              }>
              <span className=" opacity-80 mr-2">2.</span>What’s in it?
            </div>
            <div
              onClick={() => this.onClickWhatsInItForYou()}
              className={
                "floating_3 w-full md:w-1/3 flex justify-center py-5 md:border-r-2 cursor-pointer tracking-wider " +
                (this.state.selectedTab === "whatsinitforyou"
                  ? "tabactive-what-header"
                  : "")
              }>
              <span className=" opacity-80 mr-2">3.</span>What’s in it for you?
            </div>
          </div>
        </div>
        <a name="whatisit">
          <div
            className="wit_sec1 py-10"
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/Background@2x.png')`,
              backgroundPosition: "center right",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <div className="container mx-auto flex flex-wrap">
              <div className="wit_inside_left lg:w-1/2 w-100  p-10 lg:p-32">
                <div className="wit_text_container flex flex-col">
                  <div className="wit_text_title xl:text-5xl sm:text-3xl md:text-4xl tracking-wide lineheight13 mb-4 text-white font-semibold ">
                    1. What is it?
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-white tracking-wider ml-5">
                      Learning with TAP Advance is easy, affordable and fun! It
                      is an online training resource that is filled with
                      valuable bite-sized pieces of self-paced training for
                      Direct Sales Professionals.
                    </div>
                  </div>
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100">
                <img
                  className="p-10"
                  src={process.env.PUBLIC_URL + "/whatistap/11@2x.png"}
                />
              </div>
            </div>
          </div>
        </a>
        <div
          className="2ndsec_bg"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/grey-back-wit.png')`,
            backgroundPosition: "0% -40%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <div className="wit_sec2 lg:pb-10">
            <div className="container mx-auto flex flex-wrap flex-col-reverse lg:flex-row wit_sec_container">
              <div className="wit_inside_left lg:w-1/2 w-100  ">
                <div className=" relative parentofcircleimg flex justify-center">
                  <img
                    className="absolute circleimg p-10 lg:p-0 circleimg"
                    src={process.env.PUBLIC_URL + "/whatistap/circle-img.png"}
                  />
                </div>
              </div>

              <div className="wit_inside_right lg:w-1/2 w-100 flex justify-center items-center">
                <div className=" flex flex-col p-10 lg:p-0">
                  <div className="wit_text_title max-w-600 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                    Developed by
                    <span className="inline-block px-2 ml-2">
                      industry experts.
                    </span>
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-black tracking-wider">
                      With over 25+ years of combined experience in the Direct
                      Sales Industry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wit_sec3 lg:py-20">
            <div className="container mx-auto flex lg:py-0 py-20 flex-col">
              <div className="title_section flex flex-wrap justify-center items-center pb-10">
                <div className="wit_text_title text-center xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                  TAP Advance appeals to every learner.{" "}
                </div>
              </div>
              <div className="boxes_section flex justify-between flex-wrap">
                <div
                  className="box box1 flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/eyeicon.png')`,
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="box-text text-center ">
                    <h3 className="2xl:text-3xl text-xl text-brand-blue font-semibold tracking-wide">
                      See
                    </h3>
                  </div>
                </div>

                <div
                  className="box box2  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/headphoneicon.png')`,
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="box-text text-center ">
                    <h3 className="2xl:text-3xl text-xl text-brand-blue font-semibold tracking-wide">
                      Listen
                    </h3>
                  </div>
                </div>

                <div
                  className="box box3  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/bookicon.png')`,
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="box-text text-center ">
                    <h3 className=" 2xl:text-3xl text-xl text-brand-blue font-semibold tracking-wide">
                      Read & Write
                    </h3>
                  </div>
                </div>

                <div
                  className="box box4  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/handicon.png')`,
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="box-text text-center ">
                    <h3 className="2xl:text-3xl text-xl text-brand-blue font-semibold tracking-wide">
                      Do
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wit_sec2 lg:py-20">
          <div className="container mx-auto flex-wrap flex wit_sec_container">
            <div className="wit_inside_left md:w-1/2 w-100  flex justify-center content-center">
              <div className="flex flex-col justify-center p-10 md:p-0">
                <div className="wit_text_title max-w-600 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                  TAP Advance has you covered.
                </div>
                <div className="wit_text_para">
                  <div className="text-lg text-black tracking-wider max-w-600">
                    Our training content consists of foundational skills,
                    trending topics, industry norms, best & worst practices,
                    innovative ideas, fun & motivating challenges, current
                    research & stats, and fresh perspectives.
                  </div>
                </div>
              </div>
            </div>
            <div className="wit_inside_right md:w-1/2 w-100 flex justify-center items-center">
              <div className="flex justify-center">
                <img
                  className="p-10 lg:p-0"
                  src={process.env.PUBLIC_URL + "/whatistap/wit02.png"}
                />
              </div>
            </div>
          </div>
        </div>
        <a name="whatsinit">
          <div
            className="wit_sec1 py-10"
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/sec-2-bac.png')`,
              backgroundPosition: "bottom right",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <div className="container mx-auto flex flex-wrap">
              <div className="wit_inside_left lg:w-1/2 w-100  p-10 lg:p-32">
                <div className="wit_text_container flex flex-col">
                  <div className="wit_text_title xl:text-5xl sm:text-3xl md:text-4xl tracking-wide lineheight13 mb-4 text-white font-semibold ">
                    2. What’s in it?
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-white tracking-wider ml-5">
                      Tap Advance is filled with industry specific training that
                      will help you solve problems, hone skills, and increase
                      productivity.
                    </div>
                  </div>
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100">
                <img
                  className="p-10"
                  src={process.env.PUBLIC_URL + "/whatistap/12@2x.png"}
                />
              </div>
            </div>
          </div>
        </a>
        <div
          className="puzzle-back my-30 lg:my-0"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/puzzle-back.png')`,
            backgroundPosition: "left",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}>
          <div className="wit_sec2  lg:py-15">
            <div className="container mx-auto flex flex-wrap flex-col-reverse lg:flex-row  wit_sec_container">
              <div className="wit_inside_left lg:w-1/2 w-100  flex justify-center content-center">
                <div className="flex justify-center">
                  <img
                    className=""
                    src={process.env.PUBLIC_URL + "/whatistap/city03.png"}
                  />
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100 flex justify-center items-center">
                <div className="flex flex-col justify-center p-10 lg:p-0">
                  <div className="wit_text_title max-w-650 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                    Solutions that are right for you.
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-black tracking-wider max-w-650">
                      TAP Advance’s custom Learning Paths answer tough
                      questions, address hot topics and known industry
                      challenges. Remove distractions and uncover solutions.{" "}
                    </div>
                  </div>

                  <div className="wit_text_para mt-6">
                    <div className="text-lg text-black tracking-wider  max-w-650">
                      Our content developers have researched the top struggles
                      that Direct Sales Professionals face and have approached
                      our Learning Paths from several perspectives.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wit_sec2 py-10">
            <div className="container mx-auto flex-wrap lg:flex-nowrap flex wit_sec_container justify-center">
              <div className="wit_inside_left lg:w-1/2 w-100  flex justify-center content-center">
                <div className="flex flex-col justify-center p-10 lg:p-0">
                  <div className="wit_text_title max-w-650 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                    Personalize your learning experience.
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-black tracking-wider max-w-650 ">
                      Track your progress with the help of your Dashboard by
                      viewing what you have completed and what still needs to be
                      done.{" "}
                    </div>
                  </div>

                  <div className="wit_text_para mt-6">
                    <div className="text-lg text-black tracking-wider max-w-650">
                      Take time to write things down to help you remember and
                      prioritize while creating personal accountability. Use My
                      Notebook daily to record your “ah ha” moments and
                      recognize the things you do well and where you could do
                      better.{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100 flex justify-center items-center">
                <div className="flex justify-center content-center">
                  <img
                    className="p-10 lg:p-0"
                    src={process.env.PUBLIC_URL + "/whatistap/study04.png"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="wit_sec2 py-15">
            <div className="container mx-auto  flex flex-wrap flex-col-reverse lg:flex-row wit_sec_container">
              <div className="wit_inside_left lg:w-1/2 w-100  flex justify-center content-center">
                <div className="flex justify-center">
                  <img
                    className="p-10"
                    src={process.env.PUBLIC_URL + "/whatistap/superhero.png"}
                  />
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100 flex justify-center items-center">
                <div className="flex flex-col justify-center p-10 md:p-0">
                  <div className="wit_text_title max-w-650 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                    Action what you’ve learned.
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-black tracking-wider max-w-650">
                      Utilize our downloadable & printable TAP Action Sheets™ to
                      create movement and momentum in your personal or
                      professional development.{" "}
                    </div>
                  </div>

                  <div className="wit_text_para mt-6">
                    <div className="text-lg text-black tracking-wider  max-w-650">
                      It’s one thing to talk about changing and growing but it’s
                      a game changer when you do something about it.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="wit_sec3 video-sec_wit lg:pt-20 lg:px-40 pb-48"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/texturebg_wit.png')`,
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <div className="container mx-auto flex py-5 flex-col">
            <div className="title_section flex flex-wrap justify-center items-center pb-10">
              <div className="wit_text_title xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold pt-20 ">
                A full library of content.
              </div>
            </div>
            <div className="flex flex-col items-center shadow-lg	videoWrapper p-10 ">
              <iframe
                src="https://www.youtube.com/embed/2fCwm6idNdQ"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>

        <div className="wit_sec3 fourresources">
          <div className="container mx-auto">
            <div className="boxes_section flex justify-between flex-wrap">
              <div
                className="boxresources box1 flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/mod1.png')`,
                  backgroundPosition: "center right",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="box-text text-center ">
                  <div className="2xl:text-3xl text-xl text-white font-semibold tracking-wide">
                    10 Modules
                  </div>
                </div>
              </div>

              <div
                className="boxresources box2  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/block1.png')`,
                  backgroundPosition: "center right",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="box-text text-center ">
                  <div className="2xl:text-3xl text-xl text-white font-semibold tracking-wide">
                    191 Blocks
                  </div>
                </div>
              </div>

              <div
                className="boxresources box3  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/topics1.png')`,
                  backgroundPosition: "center right",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="box-text text-center ">
                  <div className="2xl:text-3xl text-xl text-white font-semibold tracking-wide">
                    225 Topics
                  </div>
                </div>
              </div>

              <div
                className="boxresources box4  flex flex-col content-end justify-end shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/resources1.png')`,
                  backgroundPosition: "center right",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="box-text text-center ">
                  <div className="2xl:text-3xl text-xl text-white font-semibold tracking-wide">
                    253 Resources
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wit_sec2 py-20">
          <div className="container mx-auto flex flex-wrap wit_sec_container">
            <div className="wit_inside_left lg:w-2/3 w-100  flex justify-center content-start">
              <div className="flex flex-col justify-center p-10 lg:p-0">
                <div className="wit_text_title max-w-650 xl:text-2xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                  “Investing in Tap Advance is one of the greatest things you
                  can do for your business!”
                </div>
                <div className="wit_text_para">
                  <div className="text-lg text-black tracking-wider max-w-650 ">
                    “No matter where you are in your Direct Sales journey, the
                    training you will find in TAP Advance will not only help you
                    overcome challenges, but help you grow personally and
                    professionally.{" "}
                  </div>
                </div>

                <div className="wit_text_para mt-6">
                  <div className="text-lg text-black tracking-wider max-w-650">
                    This is one of the most valuable training resources for
                    Direct Sales I’ve come across!”{" "}
                  </div>
                </div>

                <div className="wit_text_para mt-6">
                  <div className="text-lg text-black tracking-wider max-w-650">
                    <div className="block text-brand-blue font-semibold">
                      Isabelle Terry
                    </div>
                    Sales Education Manager
                  </div>
                  <div className=" text-light-gray-brand">Mary Kay</div>
                </div>
              </div>
            </div>
            <div className="wit_inside_right lg:w-1/3 w-full flex justify-center items-center">
              <div className="flex justify-center">
                <img
                  className="rounded-md p-10"
                  src={process.env.PUBLIC_URL + "/whatistap/isabelleterry.png"}
                />
              </div>
            </div>
          </div>
        </div>

        <a name="whatsinitforyou">
          <div
            className="wit_sec1 md:py-10 bg-gray-50"
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/whatistap/sec-3-bac.png')`,
              backgroundPosition: "bottom right",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <div className="container mx-auto flex flex-wrap">
              <div className="wit_inside_left lg:w-1/2 w-100 p-10 lg:p-24 ">
                <div className="wit_text_container flex flex-col">
                  <div className="wit_text_title xl:text-5xl sm:text-3xl md:text-4xl tracking-wide lineheight13 mb-4 text-white font-semibold ">
                    3.What’s in it for you?
                  </div>
                  <div className="wit_text_para">
                    <div className="text-lg text-white tracking-wider ml-5">
                      If you desire to build a sustainable business that allows
                      you to give back to others, have a flexible schedule and
                      create a life that you love, then TAP Advance is the tool
                      for you and your team!
                    </div>
                  </div>
                </div>
              </div>
              <div className="wit_inside_right lg:w-1/2 w-100 p-10 md:p-0">
                <img
                  className="pt-10 pb-4 "
                  src={process.env.PUBLIC_URL + "/whatistap/sec3-img.png"}
                />
              </div>
            </div>
          </div>
        </a>

        <div className="wit_sec2 py-15 bg-gray-50">
          <div className="container mx-auto flex flex-wrap flex-col-reverse lg:flex-row wit_sec_container">
            <div className="wit_inside_left lg:w-1/2 w-100  flex justify-center content-center">
              <div className="flex justify-center">
                <img
                  className="p-10"
                  src={
                    process.env.PUBLIC_URL +
                    "/whatistap/quality-connections-img.png"
                  }
                />
              </div>
            </div>
            <div className="wit_inside_right lg:w-1/2 w-100 flex justify-center items-center">
              <div className="flex flex-col justify-center p-10 md:p-0">
                <div className="wit_text_title max-w-650 xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold  ">
                  Build Quality Connections.
                </div>
                <div className="wit_text_para">
                  <div className="text-lg text-black tracking-wider max-w-650">
                    Modern sales is different than it used to be. The consumer
                    has changed, and we need to change with them.{" "}
                  </div>
                </div>

                <div className="wit_text_para mt-6">
                  <div className="text-lg text-black tracking-wider  max-w-650">
                    Build and maintain your network list and keep track of your
                    connections. Create a follow-up plan and set reminders so
                    that you can serve your customers the way they deserve.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* /////THIS IS BREAKING */}
        <div className="wit_sec3  lg:pt-20 lg:px-40 lg:pb-48 bg-gray-50">
          <div className="container mx-auto flex py-5 flex-col">
            <div className="title_section flex flex-wrap justify-center items-center pb-10">
              <div className="text-center wit_text_title xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold pt-20 ">
                Create a Sustainable Business.
              </div>
            </div>
            <div className="flex flex-wrap justify-around lg:px-48">
              <div className="sus-border learning-path-box2 rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                <div className="sus-pic flex justify-center h-auto p-6">
                  <img
                    className="object-contain"
                    src={process.env.PUBLIC_URL + "/whatistap/7-a.png"}
                  />
                </div>

                <div className="flex flex-col justify-center p-5 back-fade">
                  <div className="text-brand-blue font-semibold  md:text-base">
                    Get a strong start and lay a great foundation for your
                    business.
                  </div>
                </div>
              </div>

              <div className="sus-border learning-path-box2 rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                <div className="sus-pic flex justify-center h-auto p-6">
                  <img
                    className="object-contain"
                    src={process.env.PUBLIC_URL + "/whatistap/7-b.png"}
                  />
                </div>

                <div className="flex flex-col justify-center p-5 back-fade">
                  <div className="text-brand-blue font-semibold  md:text-base">
                    Understand what is important to create a life you love.
                  </div>
                </div>
              </div>

              <div className="sus-border learning-path-box2 rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                <div className="sus-pic flex justify-center h-auto p-6">
                  <img
                    className="object-contain "
                    src={process.env.PUBLIC_URL + "/whatistap/7-c.png"}
                  />
                </div>

                <div className="flex flex-col justify-center p-5 back-fade">
                  <div className="text-brand-blue font-semibold  md:text-base">
                    Feel good about what you do and how you do it.
                  </div>
                </div>
              </div>

              <div className="sus-border learning-path-box2 rounded-md bg-white box1 flex content-start shadow-lg hover:shadow-xl transform transition duration-300 hover:scale-105 cursor-pointer">
                <div className="sus-pic flex justify-center h-auto p-6">
                  <img
                    className="object-contain"
                    src={process.env.PUBLIC_URL + "/whatistap/7-d.png"}
                  />
                </div>

                <div className="flex flex-col justify-center p-5 back-fade">
                  <div className="text-brand-blue font-semibold  md:text-base">
                    Uncover your potential while you help others tap into
                    theirs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ///////////////////// */}
        <div className="wit_sec3 video-sec_wit lg:pt-20 lg:px-40 pb-48">
          <div className="container mx-auto flex py-5 flex-col">
            <div className="title_section flex flex-wrap justify-center items-center pb-10">
              <div className="wit_text_title xl:text-4xl sm:text-3xl md:text-2xl tracking-wide mb-4 text-brand-blue font-semibold md:pt-20 ">
                How to get started:
              </div>
            </div>
            <div className="flex flex-col items-center shadow-lg	videoWrapper p-10 ">
              <iframe
                src="https://www.youtube.com/embed/IwwtoESRjYo"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
