import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import "./home.css";
import { Button } from "semantic-ui-react";
import ReactGA from "react-ga";
import Fade from "react-reveal/Fade";

export default class home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didmount: false,
      stats: {},
    };
  }

  //  populate page on fist load
  async componentDidMount() {
    ReactGA.initialize("UA-160033896-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (localStorage.getItem("authenticated") === "true") {
      window.location.href = "/member";
    }

    if (this.state.didmount === false) {
      this.populatestats();
      this.state.didmount = true;
    }
  }

  async populatestats() {
    try {
      const stats = await this.API_get_stats();
      this.setState({ stats: stats[0] });
    } catch (e) {
      console.log(e);
    }
  }

  //  API call to get-module
  API_get_stats() {
    return API.get("tap", "/get-stats");
  }

  onClick_btn_checkousout = (event) => {
    this.props.history.push("https://theaccountabilitypartners.net");
  };

  render() {
    return (
      <div className="containerwrapper" style={{ marginTop: "-10px" }}>
        <Helmet>
          <title>Tap Advance | Home</title>
          <meta
            name="description"
            content="Are you a Network Marketer, Sales Professional or Small Business Entrepreneur?  Learn to build a sustainable business with our simple and fresh approach!"
          />
        </Helmet>
        <div className="main-container-home-updated">
          {/* NEW CODE */}
          <div
            className="hero-updated w-100 py-10 container-padding flex flex-wrap mx-0 md:flex-nowrap"
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/background_texture@2x.png')`,
              backgroundPosition: "bottom",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // minHeight: "858px",
            }}>
            <div className="left_hero lg:w-1/2 sm:w-screen sm:px-30 py-30 flex justify-center content-center     ">
              <div className="textcontainer  lg:w-1/2 md:w-3/4  h-auto  sm:w-3/4 ">
                <h1 className="xl:text-5xl sm:text-3xl md:text-4xl tracking-wide lineheight13 mb-4 text-white font-semibold ">
                  Are you ready <br></br>to{" "}
                  <span className="brand-red font-extrabold">advance</span> your
                  business?
                </h1>
                <p className="text-lg text-white font-light tracking-wider">
                  Tap into your potential with affordable training created for
                  the modern Direct Sales Professional.
                </p>
              </div>
            </div>
            <div className="right_hero md:w-1/2 sm:w-screen lg:mt-20 mr-10 px-10 py-10 relative ">
              <div
                className=" littlefloat climb"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/hero_section_back.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}></div>
              <img
                className=""
                src={process.env.PUBLIC_URL + "/hero_section.png"}
              />
            </div>
          </div>
          {/* NEW COde */}
          {/* <div
            className="hero-updated w-100 py-10 px-10 flex"
            style={{
              backgroundImage: `url('/background_texture@2x.png')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // minHeight: "858px",
            }}>
            <div className="left_hero w-1/2 px-30 py-30 flex justify-center content-center w-1/2     ">
              <div className="textcontainer  w-1/2 h-auto ">
                <h1 className="text-5xl tracking-wide lineheight13 mb-4 text-white font-semibold ">
                  Are you ready <br></br>to{" "}
                  <span className="brand-red font-extrabold">advance</span> your
                  business?
                </h1>
                <p className="text-lg text-white font-light tracking-wider">
                  Tap into your potential with affordable training created for
                  the Direct Sales Professional.
                </p>
              </div>
            </div>
            <div className="right_hero w-1/2 mt-20 mr-10 px-10 py-10 relative">
              <div
                className="littlefloat climb"
                style={{
                  backgroundImage: `url('/hero_section_back.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}></div>
              <img
                className=""
                src={process.env.PUBLIC_URL + "/hero_section.png"}
              />
            </div>
          </div> */}
          <div className="hero_after container-padding  flex text-center flex-col py-8 ">
            <h1
              style={{ color: "#363C5B" }}
              className="text-5xl tracking-wide mb-4 font-semibold">
              Building a business is hard.
            </h1>
            <p
              style={{ color: "#363C5B" }}
              className="subtext tracking-wide text-xl  lineheight13">
              If you’re facing challenges, don’t worry,{" "}
              <span className="font-semibold">you’re not alone.</span>
              <span
                className="teal brand-green my-3 font-semibold mt-16 text-2xl"
                style={{ display: "inline-block" }}>
                Tap on these common problems and let us help you find a
                solution!
              </span>
            </p>
          </div>
          {/* <div
            className="mainboxes container-padding  "
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/city_bg@2x.png')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <div className="cardcontainer flex py-10  ">
              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card1n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard rounded-md "
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card2n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card3n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card4n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card5n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card6n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card7n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card8n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>

              <div
                className=" homecard shadow-xl rounded-md"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/card9n.png')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}></div>
            </div>
          </div> */}
          <div
            className="mainboxes container-padding  "
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/city_bg@2x.png')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <div className="cardcontainer flex py-10">
              <Link to="/i-feel-stuck" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card1n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="landingpages_item_title">I feel stuck</div>
                </div>{" "}
              </Link>
              <Link to="/im-new-to-direct-sales" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card2n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="landingpages_item_title">
                    I'm new to direct sales
                  </div>
                </div>
              </Link>
              <Link to="/i-dont-know-anyone-else" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card3n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  {" "}
                  <div className="landingpages_item_title">
                    I don't know anyone else!
                  </div>
                </div>
              </Link>

              <Link to="/i-dont-know-how-to-sell" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card4n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="landingpages_item_title">
                    I don't know how to sell
                  </div>
                </div>
              </Link>
              <Link to="/i-dont-know-how-to-handle-hard-questions" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card5n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  {" "}
                  <div className="landingpages_item_title">
                    I don't know how to handle hard questions
                  </div>
                </div>
              </Link>
              <Link to="/how-do-i-build-trust" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card6n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  {" "}
                  <div className="landingpages_item_title">
                    How do I build trust?
                  </div>
                </div>
              </Link>
              <Link to="/i-dont-know-how-to-build-a-business" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card7n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="landingpages_item_title">
                    I don’t know how to build a business
                  </div>
                </div>
              </Link>

              <Link to="/whats-holding-me-back" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card8n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  <div className="landingpages_item_title">
                    What's holding me back?
                  </div>
                </div>
              </Link>
              <Link to="/where-should-i-be-spending-my-time" className="">
                <div
                  className="homecard transform transition duration-300 hover:scale-105 hover:shadow-xl shadow-xl rounded-md"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/card9n.png')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}>
                  {" "}
                  <div className="landingpages_item_title">
                    Where should I be spending my time?
                  </div>
                </div>
              </Link>
            </div>
          </div>{" "}
        </div>
        {/* <div className="homeheader_wrapper">
          <div className="homeheader_text_wrapper">
            <div className="homeheader_text">
              <div className="homeheader_text_title">
                Are you ready to advance your business?
              </div>
              <div className="homeheader_text_subtitle">
                Tap into your potential with affordable training created for the
                Direct Sales Professional.
              </div>
            </div>
          </div>

          <div className="homeheader">
            <div className="homeheader_wrapper_left">
              <div className="homeheader_block_A"></div>
              <div className="homeheader_block_D"></div>
              <div className="homeheader_block_L"></div>
            </div>
            <div className="homeheader_wrapper_center">
              <div className="homeheader_block_B"></div>
              <div className="homeheader_wrapper_center_EFGHIJ">
                <div className="homeheader_block_E"></div>
                <div className="homeheader_block_F"></div>
                <div className="homeheader_block_G"></div>
              </div>
              <div className="homeheader_block_I"></div>
              <div className="homeheader_block_M"></div>
            </div>

            <div className="homeheader_wrapper_right">
              <div className="homeheader_block_C"></div>
              <div className="homeheader_block_K"></div>
              <div className="homeheader_block_N"></div>
            </div>
          </div>
        </div> 

        <div className="maincontentblock mcb_landingpages_intro">
          <div className="landingpages_intro_wrapper">
            <p>Building a business is hard.</p>
            <p>If you’re facing challenges, don’t worry, you’re not alone.</p>
            <p className="landingpages_intro_pullout">
              Tap on these common problems and let us help you find a solution!
            </p>
          </div>
        </div>*/}
        {/* <div className="maincontentblock mcb_landingpages">
          <div className="landingpages_wrapper">
            <Link to="/i-feel-stuck" className="">
              <div className="landingpages_item_icon">
                <img src="/icons/i-feel-stuck.png" alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">I feel stuck</div>
            </Link>
            <Link to="/im-new-to-direct-sales" className="">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/im-new-to-network-marketing.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                I'm new to direct sales
              </div>
            </Link>
            <Link to="/i-dont-know-anyone-else" className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/i-dont-know-anyone-else.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                I don't know anyone else!
              </div>
            </Link>
            <Link to="/i-dont-know-how-to-sell" className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/i-dont-know-how-to-sell.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                I don't know how to sell
              </div>
            </Link>
            <Link
              to="/i-dont-know-how-to-handle-hard-questions"
              className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/i-dont-know-how-to-handle-objections.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                I don't know how to handle hard questions
              </div>
            </Link>
            <Link to="/how-do-i-build-trust" className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/how-do-i-build-trust.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                How do I build trust?
              </div>
            </Link>
            <Link
              to="/i-dont-know-how-to-build-a-business"
              className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/how-do-i-build-a-business.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                I don’t know how to build a business
              </div>
            </Link>

            <Link to="/whats-holding-me-back" className="landingpages_item">
              <div className="landingpages_item_icon">
                <img src="/icons/whats-in-my-way.png" alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                What's holding me back?
              </div>
            </Link>
            <Link
              to="/where-should-i-be-spending-my-time"
              className="landingpages_item">
              <div className="landingpages_item_icon">
                <img
                  src="/icons/where-should-i-be-spending-my-time.png"
                  alt="on track icon"></img>
              </div>
              <div className="landingpages_item_title">
                Where should I be spending my time?
              </div>
            </Link>
          </div>
        </div> */}
        {/*
                <div className="maincontentblock mcb_home_stats">
                    <div className="home_stats_wrapper">
                        <div className="home_stats_item" id="home_stats_item_modules">
                            <a href="/library"><div className="home_stats_item_icon"><img src="/icons/module 120px (eb6060).svg" alt="on track icon"></img></div></a>
                            <div className="home_stats_item_number">
                                {this.state.stats.modules} Modules
                            </div>
                        </div>
                        <div className="home_stats_item" id="home_stats_item_blocks">
                            <a href="/library"><div className="home_stats_item_icon"><img src="/icons/block 120px (2ea3a6).svg" alt="on track icon"></img></div></a>
                            <div className="home_stats_item_number">
                                {this.state.stats.blocks} Blocks
                            </div>
                        </div>
                        <div className="home_stats_item" id="home_stats_item_topics">
                            <a href="/library"><div className="home_stats_item_icon"><img src="/icons/topic 120px (cfaea9).svg" alt="on track icon"></img></div></a>
                            <div className="home_stats_item_number">
                                {this.state.stats.topics} Topics
                            </div>
                        </div>
                        <div className="home_stats_item" id="home_stats_item_resources">
                            <a href="/library"><div className="home_stats_item_icon"><img src="/icons/resource 120px (5e637c).svg" alt="on track icon"></img></div></a>
                            <div className="home_stats_item_number">
                                {this.state.stats.resources} Resources
                            </div>
                        </div>
                    </div>
                    <div className="home_stats_text">If you are curious, <a href="https://tap-app-resources.s3.amazonaws.com/public/pdf/Content-Slideshow.pdf" target="_blank">learn more</a> about how our content works!</div>
                </div>
*/}
        <div
          className="pricing_home_updated container-padding w-100 md:py-10 md:px-10 "
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/pricingbg.png')`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // minHeight: "858px",
          }}>
          <Fade>
            <div className="pricing_intro flex flex-col items-center my-10 ">
              <h1 className="tracking-wide text-white text-5xl font-semibold lg:max-w-2xl wd-full px-10 lineheight13 text-center ">
                Tap into your potential{" "}
                <span className="brand-red font-extrabold">today!</span>
              </h1>
            </div>
          </Fade>
          <Fade>
            <div className="pricing_card_container flex flex-wrap">
              <div className="left_pricing w-full lg:w-1/2 flex  justify-center lg:justify-end lg:p-5 mb-2">
                <div class="pricing-cards flex flex-col max-w-md bg-white p-10 rounded overflow-hidden shadow-lg card h-auto">
                  <div
                    className="card-header flex flex-col items-center rounded py-5 mb-5"
                    style={{
                      backgroundImage: `url('${process.env.PUBLIC_URL}/logodecoration.png')`,
                      backgroundPosition: "10% 10%",
                      backgroundSize: "unser",
                      backgroundRepeat: "no-repeat",
                      // minHeight: "858px",
                    }}>
                    <div className="price py-2 text-5xl font-bold brand-green ">
                      <span className="money">$</span>9.99
                    </div>
                    <div className="price py-2 font-semibold uppercase text-gray-400 tracking-wide   ">
                      USD/month
                    </div>
                    <div className="price_info py-2 text-base  text-black ">
                      Base price <span className="text-gray-300"> |</span>{" "}
                      Billed monthly <span className="text-gray-300"> |</span>{" "}
                      Cancel anytime
                    </div>
                  </div>

                  <div className="card-body pt-5 px-2">
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Customize your learning.</span>
                    </div>
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Build foundational skills.</span>
                    </div>
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Download important resources.</span>
                    </div>
                    <div className="pricingfeature">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>
                        Master <i>Modern</i> Sales.
                      </span>
                    </div>
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Experience personal growth.</span>
                    </div>
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Develop as a leader.</span>
                    </div>
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Build a sustainable business.</span>
                    </div>{" "}
                    <div className="pricingfeature ">
                      <svg
                        classname="checkmarksvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26">
                        <path d="M22.566 4.73l-1.793-1.218a1.088 1.088 0 00-1.507.285l-8.79 12.96-4.039-4.038a1.085 1.085 0 00-1.53 0L3.37 14.254a1.09 1.09 0 000 1.535L9.582 22c.348.348.895.613 1.387.613s.988-.308 1.308-.773L22.855 6.234a1.082 1.082 0 00-.289-1.504z" />
                      </svg>
                      <span>Grow at your own pace.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_pricing w-full lg:w-1/2 flex justify-center lg:justify-start lg:p-5 mb-2">
                <div class="pricing-cards flex flex-col max-w-md bg-white p-10 rounded overflow-hidden shadow-lg card h-auto">
                  <div className="card-header flex flex-col items-center rounded  mb-5">
                    <img
                      className="priceguy"
                      src={process.env.PUBLIC_URL + "/priceguy.png"}
                    />

                    <div className="price_info py-2 text-base  text-black text-center px-10 my-2 ">
                      For the price of two lattes a month, bridge the gap
                      between your dreams and your potential.
                    </div>
                  </div>

                  <div className="card-body pt-2 px-2">
                    <div
                      style={{ color: "#363C5B" }}
                      className="text-center text-xl max-w-xl font-semibold mb-2">
                      Advance your business.
                    </div>
                    <div className="text-center">
                      <button
                        onClick={() => (window.location.href = "/signup")}
                        class="bg-breaker-bay-500 hover:bg-breaker-bay-600 text-white font-bold py-4 px-8 rounded">
                        Sign up
                      </button>
                    </div>

                    <div className="pricingfeature text-center my-5 ">
                      <p>
                        Already a member?{" "}
                        <a
                          className="font-bold brand-green brand-green-link"
                          href="/signin">
                          {" "}
                          Sign In
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </Fade>
        </div>
        {/* <div className="maincontentblock mcb_home_signup">
          <div className="home_signup_wrapper">
            <div className="home_signup_block_1">
              <div
                className="home_signup_block_content"
                id="home_signup_block_content_price">
                <div className="home_signup_cta_price_wrapper_outer">
                  <div></div>
                  <div className="home_signup_cta_price_wrapper">
                    <div className="home_signup_cta_price_currency_icon">$</div>
                    <div className="home_signup_cta_price_price">9.99</div>
                    <div className="home_signup_cta_price_currency">USD</div>
                    <div className="home_signup_cta_price_duration">/month</div>
                  </div>
                </div>
                <div className="home_signup_block_content_price_features">
                  <div className="home_signup_block_content_price_features_item">
                    Base price
                  </div>
                  <div className="home_signup_block_content_price_features_item">
                    Billed monthly
                  </div>
                  <div className="home_signup_block_content_price_features_item">
                    Cancel anytime
                  </div>
                </div>
              </div>
            </div>
            <div className="home_signup_block_2">
              <div className="home_signup_block_2_wrapper">
                <div className="home_signup_block_2_img">
                  <img src="/icons/coffee cups.svg" alt="coffee cups"></img>
                </div>
                <div>
                  For the price of two lattes, access our growing and evolving
                  learning environment.
                </div>
              </div>
            </div>
            <div className="home_signup_block_3">
              <div className="home_signup_block_3_wrapper">
                <div>Unlimited Access</div>
                <div>Foundational skill building</div>
                <div>Modern Sales Training</div>
                <div>Trending Industry Topics</div>
                <div>Downloadable Resources</div>
                <div>Personalized Action Sheets</div>
              </div>
            </div>
            <div className="home_signup_block_4">
              <div className="home_signup_block_4_wrapper">
                <div className="home_signup_block_4_title">
                  Ready to learn with us?
                </div>
                <a href="/signup">
                  <div className="home_signup_block_4_cta_button_wrapper">
                    <Button
                      primary
                      className="btn_primary_eb6060_ffffff home_signup_block_4_cta_button">
                      SIGN UP
                    </Button>
                  </div>
                </a>
                <div>
                  Already a member? <a href="/signin">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <a href="https://theaccountabilitypartners.net">
          <div className="maincontentblock mcb_home_whoistap">
            <div className="home_whoistap_1">
              <div>
                "We are dreamers, shakers, visionaries and influencers. We are
                also students and entrepreneurs who like you, simply desire to
                be the best we can be."
              </div>
            </div>
            <div className="home_whoistap_2"></div>
            <div className="home_whoistap_3">
              <img src="/icons/logo-tap-square-white.png" alt="tap logo"></img>
            </div>
          </div>
        </a> */}
        <div className="footer-banner brand-dblue-bg container-padding px-20 py-28 text-center flex justify-center">
          <div
            className="flex flex-wrap w-3/4 items-center cursor-pointer"
            onClick={() =>
              (window.location.href = "https://theaccountabilitypartners.net")
            }>
            <div className="left-footer-banner flex justify-center w-full md:w-1/2">
              {" "}
              <div className="text-white text-2xl tracking-wide text-center">
                "Connect with our supportive community, invite accountability,
                and experience the transformation you’ve been looking for."
              </div>
            </div>
            <div className="right-footer-banner w-full md:w-1/2 flex justify-center my-5">
              {" "}
              <div className="accountabilty-banner-logo">
                <img
                  src="/icons/logo-tap-square-white.png"
                  alt="tap logo"></img>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}
