import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/appliedroute";
import home from "./containers/home/home";
import signup from "./containers/signup/signup";
import signupCardInfo from "./containers/signupCardInfo/index.js";
import signin from "./containers/signin/signin";
import verification from "./containers/verification/verification";

import reset from "./containers/reset/reset";
import verifyCode from "./containers/reset/verify";
import forgot from "./containers/forgot/forgot";

import virtualcoaching from "./containers/signup/virtualcoaching/virtualcoaching";
import workshops from "./containers/signup/workshops/workshops";
import training from "./containers/signup/training/training";
import keynotes from "./containers/signup/keynotes/keynotes";
import webinars from "./containers/signup/webinars/webinars";
import hostedevents from "./containers/signup/hostedevents/hostedevents";
import landing_i_feel_stuck from "./containers/landing_i-feel-stuck/landing_i-feel-stuck";
import landing_how_do_i_build_trust from "./containers/landing_how-do-i-build-trust/landing_how-do-i-build-trust";
import landing_i_dont_know_anyone_else from "./containers/landing_i-dont-know-anyone-else/landing_i-dont-know-anyone-else";
import landing_i_dont_know_how_to_handle_hard_questions from "./containers/landing_i-dont-know-how-to-handle-hard-questions/landing_i-dont-know-how-to-handle-hard-questions";
import landing_i_dont_know_how_to_sell from "./containers/landing_i-dont-know-how-to-sell/landing_i-dont-know-how-to-sell";
import landing_i_dont_know_how_to_build_a_business from "./containers/landing_i-dont-know-how-to-build-a-business/landing_i-dont-know-how-to-build-a-business";
import landing_i_just_signed_up_my_first_distributor_now_what from "./containers/landing_i-just-signed-up-my-first-distributor-now-what/landing_i-just-signed-up-my-first-distributor-now-what";
import landing_im_new_to_direct_sales from "./containers/landing_im-new-to-direct-sales/landing_im-new-to-direct-sales";
import landing_my_team_isnt_working from "./containers/landing_my-team-isnt-working/landing_my-team-isnt-working";
import landing_this_is_harder_than_i_thought from "./containers/landing_this-is-harder-than-i-thought/landing_this-is-harder-than-i-thought";
import landing_whats_holding_me_back from "./containers/landing_whats-holding-me-back/landing_whats-holding-me-back";
import landing_where_should_i_be_spending_my_time from "./containers/landing_where-should-i-be-spending-my-time/landing_where-should-i-be-spending-my-time";
import module1 from "./containers/module/module";
import block from "./containers/block/block";
import member from "./containers/member/member";
import learningpath from "./containers/learningpath/learningpath";
import whatIsALearningPath from "./containers/what-is-a-learning-path/what-is-a-learning-path";
import privacyPolicy from "./containers/privacy-policy/privacy-policy";
import library from "./containers/library/library";
import blogs from "./containers/blogs/blogs";
import blog from "./containers/blog/blog";
import tools from "./containers/tools/tools";
import mynotebook from "./containers/tools/mynotebook/mynotebook";
import what_is_tap_advance from "./containers/what-is-tap-advance/what-is-tap-advance";
import notfound from "./containers/notfound/notfound";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={home} />
    <AppliedRoute path="/signup" exact component={signup} props={childProps} />
    <AppliedRoute
      path="/member/confirm/:id"
      exact
      component={signupCardInfo}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/virtualcoaching"
      exact
      component={virtualcoaching}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/workshops"
      exact
      component={workshops}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/training"
      exact
      component={training}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/keynotes"
      exact
      component={keynotes}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/webinars"
      exact
      component={webinars}
      props={childProps}
    />
    <AppliedRoute
      path="/signup/hostedevents"
      exact
      component={hostedevents}
      props={childProps}
    />
    {}
    <AppliedRoute path="/signin" exact component={signin} props={childProps} />
    <AppliedRoute
      path="/verification"
      exact
      component={verification}
      props={childProps}
    />
    <AppliedRoute path="/forgot" exact component={forgot} props={childProps} />
    <AppliedRoute path="/reset" exact component={reset} props={childProps} />
    <AppliedRoute
      path="/verify"
      exact
      component={verifyCode}
      props={childProps}
    />

    <AppliedRoute
      path="/how-do-i-build-trust"
      exact
      component={landing_how_do_i_build_trust}
      props={childProps}
    />
    <AppliedRoute
      path="/i-dont-know-anyone-else"
      exact
      component={landing_i_dont_know_anyone_else}
      props={childProps}
    />
    <AppliedRoute
      path="/i-dont-know-how-to-handle-hard-questions"
      exact
      component={landing_i_dont_know_how_to_handle_hard_questions}
      props={childProps}
    />
    <AppliedRoute
      path="/i-dont-know-how-to-sell"
      exact
      component={landing_i_dont_know_how_to_sell}
      props={childProps}
    />
    <AppliedRoute
      path="/i-dont-know-how-to-build-a-business"
      exact
      component={landing_i_dont_know_how_to_build_a_business}
      props={childProps}
    />
    <AppliedRoute
      path="/i-feel-stuck"
      exact
      component={landing_i_feel_stuck}
      props={childProps}
    />
    <AppliedRoute
      path="/i-just-signed-up-my-first-distributor-now-what"
      exact
      component={landing_i_just_signed_up_my_first_distributor_now_what}
      props={childProps}
    />
    <AppliedRoute
      path="/im-new-to-direct-sales"
      exact
      component={landing_im_new_to_direct_sales}
      props={childProps}
    />
    <AppliedRoute
      path="/my-team-isnt-working"
      exact
      component={landing_my_team_isnt_working}
      props={childProps}
    />
    <AppliedRoute
      path="/this-is-harder-than-i-thought"
      exact
      component={landing_this_is_harder_than_i_thought}
      props={childProps}
    />
    <AppliedRoute
      path="/whats-holding-me-back"
      exact
      component={landing_whats_holding_me_back}
      props={childProps}
    />
    <AppliedRoute
      path="/where-should-i-be-spending-my-time"
      exact
      component={landing_where_should_i_be_spending_my_time}
      props={childProps}
    />
    <AppliedRoute
      path="/module/:moduleid"
      exact
      component={module1}
      props={childProps}
    />
    <AppliedRoute
      path="/block/:blockid"
      exact
      component={block}
      props={childProps}
    />
    <AppliedRoute
      path="/learningpath/:learningpathid"
      exact
      component={learningpath}
      props={childProps}
    />
    <AppliedRoute
      path="/blog/:blogid"
      exact
      component={blog}
      props={childProps}
    />
    <AppliedRoute path="/blogs/" exact component={blogs} props={childProps} />
    <AppliedRoute path="/member" exact component={member} props={childProps} />
    <AppliedRoute
      path="/library"
      exact
      component={library}
      props={childProps}
    />
    <AppliedRoute path="/tools/" exact component={tools} props={childProps} />
    <AppliedRoute
      path="/tools/mynotebook"
      exact
      component={mynotebook}
      props={childProps}
    />
    <AppliedRoute
      path="/what-is-a-learning-path"
      exact
      component={whatIsALearningPath}
      props={childProps}
    />
    <AppliedRoute
      path="/what-is-tap-advance"
      exact
      component={what_is_tap_advance}
      props={childProps}
    />
    <AppliedRoute
      path="/privacy-policy"
      exact
      component={privacyPolicy}
      props={childProps}
    />
    <Route component={notfound} />
  </Switch>
);
